import React from "react";
import {
  Nav,
  Button,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  FormInput,
  Pagination,
  PaginationClass,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTimes,
  faSearch,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../utils/axiosConfig";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import { ExportCSV } from "react-export-csv";

import ModalRegion from "../../components/region/ModalRegion";

export default class Region extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 20,
      total: 0,
      regions: [],
      isModalRegionAdd: false,
      isModalRegionEdit: false,
      modalRegionId: null,
      modalRegionRegionList: null,
      modalRegionNetwork: null,
      modalRegionGroup: null,
      modalRegionMailClaim: null,
      search: "",
      order: "",
      isDownloadCSV: false,
      headerCSV: [
        {
          name: "Nom",
          key: "regionListName",
        },
        {
          name: "Réseau",
          key: "networkName",
        },
        {
          name: "Groupe",
          key: "groupName",
        },
        {
          name: "Agences",
          key: "countAgency",
        },
      ],
    };
    this.refColumnNetwork = React.createRef();
    this.refColumnGroup = React.createRef();
    this.refColumnRegion = React.createRef();
    this.refColumnAgency = React.createRef();
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.initSearch();
    }
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnNetwork.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnGroup.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnRegion.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnAgency.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "network", desc: desc };
        break;
      case 1:
        order = { name: "group", desc: desc };
        break;
      case 2:
        order = { name: "region", desc: desc };
        break;
      case 3:
        order = { name: "agency", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ regions: result.tabData });
    } else {
      this.setState({ regions: [] });
    }
  }

  getData() {
    return {
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "region/count",
      "region/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.isModalRegionAdd !== this.state.isModalRegionAdd &&
        this.state.isModalRegionAdd === false) ||
      (prevState.isModalRegionEdit !== this.state.isModalRegionEdit &&
        this.state.isModalRegionEdit === false)
    ) {
      this.initSearch();
    }
  }

  handleDeleteRegionClick(id) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette région ?")) {
      axiosConfig("/region/delete", {
        data: { id: id },
      }).then((res) => {
        if (res.data.success === true) {
          alert("La région a bien été supprimée");
          this.initSearch();
        }
      });
    }
  }

  render() {
    return (
      <>
        <Nav className="mb-20" title="Régions">
          {this.props.administration === 5 ? (
            <Button
              text="Ajouter une région"
              onClick={() => {
                this.setState({
                  isModalRegionAdd: true,
                });
              }}
            />
          ) : (
            ""
          )}
        </Nav>
        <Row>
          <Col sm={12} md={6}>
            <Card className="mb-20">
              <FormInput
                value={this.state.search}
                name="search"
                onChange={this.handleInputChange}
                placeholder="Nom de la région"
                onKeyDown={this.handleSearchKeyDown}
              >
                <FontAwesomeIcon className="icon" icon={faSearch} />
              </FormInput>
            </Card>
          </Col>
        </Row>
        <Card
          title="Liste des régions"
          classNameChildren="overflow-x-auto"
          action={
            <ButtonIcon
              className="blue p-0"
              onClick={() => this.setState({ isDownloadCSV: true })}
            >
              <FontAwesomeIcon icon={faDownload} />
            </ButtonIcon>
          }
        >
          <Table
            isCSV
            empty={
              this.state.regions.length > 0
                ? ""
                : "Aucune donnée n'est disponible"
            }
            radiusTopLeft
          >
            <Thead>
              <Tr>
                <Th hide>#</Th>
                <Th
                  ref={this.refColumnNetwork}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                  radiusTopLeft
                >
                  Réseau
                </Th>
                <Th
                  ref={this.refColumnGroup}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                >
                  Groupe
                </Th>
                <Th
                  ref={this.refColumnRegion}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 2)}
                >
                  Région
                </Th>
                <Th
                  ref={this.refColumnAgency}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 3)}
                >
                  Agences
                </Th>
                <Th>Mail de réclamation</Th>
                {this.props.administration === 5 ? <Th>Actions</Th> : <></>}
              </Tr>
            </Thead>
            <Tbody>
              {this.state.regions.map((element, idx) => (
                <Tr key={idx}>
                  <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                  <Td>{element.networkName}</Td>
                  <Td>{element.groupName}</Td>
                  <Td>{element.regionListName}</Td>
                  <Td>{element.countAgency}</Td>
                  <Td>{element.mailClaim}</Td>
                  {this.props.administration === 5 ? (
                    <Td className="nowrap">
                      <div className="d-flex">
                        <ButtonIcon small className="blue">
                          <FontAwesomeIcon
                            icon={faPen}
                            onClick={() => {
                              this.setState({
                                isModalRegionEdit: true,
                                modalRegionId: element.id,
                                modalRegionRegionList: element.regionList,
                                modalRegionNetwork: element.network,
                                modalRegionGroup: element.group,
                                modalRegionMailClaim: element.mailClaim,
                              });
                            }}
                          />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() =>
                            this.handleDeleteRegionClick(element.id)
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ButtonIcon>
                      </div>
                    </Td>
                  ) : (
                    <></>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            className="mt-10"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.handlePageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        {this.props.administration === 5 ? (
          <ModalRegion
            show={this.state.isModalRegionAdd || this.state.isModalRegionEdit}
            isEdit={this.state.isModalRegionEdit}
            id={this.state.modalRegionId}
            regionList={this.state.modalRegionRegionList}
            network={this.state.modalRegionNetwork}
            group={this.state.modalRegionGroup}
            mailClaim={this.state.modalRegionMailClaim}
            onClose={() =>
              this.setState({
                isModalRegionAdd: false,
                isModalRegionEdit: false,
                modalRegionId: null,
                modalRegionRegionList: null,
                modalRegionNetwork: null,
                modalRegionGroup: null,
                modalRegionMailClaim: null,
              })
            }
          />
        ) : (
          ""
        )}
        {this.state.isDownloadCSV ? (
          <ExportCSV
            header={this.state.headerCSV}
            data={this.state.regions}
            separator={";"}
            callback={() => this.setState({ isDownloadCSV: false })}
            isDownload={true}
            filename={`Régions P${this.state.activePage} ${moment().format(
              "DD-MM-YYYY HH-mm-ss"
            )}.csv`}
          />
        ) : null}
      </>
    );
  }
}
