import React, { Component } from 'react'
import { Button, FormInput, FormSelect, Modal } from 'ui-kit-ck-consultant'
import axiosConfig from "../../../utils/axiosConfig"


export class ModalFormation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: null,
      formation: null,
      newOrganization: null,
      organizationOptions: [{ value: 0, text: "Nouvelle Organisation" }],
      isUpdate: false,
      id: null
    };
    
  }

  handleInputChange = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  getOrganizations = () => {
    axiosConfig("/pilotage-cabinet/formation/get-organization")
    .then((res) => {
      let organizationOptions = this.state.organizationOptions
      if (res.data.success) {
        organizationOptions = organizationOptions.concat(res.data.organizationOptions)
      }
      this.setState({organizationOptions})
    })
  }

  componentDidMount = () => {
    this.getOrganizations()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.organization !== prevState.organization) {
      this.setState({newOrganization: null})
    }
    if (this.props.defaultValue && prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        formation: this.props.defaultValue.name,
        organization: this.props.defaultValue.idOrganization,
        isUpdate: true,
        id: this.props.defaultValue.id
      })
    }
  }

  handleClick = () => {
    if (this.state.isUpdate) {
      axiosConfig('/pilotage-cabinet/formation/update-formation', {
        data: {
          formation: this.state.formation,
          organization: this.state.organization,
          newOrganization: this.state.newOrganization,
          id: this.state.id
        }
      }).then((res) => {
        if (res.data.success) {
          if (this.state.newOrganization) {
            this.props.updateList(res.data)
          } else {
            const org = this.state.organizationOptions.find(element => parseInt(element.value) === parseInt(this.state.organization))
            let data = {
              name: res.data.name,
              id: res.data.id, 
              idOrg: org.value,
              nameOrg: org.text,
            }
            this.props.updateList(data)
          }
          this.onClose()
        }
      })
    } else {
      axiosConfig('/pilotage-cabinet/formation/add-formation', {
        data: {
          formation: this.state.formation,
          organization: this.state.organization,
          newOrganization: this.state.newOrganization,
        }
      }).then((res) => {
        if (res.data.success) {
          if (this.state.newOrganization) {
            this.props.updateList(res.data)
          } else {
            const org = this.state.organizationOptions.find(element => parseInt(element.value) === parseInt(this.state.organization))
            let data = {
              name: res.data.name,
              id: res.data.id, 
              idOrg: org.value,
              nameOrg: org.text,
            }
            this.props.updateList(data)
          }
          this.onClose()
        }
      })
    }

  }

  onClose = () => {
    this.setState({
      organization: null,
      formation: null,
      newOrganization: null,
      isUpdate: false,
      id: null
    }, ()=> this.props.onClose())
  }


  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          title={this.props.title}
          onClose={this.onClose}
        >
          <FormInput
            className="mb-20"
            type="text"
            name="formation"
            value={this.state.formation}
            title="Nom de la formation"
            onChange={this.handleInputChange}
            required
          />
          <FormSelect 
            className="mb-20"
            name="organization"
            title="Choix de l'organisation"
            init="Veuillez sélectionner une valeur" 
            value={this.state.organization}
            options={this.state.organizationOptions}
            onChange={this.handleInputChange}
          />
          {parseInt(this.state.organization) === 0 
          ? 
            <FormInput
              className="mb-20"
              type="text"
              name="newOrganization"
              title="Nom de la nouvelle organisation"
              value={this.state.newOrganization}
              onChange={this.handleInputChange}
            />
          : null}
          <Button 
            text='Valider'
            onClick={() => this.handleClick()}
            disabled={this.state.formation && ((this.state.organization && parseInt(this.state.organization) !== 0) || this.state.newOrganization) ? false : true}
          />

        </Modal>
      </div>
    )
  }
}

export default ModalFormation
