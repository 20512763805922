import React from "react";
import {
  Nav,
  CardColor,
  Card,
  FormInput,
  Button,
  Loader,
  BarChart,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSync,
  faArrowRight,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { colors, convertHexToRGBA } from "../../utils/colors";

import leboncoinLogo from "../../images/website/leboncoin-icon.png";
import lacentraleLogo from "../../images/website/lacentrale-icon.png";
import leparkingLogo from "../../images/website/leparking-icon.png";
import autoscout24Logo from "../../images/website/autoscout24-icon.png";
import paruvenduLogo from "../../images/website/paruvendu-icon.png";
import luxautoLogo from "../../images/website/luxauto-icon.png";
import mobileLogo from "../../images/website/mobile-icon.png";

import axiosConfig from "../../utils/axiosConfig";

export default class Synchronization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countLoad: 0,
      isLoad: false,
      isLoadAdHistory: false,
      lastError: 0,
      isSynchronization: false,
      countSynchronization: 0,
      isSynchronizationUsers: false,
      countSynchronizationUsers: 0,
      countLeboncoin: 0,
      countLacentrale: 0,
      countLeparking: 0,
      countAutoscout24: 0,
      countParuvendu: 0,
      countLuxauto: 0,
      countMobile: 0,
      countDayLeboncoinCar: 0,
      countDayLeboncoinBike: 0,
      countDayLeboncoinCommercial: 0,
      countDayLeboncoinCaravaning: 0,
      countDayLacentrale: 0,
      countDayLeparking: 0,
      countDayAutoscout24: 0,
      countDayLuxauto: 0,
      countDayMobile: 0,
      countDayDisabled: 0,
      countDayChecked: 0,
      countDayInsert: 0,
      countAccountGarage: 0,
      countAccountGarage24: 0,
      countAccountClient: 0,
      countAccountClient24: 0,
      countEga: 0,
      countEga24: 0,
      countSignature: 0,
      countSignatureOK: 0,
      countMail: 0,
      countMail24: 0,
      mailIxperience: 0,
      countMailIxperience98: 0,
      isIxperience: false,
      login: "",
      password: "",
      codeUnisite: "",
      adHistoryInsert: [],
      adHistoryUpdate: [],
      adHistoryCheck: [],
      adHistoryDisable: [],
      buttonHistoryActive: 1,
      buttonHistory: [
        { text: "120h", value: 120 },
        { text: "72h", value: 72 },
        { text: "24h", value: 24 },
        { text: "12h", value: 12 },
        { text: "6h", value: 6 },
      ],
      isLeboncoin: false,
      isLacentrale: false,
      isLeparking: false,
      isAutoscout24: false,
      isParuvendu: false,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState(
      {
        [name]: checked,
      },
      this.getAdHistory
    );
  };

  componentDidMount() {
    this.getLastError();
    this.getSynchronization();
    this.getSynchronizationUsers();
    this.getAd();
    this.getAdDay();
    this.getAdDayDisabled();
    this.getAdDayChecked();
    this.getAdDayInsert();
    this.getAdHistory();
    this.getAccountGarage();
    this.getAccountClient();
    this.getEga();
    this.getSignature();
    this.getMail();
    this.getMailIxperience();
  }

  getLastError = () => {
    axiosConfig("synchronization/get-last-error").then((res) => {
      if (res.data.success) {
        this.setState({ lastError: res.data.count });
      } else {
        this.setState({ lastError: 0 });
      }
    });
  };

  getSynchronization = () => {
    axiosConfig("synchronization/get-synchronization").then((res) => {
      if (res.data.success) {
        this.setState({
          countSynchronization: res.data.count,
          isSynchronization: res.data.count ? true : false,
        });
      } else {
        this.setState({
          countSynchronization: 0,
          isSynchronizationUsers: false,
        });
      }
    });
  };

  getSynchronizationUsers = () => {
    axiosConfig("synchronization/get-synchronization-users").then((res) => {
      if (res.data.success) {
        this.setState({
          countSynchronizationUsers: res.data.count,
          isSynchronizationUsers: res.data.count ? true : false,
        });
      } else {
        this.setState({
          countSynchronizationUsers: 0,
          isSynchronizationUsers: false,
        });
      }
    });
  };

  deleteLastError = () => {
    if (this.state.lastError) {
      axiosConfig("synchronization/delete-last-error").then((res) => {
        if (res.data.success) {
          this.getLastError();
          alert("Synchronisation relancée");
        } else {
          alert("Erreur");
        }
      });
    } else {
      alert("Aucune erreur");
    }
  };

  startSynchronization = () => {
    this.setState(
      {
        isSynchronization: true,
      },
      () => {
        axiosConfig("synchronization/start-synchronization").then((res) => {
          if (res.data.success) {
            this.getSynchronization();
            this.getSynchronizationUsers();
            alert("Synchronisation relancée");
          } else {
            alert("Erreur");
          }
        });
      }
    );
  };

  startSynchronizationUsers = () => {
    this.setState(
      {
        isSynchronizationUsers: true,
      },
      () => {
        axiosConfig("synchronization/start-synchronization-users").then(
          (res) => {
            if (res.data.success) {
              this.getSynchronization();
              this.getSynchronizationUsers();
              alert("Synchronisation relancée");
            } else {
              alert("Erreur");
            }
          }
        );
      }
    );
  };

  checkWSLacour = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("synchronization/check-ws-lacour", {
          data: {
            login: this.state.login,
            password: this.state.password,
            codeUnisite: this.state.codeUnisite,
          },
        }).then((res) => {
          this.setState({ isLoad: false });
          if (res.data.success) {
            alert(`Webservice OK - ${res.data.count} dossier(s)`);
          } else {
            alert("Erreur de webservice");
          }
        });
      }
    );
  };

  getAd = () => {
    axiosConfig("synchronization/get-ad").then((res) => {
      if (res.data.success) {
        this.setState({
          countLeboncoin: this.formatNumber(res.data.data["leboncoin"]),
          countLacentrale: this.formatNumber(res.data.data["lacentrale"]),
          countLeparking: this.formatNumber(res.data.data["leparking"]),
          countAutoscout24: this.formatNumber(res.data.data["autoscout24"]),
          countParuvendu: this.formatNumber(res.data.data["paruvendu"]),
          countLuxauto: this.formatNumber(res.data.data["luxauto"]),
          countMobile: this.formatNumber(res.data.data["mobile"]),
        });
      } else {
        this.setState({
          countLeboncoin: 0,
          countLacentrale: 0,
          countLeparking: 0,
          countAutoscout24: 0,
          countParuvendu: 0,
          countLuxauto: 0,
          countMobile: 0,
        });
      }
    });
  };

  getAdDay = () => {
    axiosConfig("synchronization/get-ad-day").then((res) => {
      if (res.data.success) {
        this.setState({
          countDayLeboncoinCar: this.formatNumber(res.data.data["leboncoin-0"]),
          countDayLeboncoinBike: this.formatNumber(
            res.data.data["leboncoin-1"]
          ),
          countDayLeboncoinCommercial: this.formatNumber(
            res.data.data["leboncoin-2"]
          ),
          countDayLeboncoinCaravaning: this.formatNumber(
            res.data.data["leboncoin-3"]
          ),
          countDayLacentrale: this.formatNumber(res.data.data["lacentrale-0"]),
          countDayLeparking: this.formatNumber(res.data.data["leparking-0"]),
          countDayAutoscout24: this.formatNumber(
            res.data.data["autoscout24-0"]
          ),
          countDayParuvendu: this.formatNumber(res.data.data["paruvendu-0"]),
          countDayLuxauto: this.formatNumber(res.data.data["luxauto-0"]),
          countDayMobile: this.formatNumber(res.data.data["mobile-0"]),
        });
      } else {
        this.setState({
          countDayLeboncoin: 0,
          countDayLacentrale: 0,
          countDayLeparking: 0,
          countDayAutoscout24: 0,
          countDayParuvendu: 0,
          countDayLuxauto: 0,
          countDayMobile: 0,
        });
      }
    });
  };

  getAdDayDisabled = () => {
    axiosConfig("synchronization/get-ad-day-disabled").then((res) => {
      if (res.data.success) {
        this.setState({
          countDayDisabled: this.formatNumber(res.data.data["disabled"]),
        });
      } else {
        this.setState({
          countDayDisabled: 0,
        });
      }
    });
  };

  getAdDayChecked = () => {
    axiosConfig("synchronization/get-ad-day-checked").then((res) => {
      if (res.data.success) {
        this.setState({
          countDayChecked: this.formatNumber(res.data.data["checked"]),
        });
      } else {
        this.setState({
          countDayChecked: 0,
        });
      }
    });
  };

  getAdDayInsert = () => {
    axiosConfig("synchronization/get-ad-day-insert").then((res) => {
      if (res.data.success) {
        this.setState({
          countDayInsert: this.formatNumber(res.data.data["insert"]),
        });
      } else {
        this.setState({
          countDayInsert: 0,
        });
      }
    });
  };

  getAdHistory = () => {
    this.setState(
      {
        isLoadAdHistory: true,
      },
      () => {
        axiosConfig("synchronization/get-ad-history", {
          data: {
            hour: this.state.buttonHistory[this.state.buttonHistoryActive]
              .value,
            isLeboncoin: this.state.isLeboncoin,
            isLacentrale: this.state.isLacentrale,
            isLeparking: this.state.isLeparking,
            isAutoscout24: this.state.isAutoscout24,
            isParuvendu: this.state.isParuvendu,
          },
        }).then((res) => {
          if (res.data.success) {
            this.setState({
              isLoadAdHistory: false,
              adHistoryInsert: res.data.adHistoryInsert,
              adHistoryUpdate: res.data.adHistoryUpdate,
              adHistoryCheck: res.data.adHistoryCheck,
              adHistoryDisable: res.data.adHistoryDisable,
            });
          } else {
            this.setState({
              isLoadAdHistory: false,
              adHistoryInsert: [],
              adHistoryUpdate: [],
              adHistoryCheck: [],
              adHistoryDisable: [],
            });
          }
        });
      }
    );
  };

  formatNumber = (value) => {
    if (value) {
      return new Intl.NumberFormat("cs").format(value);
    } else {
      return 0;
    }
  };

  getHour = (hour) => {
    let hours = [];

    for (let index = hour - 1; index >= 0; index--) {
      hours.push(
        moment().subtract(index, "hours").format("DD/MM/YYYY HH:00:00")
      );
    }

    return hours;
  };

  getAccountGarage = () => {
    axiosConfig("synchronization/get-account-garage").then((res) => {
      if (res.data.success) {
        this.setState({
          countAccountGarage: this.formatNumber(res.data.data["count"]),
          countAccountGarage24: this.formatNumber(res.data.data["count24"]),
        });
      } else {
        this.setState({
          countAccountGarage: 0,
          countAccountGarage24: 0,
        });
      }
    });
  };

  getAccountClient = () => {
    axiosConfig("synchronization/get-account-client").then((res) => {
      if (res.data.success) {
        this.setState({
          countAccountClient: this.formatNumber(res.data.data["count"]),
          countAccountClient24: this.formatNumber(res.data.data["count24"]),
        });
      } else {
        this.setState({
          countAccountClient: 0,
          countAccountClient24: 0,
        });
      }
    });
  };

  getEga = () => {
    axiosConfig("synchronization/get-ega").then((res) => {
      if (res.data.success) {
        this.setState({
          countEga: this.formatNumber(res.data.data["count"]),
          countEga24: this.formatNumber(res.data.data["count24"]),
        });
      } else {
        this.setState({
          countEga: 0,
          countEga24: 0,
        });
      }
    });
  };

  getSignature = () => {
    axiosConfig("synchronization/get-signature").then((res) => {
      if (res.data.success) {
        this.setState({
          countSignature: this.formatNumber(res.data.data["count"]),
          countSignatureOK: this.formatNumber(res.data.data["countOK"]),
        });
      } else {
        this.setState({
          countSignature: 0,
          countSignatureOK: 0,
        });
      }
    });
  };

  getMail = () => {
    axiosConfig("synchronization/get-mail").then((res) => {
      if (res.data.success) {
        this.setState({
          countMail: this.formatNumber(res.data.data["count"]),
          countMail24: this.formatNumber(res.data.data["count24"]),
        });
      } else {
        this.setState({
          countMail: 0,
          countMail24: 0,
        });
      }
    });
  };

  getMailIxperience = () => {
    axiosConfig("synchronization/get-mail-ixperience").then((res) => {
      if (res.data.success) {
        this.setState({
          mailIxperience: res.data.data["count"],
          countMailIxperience98: res.data.data["count98"],
        });
      } else {
        this.setState({
          mailIxperience: 0,
          countMailIxperience98: 0,
        });
      }
    });
  };

  render() {
    return (
      <>
        <Nav className="mb-20" title="Synchronisation" />
        <h3>Dossiers</h3>
        <Row>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.formatNumber(this.state.lastError).toString()}
              title={"Erreurs synchronisation 48h"}
              subtitle={
                this.state.lastError
                  ? "Cliquez pour relancer la synchronisation"
                  : ""
              }
              className={this.state.lastError ? "bg-red" : "bg-green"}
              onClick={this.deleteLastError}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={
                <FontAwesomeIcon
                  icon={this.state.isSynchronization ? faSync : faCheck}
                />
              }
              title={`Synchronisation complète ${
                this.state.isSynchronization
                  ? `(${this.formatNumber(this.state.countSynchronization)})`
                  : ""
              }`}
              subtitle={
                !this.state.isSynchronization
                  ? "Cliquez pour relancer la synchronisation"
                  : "En cours..."
              }
              className={
                this.state.isSynchronization ? "bg-orange" : "bg-green"
              }
              onClick={this.startSynchronization}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={
                <FontAwesomeIcon
                  icon={this.state.isSynchronizationUsers ? faSync : faCheck}
                />
              }
              title={`Synchronisation utilisateurs ${
                this.state.isSynchronizationUsers
                  ? `(${this.formatNumber(
                      this.state.countSynchronizationUsers
                    )})`
                  : ""
              }`}
              subtitle={
                !this.state.isSynchronization
                  ? "Cliquez pour relancer la synchronisation"
                  : "En cours..."
              }
              className={
                this.state.isSynchronizationUsers ? "bg-orange" : "bg-green"
              }
              onClick={this.startSynchronizationUsers}
            />
          </Col>
        </Row>
        <h3>IxPérience</h3>
        <Row>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.state.countAccountGarage}
              title={"Compte réparateur actif"}
              subtitle={`${this.state.countAccountGarage24} (24h)`}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.state.countAccountClient}
              title={"Compte assuré actif"}
              subtitle={`${this.state.countAccountClient24} (24h)`}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.state.countMail}
              title={"Mail envoyé"}
              subtitle={`${this.state.countMail24} (24h)`}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.state.countEga}
              title={"RDV EGA"}
              subtitle={`${this.state.countEga24} (24h)`}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.state.countSignature}
              title={"Signature électronique"}
              subtitle={`(${this.state.countSignatureOK}) Signature électronique OK`}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.formatNumber(this.state.mailIxperience)}
              title={"File d'attente mail"}
              subtitle={`(${this.state.countMailIxperience98}) Mails code 98`}
            />
          </Col>
        </Row>
        <h3>Annonces</h3>
        <Row>
          <Col xs={12} md={6} lg={6} className="mb-20">
            <CardColor
              image={
                <img
                  src={leboncoinLogo}
                  alt="leboncoin"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              }
              title={`${this.state.countLeboncoin} (Annonces Leboncoin actives)`}
              subtitle={`${this.state.countDayLeboncoinCar} | ${this.state.countDayLeboncoinBike} | ${this.state.countDayLeboncoinCommercial} | ${this.state.countDayLeboncoinCaravaning} annonces ajoutées (24h)`}
              className={"bg-blue"}
            />
          </Col>
          <Col xs={12} md={6} lg={6} className="mb-20">
            <CardColor
              image={
                <img
                  src={lacentraleLogo}
                  alt="lacentrale"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              }
              title={`${this.state.countLacentrale} (Annonces La Centrale actives)`}
              subtitle={`${this.state.countDayLacentrale} annonces ajoutées (24h)`}
              className={"bg-blue"}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              image={
                <img
                  src={leparkingLogo}
                  alt="leparking"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              }
              title={`${this.state.countLeparking} (Annonces Le Parking actives)`}
              subtitle={`${this.state.countDayLeparking} annonces ajoutées (24h)`}
              className={"bg-blue"}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              image={
                <img
                  src={autoscout24Logo}
                  alt="autoscout24"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              }
              title={`${this.state.countAutoscout24} (Annonces Autoscout24 actives)`}
              subtitle={`${this.state.countDayAutoscout24} annonces ajoutées (24h)`}
              className={"bg-blue"}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              image={
                <img
                  src={paruvenduLogo}
                  alt="paruvendu"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              }
              title={`${this.state.countParuvendu} (Annonces Paru Vendu actives)`}
              subtitle={`${this.state.countDayParuvendu} annonces ajoutées (24h)`}
              className={"bg-blue"}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              image={
                <img
                  src={luxautoLogo}
                  alt="luxauto"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              }
              title={`${this.state.countLuxauto} (Annonces Luxauto actives)`}
              subtitle={`${this.state.countDayLuxauto} annonces ajoutées (24h)`}
              className={"bg-blue"}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              image={
                <img
                  src={mobileLogo}
                  alt="mobile"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              }
              title={`${this.state.countMobile} (Annonces Mobile.de actives)`}
              subtitle={`${this.state.countDayMobile} annonces ajoutées (24h)`}
              className={"bg-blue"}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              image={
                <div
                  style={{ fontSize: "26px" }}
                  className="d-flex h-100 w-100"
                >
                  <FontAwesomeIcon className="m-auto" icon={faBan} />
                </div>
              }
              title={`${this.state.countDayDisabled} (Annonces désactivées 24h)`}
              className={"bg-blue"}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              image={
                <div
                  style={{ fontSize: "26px" }}
                  className="d-flex h-100 w-100"
                >
                  <FontAwesomeIcon className="m-auto" icon={faCheck} />
                </div>
              }
              title={`${this.state.countDayChecked} (Annonces vérifiées 24h)`}
              className={"bg-blue"}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              image={
                <div
                  style={{ fontSize: "26px" }}
                  className="d-flex h-100 w-100"
                >
                  <FontAwesomeIcon className="m-auto" icon={faCheck} />
                </div>
              }
              title={`${this.state.countDayInsert} (Annonces ajoutées 24h)`}
              className={"bg-blue"}
            />
          </Col>
        </Row>
        <Card
          title={`Statistiques synchronisation - ${
            this.state.buttonHistory[this.state.buttonHistoryActive].text
          }/h`}
          className={"mb-10 mb-30-xl"}
          isLoad={this.state.isLoadAdHistory}
        >
          <Row>
            <FormCheckbox
              className="mb-20 mr-5"
              text="Leboncoin"
              name="isLeboncoin"
              checked={this.state.isLeboncoin}
              onChange={this.handleCheckboxChange}
            />
            <FormCheckbox
              className="mb-20 mr-5"
              text="La centrale"
              name="isLacentrale"
              checked={this.state.isLacentrale}
              onChange={this.handleCheckboxChange}
            />
            <FormCheckbox
              className="mb-20 mr-5"
              text="Leparking"
              name="isLeparking"
              checked={this.state.isLeparking}
              onChange={this.handleCheckboxChange}
            />
            <FormCheckbox
              className="mb-20 mr-5"
              text="Autoscout24"
              name="isAutoscout24"
              checked={this.state.isAutoscout24}
              onChange={this.handleCheckboxChange}
            />
            <FormCheckbox
              className="mb-20 mr-5"
              text="Paruvendu"
              name="isParuvendu"
              checked={this.state.isParuvendu}
              onChange={this.handleCheckboxChange}
            />
          </Row>
          <Row>
            {this.state.buttonHistory.map((element, idx) => (
              <Col key={idx} xs={12} md={2}>
                <Button
                  text={element.text}
                  outline={idx !== this.state.buttonHistoryActive}
                  onClick={() => {
                    this.setState(
                      {
                        buttonHistoryActive: idx,
                      },
                      this.getAdHistory
                    );
                  }}
                />
              </Col>
            ))}
          </Row>
          <BarChart
            title=""
            height={300}
            labels={this.getHour(
              Object.keys(this.state.adHistoryInsert).length
            )}
            digits={0}
            isLegend={true}
            dataSet={[
              {
                label: "Insertion",
                type: "line",
                data: this.state.adHistoryInsert,
                color: convertHexToRGBA(colors.green[0], 25),
                borderColor: colors.green[0],
                fill: true,
              },
              {
                label: "Mise à jour",
                type: "line",
                data: this.state.adHistoryUpdate,
                color: convertHexToRGBA(colors.blue[0], 25),
                borderColor: colors.blue[0],
                fill: true,
              },
              {
                label: "Vérification",
                type: "line",
                data: this.state.adHistoryCheck,
                color: convertHexToRGBA(colors.orange[0], 25),
                borderColor: colors.orange[0],
                fill: true,
              },
              {
                label: "Désactivation",
                type: "line",
                data: this.state.adHistoryDisable,
                color: convertHexToRGBA(colors.red[0], 25),
                borderColor: colors.red[0],
                fill: true,
              },
            ]}
            yAxes={[
              {
                ticks: {
                  display: false,
                  beginAtZero: true,
                },
                gridLines: {
                  display: true,
                },
              },
            ]}
            xAxes={[
              {
                ticks: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
              },
            ]}
          />
        </Card>
        <Card
          className="mb-20"
          title="Vérification des identifiants - WS Lacour"
        >
          <Row>
            <Col xs={12} md={3}>
              <FormInput
                type="text"
                name="login"
                value={this.state.login}
                title="Identifiant"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={3}>
              <FormInput
                type="text"
                name="password"
                value={this.state.password}
                title="Mot de passe"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={3}>
              <FormInput
                type="text"
                name="codeUnisite"
                value={this.state.codeUnisite}
                title="Code unisite"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={3} className="d-flex">
              <Button
                className="mt-auto"
                text="Continuer"
                onClick={this.checkWSLacour}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Col>
          </Row>
        </Card>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
