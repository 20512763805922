import React from "react";
import {
  Modal,
  FormSelect,
  FormInput,
  FormCheckbox,
  Button,
} from "ui-kit-ck-consultant";
import axiosConfig from "../../utils/axiosConfig";
import {
  getNetworkSelect,
  getGroupSelect,
  getRegionSelect,
  getAgencySelect,
} from "../../utils/general";

import AuthContext from "../../context/AuthContext";

export default class ModalUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      codeUnisite: "",
      passwordUnisite: "",
      networkOptions: [],
      groupOptions: [],
      regionOptions: [],
      agencyOptions: [],
      network: "DEFAULT",
      group: "DEFAULT",
      region: "DEFAULT",
      agency: "DEFAULT",
      isAdministration: false,
      isPilotageCabinet: false,
      isPixTransfer: false,
      isValorEasy: false,
      isEvalCar: false,
      isRedaxion: false,
      isXpertVisio: false,
      isGlaSoftware: false,
      isIxPerience: false,
      isError: false,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "network") {
          this.getGroup();
        } else if (name === "group") {
          this.getRegion();
        } else if (name === "region") {
          this.getAgency();
        }
      }
    );
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  componentDidMount() {
    this.getNetwork();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.setState(
        {
          name: this.props.name,
          email: this.props.email,
          codeUnisite: this.props.codeUnisite,
          passwordUnisite: this.props.passwordUnisite,
          network: this.props.network ? this.props.network : "DEFAULT",
          isAdministration: this.props.isAdministration,
          isPilotageCabinet: this.props.isPilotageCabinet,
          isPixTransfer: this.props.isPixTransfer,
          isValorEasy: this.props.isValorEasy,
          isEvalCar: this.props.isEvalCar,
          isRedaxion: this.props.isRedaxion,
          isXpertVisio: this.props.isXpertVisio,
          isGlaSoftware: this.props.isGlaSoftware,
          isIxPerience: this.props.isIxPerience,
        },
        () => {
          this.getGroup();
        }
      );
    } else if (prevProps.show !== this.props.show && !this.props.id) {
      this.setState({
        network: "DEFAULT",
        group: "DEFAULT",
        region: "DEFAULT",
        agency: "DEFAULT",
        name: "",
        email: "",
        codeUnisite: "",
        passwordUnisite: "",
        isPilotageCabinet: false,
        isPixTransfer: false,
        isValorEasy: false,
        isEvalCar: false,
        isRedaxion: false,
        isXpertVisio: false,
        isGlaSoftware: false,
        isIxPerience: false,
        isError: false,
      });
    }
  }

  getNetwork() {
    getNetworkSelect((result) => {
      this.setState({ networkOptions: result.data });
    }, true);
  }

  getGroup() {
    getGroupSelect(
      this.state.network,
      (result) => {
        if (result.data.length > 0) {
          if (
            this.props.network === this.state.network &&
            this.search(result.data, this.props.group)
          ) {
            this.setState(
              { groupOptions: result.data, group: this.props.group },
              this.getRegion
            );
          } else {
            this.setState({ groupOptions: result.data, group: "DEFAULT" });
          }
        } else {
          this.setState({ groupOptions: result.data, group: "DEFAULT" });
        }
      },
      true
    );
  }

  getRegion() {
    getRegionSelect(
      this.state.network,
      this.state.group,
      (result) => {
        if (result.data.length > 0) {
          if (
            this.props.group === this.state.group &&
            this.search(result.data, this.props.region)
          ) {
            this.setState(
              { regionOptions: result.data, region: this.props.region },
              this.getAgency
            );
          } else {
            this.setState({ regionOptions: result.data, region: "DEFAULT" });
          }
        } else {
          this.setState({ regionOptions: result.data, region: "DEFAULT" });
        }
      },
      true
    );
  }

  getAgency() {
    getAgencySelect(
      this.state.network,
      this.state.group,
      this.state.region,
      (result) => {
        if (result.data.length > 0) {
          if (
            this.props.region === this.state.region &&
            this.search(result.data, this.props.agency)
          ) {
            this.setState({
              agencyOptions: result.data,
              agency: this.props.agency,
            });
          } else {
            this.setState({ agencyOptions: result.data, agency: "DEFAULT" });
          }
        } else {
          this.setState({ agencyOptions: result.data, agency: "DEFAULT" });
        }
      },
      true
    );
  }

  handleEditUserClick = () => {
    if (this.state.name && this.state.email) {
      let data = this.getData();
      data.id = this.props.id;
      axiosConfig("/user/update", {
        data: data,
      }).then((res) => {
        if (res.data.success === true) {
          alert("L'utilisateur a bien été mis à jour");
          this.props.onClose();
        }
      });
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  handleAddUserClick = () => {
    if (this.state.name && this.state.email) {
      axiosConfig("/user/insert", {
        data: this.getData(),
      }).then((res) => {
        if (res.data.success === true) {
          alert("L'utilisateur a bien été ajouté");
          this.props.onClose();
        }
      });
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  getData() {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      email: this.state.email,
      name: this.state.name,
      codeUnisite: this.state.codeUnisite,
      passwordUnisite: this.state.passwordUnisite,
      isAdministration: this.state.isAdministration,
      isPilotageCabinet: this.state.isPilotageCabinet,
      isPixTransfer: this.state.isPixTransfer,
      isValorEasy: this.state.isValorEasy,
      isEvalCar: this.state.isEvalCar,
      isRedaxion: this.state.isRedaxion,
      isXpertVisio: this.state.isXpertVisio,
      isGlaSoftware: this.state.isGlaSoftware,
      isIxPerience: this.state.isIxPerience,
    };
  }

  search = (source, name) => {
    var results;
    results = source.map((entry) => {
      var match = entry.value === name;
      return match;
    });
    return results.includes(true);
  };

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Modal
        title={
          this.props.isEdit
            ? "Modifier " + this.props.name
            : "Ajouter " + this.props.name
        }
        show={true}
        onClose={this.props.onClose}
      >
        <FormSelect
          title="Réseau"
          ignore={true}
          className="mb-20"
          name="network"
          value={this.state.network}
          options={this.state.networkOptions}
          init="Veuillez sélectionner une valeur"
          onChange={this.handleInputChange}
        />
        <FormSelect
          disabled={this.state.network === "DEFAULT"}
          title="Groupe"
          ignore={true}
          className="mb-20"
          name="group"
          value={this.state.group}
          options={this.state.groupOptions}
          init="Veuillez sélectionner une valeur"
          onChange={this.handleInputChange}
        />
        <FormSelect
          disabled={this.state.group === "DEFAULT"}
          title="Région"
          ignore={true}
          className="mb-20"
          name="region"
          value={this.state.region}
          options={this.state.regionOptions}
          init="Veuillez sélectionner une valeur"
          onChange={this.handleInputChange}
        />
        <FormSelect
          disabled={this.state.region === "DEFAULT"}
          title="Agence"
          ignore={true}
          className="mb-20"
          name="agency"
          value={this.state.agency}
          options={this.state.agencyOptions}
          init="Veuillez sélectionner une valeur"
          onChange={this.handleInputChange}
        />
        <FormInput
          className="mb-20"
          type="email"
          name="email"
          value={this.state.email}
          title="Adresse mail"
          onChange={this.handleInputChange}
          error={
            this.state.isError === true && !this.state.email
              ? "Veuillez remplir ce champ"
              : ""
          }
        />
        <FormInput
          className="mb-20"
          type="text"
          name="name"
          value={this.state.name}
          title="Nom"
          onChange={this.handleInputChange}
          error={
            this.state.isError === true && !this.state.name
              ? "Veuillez remplir ce champ"
              : ""
          }
        />
        <FormInput
          className="mb-20"
          type="text"
          name="codeUnisite"
          value={this.state.codeUnisite}
          title="Code unisite"
          onChange={this.handleInputChange}
        />
        <FormInput
          className="mb-20"
          type="text"
          name="passwordUnisite"
          value={this.state.passwordUnisite}
          title="Mot de passe unisite"
          onChange={this.handleInputChange}
        />
        <FormCheckbox
          className="mb-20"
          text="Administration"
          name="isAdministration"
          checked={this.state.isAdministration}
          onChange={this.handleCheckboxChange}
        />
        {this.state.network !== "DEFAULT" ? (
          <>
            {this.context.isPilotageCabinet ? (
              <FormCheckbox
                className="mb-20"
                text="Pilotage Cabinet"
                name="isPilotageCabinet"
                checked={this.state.isPilotageCabinet}
                onChange={this.handleCheckboxChange}
              />
            ) : (
              ""
            )}
            {this.context.isPixTransfer ? (
              <FormCheckbox
                className="mb-20"
                text="PixTransfer"
                name="isPixTransfer"
                checked={this.state.isPixTransfer}
                onChange={this.handleCheckboxChange}
              />
            ) : (
              ""
            )}
            {this.context.isValorEasy ? (
              <FormCheckbox
                className="mb-20"
                text="ValorEasy"
                name="isValorEasy"
                checked={this.state.isValorEasy}
                onChange={this.handleCheckboxChange}
              />
            ) : (
              ""
            )}
            {this.context.isEvalCar ? (
              <FormCheckbox
                className="mb-20"
                text="EvalCar"
                name="isEvalCar"
                checked={this.state.isEvalCar}
                onChange={this.handleCheckboxChange}
              />
            ) : (
              ""
            )}
            {this.context.isRedaxion ? (
              <FormCheckbox
                className="mb-20"
                text="Rédaxion"
                name="isRedaxion"
                checked={this.state.isRedaxion}
                onChange={this.handleCheckboxChange}
              />
            ) : (
              ""
            )}
            {this.context.isXpertVisio ? (
              <FormCheckbox
                className="mb-20"
                text="XpertVisio"
                name="isXpertVisio"
                checked={this.state.isXpertVisio}
                onChange={this.handleCheckboxChange}
              />
            ) : (
              ""
            )}
            {this.context.isGlaSoftware ? (
              <FormCheckbox
                className="mb-20"
                text="GLA software"
                name="isGlaSoftware"
                checked={this.state.isGlaSoftware}
                onChange={this.handleCheckboxChange}
              />
            ) : (
              ""
            )}
            {this.context.isIxPerience ? (
              <FormCheckbox
                className="mb-20"
                text="IxPérience"
                name="isIxPerience"
                checked={this.state.isIxPerience}
                onChange={this.handleCheckboxChange}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <Button
          className="mb-4"
          text={this.props.isEdit ? "Modifier" : "Ajouter"}
          onClick={() => {
            this.props.isEdit
              ? this.handleEditUserClick()
              : this.handleAddUserClick();
          }}
        />
      </Modal>
    );
  }
}
