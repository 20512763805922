import React from "react";
import {
  FullModal,
  FormInput,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import axiosConfig from "../../../utils/axiosConfig";

export default class ModalGoalAgency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ageR: "",
      ageNR: "",
      discount: "",
      averageHourly: "",
      costR: "",
      costNR: "",
      depositTimeR: "",
      EADMissionDeadline: "",
      depositTimeNR: "",
      SD29Deadline: "",
      SD29VisitDeadline: "",
      visitMissionDeadline: "",
      honorary: "",
      anomalyNumber: "",
      auditResult: "",
      precision: "",
      satisfaction: "",
      rate1: false,
      rate2: false,
      rate3: false,
      rate4: false,
      rate5: false,
      rate6: false,
      rate7: false,
      rate8: false,
      rate9: false,
      rate10: false,
      rate11: false,
      rate12: false,
      rate13: false,
      rate14: false,
      rate15: false,
      rate16: false,
      rate17: false,
      rate18: false,
      rate19: false,
      rate20: false,
      rate21: false,
      rate22: false,
      rate23: false,
      rate24: false,
      rate25: false,
      rate26: false,
      rate27: false,
      rate28: false,
      rate29: false,
      rate30: false,
      rate31: false,
    };
    this.baseState = this.state
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      axiosConfig("/pilotage-cabinet/agency/get-agency-goal", {
        data: {
          id: this.props.id,
        }
      }).then((res) => {
        if (res.data.success === true && res.data.data.goal !== null) {
          const goal = res.data.data.goal
          this.setState({
            ageR: goal.age_r,
            ageNR: goal.age_nr,
            discount: goal.remise,
            averageHourly: goal.horaire,
            costR: goal.cout_reparable,
            costNR: goal.cout_vrade,
            depositTimeR: goal.delai_depot_r,
            EADMissionDeadline: goal.delai_mission_ead,
            depositTimeNR: goal.delai_depot_nr,
            SD29Deadline: goal.delai_sd29,
            SD29VisitDeadline: goal.delai_visite_sd29,
            visitMissionDeadline: goal.delai_mission_visite,
            honorary: goal.honoraire,
            anomalyNumber: goal.anomalie,
            auditResult: goal.audit,
            precision: goal.precision,
            satisfaction: goal.satisfaction,
            rate1: goal.rate1,
            rate2: goal.rate2,
            rate3: goal.rate3,
            rate4: goal.rate4,
            rate5: goal.rate5,
            rate6: goal.rate6,
            rate7: goal.rate7,
            rate8: goal.rate8,
            rate9: goal.rate9,
            rate10: goal.rate10,
            rate11: goal.rate11,
            rate12: goal.rate12,
            rate13: goal.rate13,
            rate14: goal.rate14,
            rate15: goal.rate15,
            rate16: goal.rate16,
            rate17: goal.rate17,
            rate18: goal.rate18,
            rate19: goal.rate19,
            rate20: goal.rate20,
            rate21: goal.rate21,
            rate22: goal.rate22,
            rate23: goal.rate23,
            rate24: goal.rate24,
            rate25: goal.rate25,
            rate26: goal.rate26,
            rate27: goal.rate27,
            rate28: goal.rate28,
            rate29: goal.rate29,
            rate30: goal.rate30,
            rate31: goal.rate31,
          }, () => {
            this.defaultState = this.state
          })
        } else {
          this.setState(this.baseState, () => {
            this.defaultState = this.state
          })
        }
      });
    } else if (
      prevProps.show !== this.props.show &&
      !this.props.id
    ) {
      this.setState(this.baseState, () => {
        this.defaultState = this.state
      })
    }
  }

  handleValidClick = () => {
    axiosConfig("/pilotage-cabinet/agency/update-agency-goal", {
      data: this.getData()
    }).then((res) => {
      if (res.data.success === true) {
        alert("L'objectif de l'agence a bien été modifié")
        this.props.onClose()
      }
    })
  }

  handleCloseClick = () => {
    if (JSON.stringify(this.defaultState) !== JSON.stringify(this.state) && window.confirm("Êtes-vous sûr de vouloir quitter sans sauvegarder ?")) {
      this.props.onClose()
    } else if(JSON.stringify(this.defaultState) === JSON.stringify(this.state)){
      this.props.onClose()
    }
  }

  getData() {
    return {
      id: this.props.id,
      ageR: this.state.ageR,
      ageNR: this.state.ageNR,
      discount: this.state.discount,
      averageHourly: this.state.averageHourly,
      costR: this.state.costR,
      costNR: this.state.costNR,
      depositTimeR: this.state.depositTimeR,
      EADMissionDeadline: this.state.EADMissionDeadline,
      depositTimeNR: this.state.depositTimeNR,
      SD29Deadline: this.state.SD29Deadline,
      SD29VisitDeadline: this.state.SD29VisitDeadline,
      visitMissionDeadline: this.state.visitMissionDeadline,
      honorary: this.state.honorary,
      anomalyNumber: this.state.anomalyNumber,
      auditResult: this.state.auditResult,
      precision: this.state.precision,
      satisfaction: this.state.satisfaction,
      rate1: this.state.rate1,
      rate2: this.state.rate2,
      rate3: this.state.rate3,
      rate4: this.state.rate4,
      rate5: this.state.rate5,
      rate6: this.state.rate6,
      rate7: this.state.rate7,
      rate8: this.state.rate8,
      rate9: this.state.rate9,
      rate10: this.state.rate10,
      rate11: this.state.rate11,
      rate12: this.state.rate12,
      rate13: this.state.rate13,
      rate14: this.state.rate14,
      rate15: this.state.rate15,
      rate16: this.state.rate16,
      rate17: this.state.rate17,
      rate18: this.state.rate18,
      rate19: this.state.rate19,
      rate20: this.state.rate20,
      rate21: this.state.rate21,
      rate22: this.state.rate22,
      rate23: this.state.rate23,
      rate24: this.state.rate24,
      rate25: this.state.rate25,
      rate26: this.state.rate26,
      rate27: this.state.rate27,
      rate28: this.state.rate28,
      rate29: this.state.rate29,
      rate30: this.state.rate30,
      rate31: this.state.rate31,
    }
  }

  render() {
    if (!this.props.show) {
      return null
    }

    return (
      <FullModal title={"Modifier l'objectif " + this.props.name} show={true} onClose={this.handleCloseClick} onValid={this.handleValidClick}>
        <Row>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Corrélations</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="ageR" value={this.state.ageR} placeholder="80" title="Âge véhicule R (mois)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="ageNR" value={this.state.ageNR} placeholder="80" title="Âge véhicule NR (mois)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="discount" value={this.state.discount} placeholder="2" title="Remise (%)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="averageHourly" value={this.state.averageHourly} placeholder="50" title="Horaire moyen (€ HT)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Objectifs coût</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="costR" value={this.state.costR} placeholder="1200" title="Coût réparable (€ HT)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="costNR" value={this.state.costNR} placeholder="3000" title="Coût VRADE (€ HT)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Objectifs délai</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="depositTimeR" value={this.state.depositTimeR} placeholder="30" title="Délai dépôt R (jours)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="EADMissionDeadline" value={this.state.EADMissionDeadline} placeholder="2" title="Délai mission-EAD (jours)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="depositTimeNR" value={this.state.depositTimeNR} placeholder="20" title="Délai dépôt NR (jours)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="SD29Deadline" value={this.state.SD29Deadline} placeholder="7" title="Délai SD29 (jours)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="SD29VisitDeadline" value={this.state.SD29VisitDeadline} placeholder="2" title="Délai visite-SD29 (jours)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="visitMissionDeadline" value={this.state.visitMissionDeadline} placeholder="2" title="Délai mission-visite (jours)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Objectifs production</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="honorary" value={this.state.honorary} placeholder="185000" title="Honoraire (€ HT/mois)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Objectifs qualité</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="anomalyNumber" value={this.state.anomalyNumber} placeholder="0" title="Nombre d'anomalies" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="auditResult" value={this.state.auditResult} placeholder="100" title="Résultat d'audit (%)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="precision" value={this.state.precision} placeholder="90" title="Précision (%)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="satisfaction" value={this.state.satisfaction} placeholder="18" title="Satisfaction / 20" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Plan d'action</h4>
          </Col>
          <Col xs={12} md={4}>
            <p>Indicateurs de pilotage réparable</p>
            <FormCheckbox className="mb-20" text="Taux de jantes réparées" name="rate1" checked={this.state.rate1} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de pièces plastiques réparées" name="rate2" checked={this.state.rate2} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de boucliers réparés" name="rate3" checked={this.state.rate3} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de PRE" name="rate4" checked={this.state.rate4} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de portes réparées" name="rate5" checked={this.state.rate5} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de radiateurs / condenseurs réparés" name="rate6" checked={this.state.rate6} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de pièces soudés non remplacées" name="rate7" checked={this.state.rate7} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de jupe arrière non remplacées" name="rate8" checked={this.state.rate8} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux d'aile arrière non remplacées" name="rate9" checked={this.state.rate9} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de dépôt de rapport < 20j après réception mission" name="rate10" checked={this.state.rate10} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de dépôt rapport < 10j après expertise" name="rate11" checked={this.state.rate11} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="% de préconisation PQE" name="rate20" checked={this.state.rate20} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="% de transformation PQE" name="rate21" checked={this.state.rate21} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="% de préconisation PRE" name="rate22" checked={this.state.rate22} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="% de transformation PRE" name="rate23" checked={this.state.rate23} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de hayons réparés" name="rate24" checked={this.state.rate24} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de PRE sur les boucliers" name="rate25" checked={this.state.rate25} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de PRE sur les hayons" name="rate26" checked={this.state.rate26} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de PRE sur les portes" name="rate27" checked={this.state.rate27} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de PRE sur les projecteurs" name="rate28" checked={this.state.rate28} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de projecteurs réparés" name="rate29" checked={this.state.rate29} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux d'ailes avant réparées" name="rate30" checked={this.state.rate30} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de feux réparés" name="rate31" checked={this.state.rate31} onChange={this.handleCheckboxChange} />
          </Col>
          <Col xs={12} md={4}>
            <p>Indicateurs de pilotage non réparable</p>
            <FormCheckbox className="mb-20" text="Taux de conformité du process VRADE" name="rate12" checked={this.state.rate12} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de conformités de bilan technique" name="rate13" checked={this.state.rate13} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de SD29 < 8j après réception mission" name="rate14" checked={this.state.rate14} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de SD29 < 2j après expertise" name="rate15" checked={this.state.rate15} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de dépôt rapport < 20j après réception mission" name="rate16" checked={this.state.rate16} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Taux de dépôt rapport < 10j après expertise" name="rate17" checked={this.state.rate17} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Correctif selon marché HT dans bilan technique" name="rate18" checked={this.state.rate18} onChange={this.handleCheckboxChange} />
            <FormCheckbox className="mb-20" text="Total +/- values HT dans bilan technique" name="rate19" checked={this.state.rate19} onChange={this.handleCheckboxChange} />
          </Col>
        </Row>
      </FullModal>
    )
  }
}
