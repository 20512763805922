import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  Pagination,
  PaginationClass,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../../utils/axiosConfig";

import ModalAgency from "./ModalAgency";
import ModalGoalAgency from "./ModalGoalAgency";

export default class Agency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 20,
      total: 0,
      agencies: [],
      search: "",
      isModalAgencyEdit: false,
      modalAgencyId: null,
      modalAgencyName: null,
      modalAgencyOverall: null,
      modalAgencySatisfaction: null,
      modalAgencyHonorary: null,
      modalAgencyColor: null,
      isModalGoalAgencyEdit: false,
      modalGoalAgencyId: null,
      modalGoalAgencyName: null,
      order: "",
    };

    this.refColumnName = React.createRef();
    this.refColumnPG = React.createRef();
    this.refColumnSatisfaction = React.createRef();
    this.refColumnCount = React.createRef();
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "pilotage-cabinet/agency/count",
      "pilotage-cabinet/agency/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  handleSearch = (search) => {
    this.setState(
      {
        search,
      },
      () => {
        this.initSearch();
      }
    );
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnPG.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnSatisfaction.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnCount.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "PG", desc: desc };
        break;
      case 2:
        order = { name: "satisfaction", desc: desc };
        break;
      case 3:
        order = { name: "count", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ agencies: result.tabData });
    } else {
      this.setState({ agencies: [] });
    }
  }

  getData() {
    return {
      network: this.props.network,
      group: this.props.group,
      region: this.props.region,
      agency: this.props.agency,
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isModalAgencyEdit !== this.state.isModalAgencyEdit &&
      this.state.isModalAgencyEdit === false
    ) {
      this.initSearch();
    }
  }

  render() {
    return (
      <>
        <Table
          isCSV
          radiusTopLeft
          empty={
            this.state.agencies.length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th hide>#</Th>
              <Th
                ref={this.refColumnName}
                sort
                onSort={(desc) => this.handleSortClick(desc, 0)}
                radiusTopLeft
              >
                Nom
              </Th>
              <Th
                ref={this.refColumnPG}
                sort
                onSort={(desc) => this.handleSortClick(desc, 1)}
              >
                P.G.
              </Th>
              <Th
                ref={this.refColumnSatisfaction}
                sort
                onSort={(desc) => this.handleSortClick(desc, 2)}
              >
                Satisfaction
              </Th>
              <Th
                ref={this.refColumnCount}
                sort
                onSort={(desc) => this.handleSortClick(desc, 3)}
              >
                Nb. dossiers
              </Th>
              <Th>Général</Th>
              <Th>Objectifs</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.agencies.map((element, idx) => (
              <Tr key={idx}>
                <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                <Td>
                  <div className="d-flex">
                    <div
                      className="mt-auto mb-auto mr-10"
                      style={{
                        backgroundColor: element.color,
                        height: "10px",
                        width: "10px",
                        borderRadius: "10px",
                      }}
                    />
                    <div className="mt-auto mb-auto">{element.name}</div>
                  </div>
                </Td>
                <Td>{element.overall === 1 ? "Oui" : "Non"}</Td>
                <Td>{element.satisfaction === 1 ? "Oui" : "Non"}</Td>
                <Td>{element.folders}</Td>
                <Td className="nowrap text-center">
                  <ButtonIcon
                    small
                    className="blue"
                    onClick={() => {
                      this.setState({
                        isModalAgencyEdit: true,
                        modalAgencyId: element.id,
                        modalAgencyName: element.name,
                        modalAgencyOverall: element.overall,
                        modalAgencySatisfaction: element.satisfaction,
                        modalAgencyHonorary: element.honorary,
                        modalAgencyColor: element.color,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </ButtonIcon>
                </Td>
                <Td className="nowrap text-center">
                  <ButtonIcon
                    small
                    className="blue"
                    onClick={() => {
                      this.setState({
                        isModalGoalAgencyEdit: true,
                        modalGoalAgencyId: element.id,
                        modalGoalAgencyName: element.name,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          className="mt-10"
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.tabLength}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={4}
          onChange={this.handlePageChange.bind(this)}
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
          nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
        />
        <ModalAgency
          show={this.state.isModalAgencyEdit}
          id={this.state.modalAgencyId}
          name={this.state.modalAgencyName}
          overall={this.state.modalAgencyOverall}
          satisfaction={this.state.modalAgencySatisfaction}
          honorary={this.state.modalAgencyHonorary}
          color={this.state.modalAgencyColor}
          onClose={() =>
            this.setState({
              isModalAgencyEdit: false,
              modalAgencyId: null,
              modalAgencyName: null,
              modalAgencyOverall: null,
              modalAgencySatisfaction: null,
              modalAgencyColor: null,
            })
          }
        />
        <ModalGoalAgency
          show={this.state.isModalGoalAgencyEdit}
          id={this.state.modalGoalAgencyId}
          name={this.state.modalGoalAgencyName}
          onClose={() =>
            this.setState({
              isModalGoalAgencyEdit: false,
              modalGoalAgencyId: null,
              modalGoalAgencyName: null,
            })
          }
        />
      </>
    );
  }
}
