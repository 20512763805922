import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  Pagination,
  PaginationClass,
} from "ui-kit-ck-consultant";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import axiosConfig from "../../../utils/axiosConfig";

import ModalUser from "./ModalUser";
import ModalGoalUser from "./ModalGoalUser";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 20,
      total: 0,
      users: [],
      search: "",
      isModalUserEdit: false,
      modalUserId: null,
      modalUserName: null,
      modalUserInitial: null,
      modalUserColor: null,
      modalUserType: null,
      modalUserRole: null,
      modalUserOverall: null,
      modalUserValidateLeave: null,
      modalUserRegionIsolate: null,
      isModalGoalUserEdit: false,
      modalGoalUserId: null,
      modalGoalUserType: null,
      modalUserNetwork: null,
      modalUserGroup: null,
      modalUserRegion: null,
      modalUserAgency: null,
      modalUserDateStart: null,
    };

    this.refColumnName = React.createRef();
    this.refColumnInitial = React.createRef();
    this.refColumnType = React.createRef();
    this.refColumnRole = React.createRef();
    this.refColumnNetwork = React.createRef();
    this.refColumnGroup = React.createRef();
    this.refColumnRegion = React.createRef();
    this.refColumnAgency = React.createRef();
    this.refColumnPG = React.createRef();
    this.refColumnDateStart = React.createRef();
    this.refCodeVE = React.createRef();
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearch = (search) => {
    this.setState(
      {
        search,
      },
      () => {
        this.initSearch();
      }
    );
  };

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "pilotage-cabinet/user/count",
      "pilotage-cabinet/user/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isModalUserEdit !== this.state.isModalUserEdit &&
      this.state.isModalUserEdit === false
    ) {
      this.initSearch();
    }
  }

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnInitial.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnType.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnRole.current.setState({ desc: false, sort: false });
    }
    if (column !== 4) {
      this.refColumnNetwork.current.setState({ desc: false, sort: false });
    }
    if (column !== 5) {
      this.refColumnGroup.current.setState({ desc: false, sort: false });
    }
    if (column !== 6) {
      this.refColumnRegion.current.setState({ desc: false, sort: false });
    }
    if (column !== 7) {
      this.refColumnAgency.current.setState({ desc: false, sort: false });
    }
    if (column !== 8) {
      this.refColumnPG.current.setState({ desc: false, sort: false });
    }
    if (column !== 9) {
      this.refColumnDateStart.current.setState({ desc: false, sort: false });
    }
    if (column !== 10) {
      this.refCodeVE.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "initial", desc: desc };
        break;
      case 2:
        order = { name: "type", desc: desc };
        break;
      case 3:
        order = { name: "role", desc: desc };
        break;
      case 4:
        order = { name: "network", desc: desc };
        break;
      case 5:
        order = { name: "group", desc: desc };
        break;
      case 6:
        order = { name: "region", desc: desc };
        break;
      case 7:
        order = { name: "agency", desc: desc };
        break;
      case 8:
        order = { name: "PG", desc: desc };
        break;
      case 9:
        order = { name: "dateStart", desc: desc };
        break;
      case 10:
        order = { name: "codeVE", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ users: result.tabData });
    } else {
      this.setState({ users: [] });
    }
  }

  getData() {
    return {
      network: this.props.network,
      group: this.props.group,
      region: this.props.region,
      agency: this.props.agency,
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  getAgenciesOptions = (isForce = false) => {
    if (
      this.state.modalUserRole === 1 ||
      this.state.modalUserRole === 2 ||
      isForce
    ) {
      axiosConfig({
        url: "/pilotage-cabinet/agency/get-agencies-options",
        data: {
          network: this.state.modalUserNetwork,
          group: this.state.modalUserGroup,
          region: this.state.modalUserRegion,
        },
      }).then((res) => {
        if (res.data.success === true && res.data.AgenciesOptions.length > 0) {
          this.setState(
            {
              agenciesOptions: [
                { value: 0, text: "Veuillez sélectionner une agence" },
                ...res.data.AgenciesOptions,
              ],
            },
            () => this.getFormatAgenciesList(this.state.modalUserAgency)
          );
        } else {
          this.setState({
            agenciesOptions: [{ value: 0, text: "Aucune Agence" }],
          });
        }
      });
    } else {
      this.setState({
        isModalUserEdit: true,
      });
    }
  };

  getFormatAgenciesList(agenciesList) {
    if (agenciesList) {
      if (typeof agenciesList === "string") {
        agenciesList = agenciesList.split(",");
      }
      let newList = agenciesList.map((element) => {
        if (element.value || element.text) {
          return element;
        } else {
          let index = this.state.agenciesOptions.findIndex(
            (agency) => agency.value === parseInt(element)
          );
          if (index >= 0) {
            return this.state.agenciesOptions[index];
          }
        }
        return element;
      });
      this.setState({
        modalUserAgency: newList,
        isModalUserEdit: true,
      });
    } else {
      this.setState({
        modalUserAgency: [],
        isModalUserEdit: true,
      });
    }
  }

  render() {
    return (
      <>
        <Table
          isCSV
          radiusTopLeft
          empty={
            this.state.users.length > 0 ? "" : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th hide>#</Th>
              <Th
                ref={this.refColumnName}
                sort
                onSort={(desc) => this.handleSortClick(desc, 0)}
                radiusTopLeft
              >
                Nom
              </Th>
              <Th
                ref={this.refColumnInitial}
                sort
                onSort={(desc) => this.handleSortClick(desc, 1)}
              >
                Initiales
              </Th>
              <Th
                ref={this.refColumnType}
                sort
                onSort={(desc) => this.handleSortClick(desc, 2)}
              >
                Type
              </Th>
              <Th
                ref={this.refColumnRole}
                sort
                onSort={(desc) => this.handleSortClick(desc, 3)}
              >
                Rôle
              </Th>
              <Th
                ref={this.refColumnNetwork}
                sort
                onSort={(desc) => this.handleSortClick(desc, 4)}
              >
                Réseau
              </Th>
              <Th
                ref={this.refColumnGroup}
                sort
                onSort={(desc) => this.handleSortClick(desc, 5)}
              >
                Groupe
              </Th>
              <Th
                ref={this.refColumnRegion}
                sort
                onSort={(desc) => this.handleSortClick(desc, 6)}
              >
                Région
              </Th>
              <Th
                ref={this.refColumnAgency}
                sort
                onSort={(desc) => this.handleSortClick(desc, 7)}
              >
                Agence
              </Th>
              <Th
                ref={this.refColumnPG}
                sort
                onSort={(desc) => this.handleSortClick(desc, 8)}
              >
                P.G.
              </Th>
              <Th
                ref={this.refColumnDateStart}
                sort
                onSort={(desc) => this.handleSortClick(desc, 9)}
              >
                Date début activité
              </Th>
              <Th
                ref={this.refCodeVE}
                sort
                onSort={(desc) => this.handleSortClick(desc, 10)}
              >
                Code VE
              </Th>
              <Th>Général</Th>
              <Th>Objectifs</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.users.map((element, idx) => (
              <Tr key={idx}>
                <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                <Td>
                  <div className="d-flex">
                    <div
                      className="mt-auto mb-auto mr-10"
                      style={{
                        backgroundColor: element.color,
                        height: "10px",
                        width: "10px",
                        borderRadius: "10px",
                      }}
                    />
                    <div className="mt-auto mb-auto">{element.name}</div>
                  </div>
                </Td>
                <Td>{element.initial}</Td>
                <Td>{element.type}</Td>
                <Td>{element.role}</Td>
                <Td>{element.network ? element.network : "N.R"}</Td>
                <Td>{element.group ? element.group : "N.R"}</Td>
                <Td>{element.region ? element.region : "N.R"}</Td>
                <Td>{element.agency ? element.agency : "N.R"}</Td>
                <Td>{element.overall === 1 ? "Oui" : "Non"}</Td>
                <Td>
                  {element.dateStart
                    ? moment(element.dateStart).format("DD/MM/YYYY")
                    : "N.R."}
                </Td>
                <Td>{element.codeVE}</Td>
                <Td className="nowrap text-center">
                  <ButtonIcon
                    small
                    className="blue"
                    onClick={() => {
                      this.setState(
                        {
                          modalUserId: element.id,
                          modalUserName: element.name,
                          modalUserInitial: element.initial,
                          modalUserColor: element.color,
                          modalUserType: element.type_id,
                          modalUserRole: element.role_id,
                          modalUserOverall: element.overall,
                          modalUserValidateLeave: element.validateLeave,
                          modalUserRegionIsolate: element.regionIsolate,
                          modalUserNetwork: element.network_id,
                          modalUserGroup: element.group_id,
                          modalUserRegion: element.region_id,
                          modalUserAgency:
                            element.manageAgencies &&
                            Array.isArray(element.manageAgencies)
                              ? element.manageAgencies
                              : element.agency_id
                              ? [
                                  {
                                    value: element.agency_id,
                                    text: element.agency,
                                  },
                                ]
                              : null,
                          modalUserDateStart: element.dateStart,
                          modalUserCodeVE: element.codeVE,
                        },
                        () => this.getAgenciesOptions()
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </ButtonIcon>
                </Td>
                <Td className="nowrap text-center">
                  {element.type_id > 0 ? (
                    <ButtonIcon
                      small
                      className="blue"
                      onClick={() => {
                        this.setState({
                          isModalGoalUserEdit: true,
                          modalGoalUserId: element.id,
                          modalGoalUserName: element.name,
                          modalGoalUserType: element.type_id,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </ButtonIcon>
                  ) : (
                    ""
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          className="mt-10"
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.tabLength}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={4}
          onChange={this.handlePageChange.bind(this)}
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
          nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
        />
        <ModalUser
          show={this.state.isModalUserEdit}
          id={this.state.modalUserId}
          name={this.state.modalUserName}
          initial={this.state.modalUserInitial}
          color={this.state.modalUserColor}
          type={this.state.modalUserType}
          role={this.state.modalUserRole}
          overall={this.state.modalUserOverall}
          validateLeave={this.state.modalUserValidateLeave}
          regionIsolate={this.state.modalUserRegionIsolate}
          network={this.state.modalUserNetwork}
          group={this.state.modalUserGroup}
          region={this.state.modalUserRegion}
          agenciesOptions={this.state.agenciesOptions}
          agencies={this.state.modalUserAgency}
          dateStart={this.state.modalUserDateStart}
          codeVE={this.state.modalUserCodeVE}
          onClose={() =>
            this.setState({
              isModalUserEdit: false,
              modalUserId: null,
              modalUserName: null,
              modalUserInitial: null,
              modalUserColor: null,
              modalUserType: null,
              modalUserRole: null,
              modalUserOverall: null,
              modalUserValidateLeave: null,
              modalUserRegionIsolate: null,
              modalUserDateStart: null,
            })
          }
          getAgenciesOptions={this.getAgenciesOptions}
        />
        <ModalGoalUser
          show={this.state.isModalGoalUserEdit}
          id={this.state.modalGoalUserId}
          name={this.state.modalGoalUserName}
          type={this.state.modalGoalUserType}
          onClose={() =>
            this.setState({
              isModalGoalUserEdit: false,
              modalGoalUserId: null,
              modalGoalUserName: null,
              modalGoalUserType: null,
            })
          }
        />
      </>
    );
  }
}
