import React from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faBuilding,
  faUsers,
  faLocationArrow,
  faBriefcase,
  faFileInvoice,
  faSyncAlt,
  faEllipsisV,
  faUserTie,
  faTools,
  faCarCrash,
} from "@fortawesome/free-solid-svg-icons";
import { Header, ButtonIcon, Sidebar, Icon } from "ui-kit-ck-consultant";
import { icHome, icProfil } from "icon-kit-ck-consultant";
import axiosConfig from "../utils/axiosConfig";

import pilotagecabinetLogo from "../images/pilotagecabinet.svg";
import valoreasyLogo from "../images/valoreasy.svg";
import evalcarLogo from "../images/evalcar.svg";
import ixperienceLogo from "../images/ixperience.svg";
import pixtransferLogo from "../images/pixtransfer.svg";
import glasoftwareLogo from "../images/Sigle-GLA-software.png";
import hubexLogo from "../images/HUBEX-4.png";

import AuthContext from "../context/AuthContext";

export default class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      views: [],
    };
    this.sidebarRef = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    let views = [];

    views.push({
      icon: <Icon icon={icHome} />,
      name: "Tableau de bord",
      path: "/home",
    });

    if (this.context.administration >= 5) {
      views.push({
        icon: <FontAwesomeIcon icon={faBuilding} />,
        name: "Réseaux",
        path: "/network",
      });
    }

    if (this.context.administration >= 4) {
      views.push({
        icon: <FontAwesomeIcon icon={faUsers} />,
        name: "Groupes",
        path: "/group",
      });
    }

    if (this.context.administration >= 3) {
      views.push({
        icon: <FontAwesomeIcon icon={faLocationArrow} />,
        name: "Régions",
        path: "/region",
      });
    }

    if (this.context.administration >= 2) {
      views.push({
        icon: <FontAwesomeIcon icon={faBriefcase} />,
        name: "Agences",
        path: "/agency",
      });
    }

    if (this.context.administration >= 1) {
      views.push({
        icon: <Icon icon={icProfil} />,
        name: "Utilisateurs",
        path: "/user",
      });
    }

    if (this.context.administration >= 5) {
      views.push({
        icon: <FontAwesomeIcon icon={faCarCrash} />,
        name: "Compagnies",
        path: "/company",
      });
    }

    if (this.context.administration >= 5) {
      views.push({
        icon: <FontAwesomeIcon icon={faUserTie} />,
        name: "Mandants",
        path: "/principal",
      });
    }

    if (this.context.administration >= 5) {
      views.push({
        icon: <FontAwesomeIcon icon={faTools} />,
        name: "Réparateurs",
        path: "/garage",
      });
    }

    if (this.context.administration >= 5) {
      views.push({
        icon: <FontAwesomeIcon icon={faCarCrash} />,
        name: "Assurés",
        path: "/client",
      });
    }

    if (this.context.email !== "d.lorgnet@ref-conseil.fr") {
      views.push({
        icon: <img alt="icon" height={28} src={pilotagecabinetLogo} />,
        name: "Pilotage Cabinet",
        path: "/pilotage-cabinet",
      });
    }

    views.push({
      icon: <img alt="icon" height={28} src={valoreasyLogo} />,
      name: "ValorEasy",
      path: "/valoreasy",
    });

    if (this.context.isEvalCar) {
      views.push({
        icon: <img alt="icon" height={28} src={evalcarLogo} />,
        name: "EvalCar",
        path: "/evalcar",
      });
    }

    if (this.context.email !== "d.lorgnet@ref-conseil.fr") {
      views.push({
        icon: <img alt="icon" height={28} src={ixperienceLogo} />,
        name: "Ixperience",
        path: "/ixperience",
      });
    }
    if (this.context.isPixTransfer) {
      views.push({
        icon: <img alt="icon" height={28} src={pixtransferLogo} />,
        name: "PixTransfer",
        path: "/pixtransfer",
      });
    }
    if (this.context.isGlaSoftware) {
      views.push({
        icon: <img alt="icon" height={28} src={glasoftwareLogo} />,
        name: "GLA Software",
        path: "/glasoftware",
      });
    }

    if (this.context.administration === 5) {
      views.push({
        icon: <FontAwesomeIcon icon={faFileInvoice} />,
        name: "Facturation",
        path: "/invoice",
      });
    }

    if (this.context.administration === 5) {
      views.push({
        icon: <FontAwesomeIcon icon={faSyncAlt} />,
        name: "Synchronisation",
        path: "/synchronization",
      });
    }

    this.setState({ views });
  }

  handleSignOutClick() {
    var url = window.location.href;
    var arr = url.split("/");
    axiosConfig("/auth/signout", {
      data: {
        url: arr[0] + "//" + arr[2],
      },
    }).then((res) => {
      if (res.data.success === true) {
        document.location.href = res.data.redirect;
      }
    });
  }

  render() {
    if (this.props.connected === false) {
      return <Redirect to="/signin" />;
    } else {
      return (
        <div className="Dashboard-container">
          <Sidebar
            ref={this.sidebarRef}
            location={this.props.location}
            views={this.state.views}
            onViewChange={(result) => {
              this.props.history.push(result.path);
            }}
            title={`${this.context.name} - lvl ${this.context.administration}`}
            subtitle={this.context.email}
            checkIfMobile={(isMobile) => {
              if (this.state.isMobile !== isMobile) {
                this.setState({ isMobile });
              }
            }}
          />
          <div className="Dashboard-container-body">
            <Header logo={hubexLogo} height="42">
              {this.state.isMobile ? (
                <ButtonIcon
                  onClick={() => {
                    this.sidebarRef.current.handleHideClick();
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </ButtonIcon>
              ) : null}
              <ButtonIcon
                className="white"
                onClick={() => this.handleSignOutClick()}
              >
                <FontAwesomeIcon icon={faPowerOff} />
              </ButtonIcon>
            </Header>
            <div className="Dashboard-content">
              {this.props.connected === true ? this.props.children : ""}
            </div>
          </div>
        </div>
      );
    }
  }
}
