import React from "react";
import {
  Modal,
  Button,
  FormCheckbox
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid"
import axiosConfig from "../../utils/axiosConfig"

export default class ModalUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      global: false
    };
  }

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.setState({
        global: this.props.global ? true : false,
      })
    } else if (prevProps.show !== this.props.show &&
      !this.props.id
    ) {
      this.setState({
        global: false,
      })
    }
  }

  handleEditUserClick = () => {
    let data = this.getData()
    axiosConfig("/valoreasy/update-user", {
      data: data
    }).then((res) => {
      if (res.data.success === true) {
        alert("L'utilisateur a bien été mis à jour")
        this.props.onClose()
      }
    });
  }

  getData() {
    return {
      id: this.props.id,
      global: this.state.global,
    }
  }

  render() {
    if (!this.props.show) {
      return null
    }

    return (
      <Modal
        title={"Modifier " + this.props.name}
        show={true}
        onClose={this.props.onClose}
      >
        <Row>
          <Col xs={12} className="mb-20 d-flex">
            <FormCheckbox
              className="mb-20"
              text="Visualisation globale"
              name="global"
              checked={this.state.global}
              onChange={this.handleCheckboxChange}
            />
          </Col>
          <Button
            text="Modifier"
            onClick={(e) => this.handleEditUserClick(e)}
          />
        </Row>
      </Modal>
    )
  }
}
