import React from "react";
import {
  Modal,
  FormInput,
  Button,
  FormCheckbox,
  FormRadio,
  Chips,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { PhotoshopPicker } from "react-color";
import axiosConfig from "../../../utils/axiosConfig";

export default class ModalUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isColor: false,
      colorTmp: "",
      initial: "",
      color: "",
      type: 0,
      role: 0,
      overall: false,
      validateLeave: false,
      regionIsolate: false,
      agencies: [],
      dateStart: "",
      codeVE: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  handleChipsChange = (agenciesList) => {
    this.setState({ agencies: agenciesList });
  };

  handleRadioChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: parseInt(value),
      },
      () => {
        if (
          name === "role" &&
          (parseInt(value) === 1 || parseInt(value) === 2)
        ) {
          this.props.getAgenciesOptions(true);
        }
      }
    );
  };

  handleChangeComplete = (e) => {
    this.setState({ colorTmp: e.hex });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.id) {
      this.setState({
        initial: this.props.initial ? this.props.initial : "",
        color: this.props.color,
        type: this.props.type,
        role: this.props.role,
        overall: this.props.overall,
        validateLeave: this.props.validateLeave,
        regionIsolate: this.props.regionIsolate,
        agencies: this.props.agencies,
        dateStart: this.props.dateStart ? this.props.dateStart : "",
        codeVE: this.props.codeVE,
      });
    } else if (prevProps.show !== this.props.show && !this.props.id) {
      this.setState({
        isColor: false,
        colorTmp: "",
        initial: "",
        color: "",
        type: 0,
        role: 0,
        overall: false,
        validateLeave: false,
        regionIsolate: false,
        isError: false,
        agencies: [],
        dateStart: "",
        codeVE: "",
      });
    }
  }

  handleEditUserClick = () => {
    let data = this.getData();
    data.id = this.props.id;
    axiosConfig("/pilotage-cabinet/user/update-user", {
      data: data,
    }).then((res) => {
      if (res.data.success === true) {
        alert("L'utilisateur a bien été mis à jour");
        this.props.onClose();
      }
    });
  };

  getData() {
    return {
      initial: this.state.initial,
      color: this.state.color,
      type: this.state.type,
      role: this.state.role,
      overall: this.state.overall,
      validateLeave: this.state.validateLeave,
      regionIsolate: this.state.regionIsolate,
      agencies: this.state.agencies,
      dateStart: this.state.dateStart,
      codeVE: this.state.codeVE,
    };
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    console.log(this.state.agencies);

    return (
      <Modal
        title={"Modifier " + this.props.name}
        show={true}
        onClose={this.props.onClose}
      >
        <Row>
          <Col xs={12} md={6} className="mb-20 d-flex">
            <FormInput
              type="text"
              name="initial"
              value={this.state.initial}
              title="Initiales"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={6} className="mb-20 d-flex">
            <Button
              className="mt-auto"
              onClick={() =>
                this.setState({ isColor: true, colorTmp: this.state.color })
              }
              text="Modifier la couleur"
            >
              <div
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "15px",
                  backgroundColor: this.state.color,
                }}
              ></div>
            </Button>
          </Col>
          <Col xs={12} md={6} className="mb-20">
            <h2 className="mb-10">Type</h2>
            <FormRadio
              value={0}
              checked={this.state.type === 0}
              onChange={this.handleRadioChange}
              text="Aucun"
              name="type"
              className="mb-10"
            />
            <FormRadio
              value={1}
              checked={this.state.type === 1}
              onChange={this.handleRadioChange}
              text="Expert masqué"
              name="type"
              className="mb-10"
            />
            <FormRadio
              value={2}
              checked={this.state.type === 2}
              onChange={this.handleRadioChange}
              text="Expert terrain"
              name="type"
              className="mb-10"
            />
            <FormRadio
              value={3}
              checked={this.state.type === 3}
              onChange={this.handleRadioChange}
              text="Expert EAD"
              name="type"
              className="mb-10"
            />
            <FormRadio
              value={4}
              checked={this.state.type === 4}
              onChange={this.handleRadioChange}
              text="Assistant(e) masqué(e)"
              name="type"
              className="mb-10"
            />
            <FormRadio
              value={5}
              checked={this.state.type === 5}
              onChange={this.handleRadioChange}
              text="Assistant(e)"
              name="type"
              className="mb-10"
            />
          </Col>
          <Col xs={12} md={6} className="mb-20">
            <h2 className="mb-10">Rôle</h2>
            <FormRadio
              value={0}
              checked={this.state.role === 0}
              onChange={this.handleRadioChange}
              text="Aucun"
              name="role"
              className="mb-10"
            />
            <FormRadio
              value={1}
              checked={this.state.role === 1}
              onChange={this.handleRadioChange}
              text="Responsable technique"
              name="role"
              className="mb-10"
            />
            <FormRadio
              value={2}
              checked={this.state.role === 2}
              onChange={this.handleRadioChange}
              text="Responsable administratif"
              name="role"
              className="mb-10"
            />
            <FormRadio
              value={3}
              checked={this.state.role === 3}
              onChange={this.handleRadioChange}
              text="DRH"
              name="role"
              className="mb-10"
            />
            <FormRadio
              value={4}
              checked={this.state.role === 4}
              onChange={this.handleRadioChange}
              text="Dirigeant"
              name="role"
              className="mb-10"
            />
          </Col>
          {this.state.role === 1 ||
          this.state.role === 2 ||
          this.state.role === 3 ? (
            <Col xs={12} md={12} className="mb-20">
              <h2 className="mb-10">
                De quelle(s) agence(s) êtes-vous responsable ?
              </h2>
              <Chips
                options={this.props.agenciesOptions}
                list={this.props.agencies}
                onChange={(list) => this.handleChipsChange(list)}
              />
            </Col>
          ) : null}
        </Row>
        <FormCheckbox
          className="mb-20"
          text="Performance globale"
          name="overall"
          checked={this.state.overall}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="Retirer validation responsable"
          name="validateLeave"
          checked={this.state.validateLeave}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="Bloquer l'utilisateur dans la région"
          name="regionIsolate"
          checked={this.state.regionIsolate}
          onChange={this.handleCheckboxChange}
        />
        <Col xs={12} md={6} className="mb-20 d-flex">
          <FormInput
            type="date"
            name="dateStart"
            value={this.state.dateStart}
            title="Date début d'activité"
            onChange={this.handleInputChange}
          />
        </Col>
        <Col xs={12} md={6} className="mb-20 d-flex">
          <FormInput
            type="text"
            name="codeVE"
            value={this.state.codeVE}
            title="Code VE"
            onChange={this.handleInputChange}
          />
        </Col>
        <Button text="Modifier" onClick={(e) => this.handleEditUserClick(e)} />
        {this.state.isColor === true ? (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <PhotoshopPicker
              color={this.state.colorTmp}
              onAccept={(e) =>
                this.setState({ isColor: false, color: this.state.colorTmp })
              }
              onCancel={(e) => this.setState({ isColor: false })}
              onChangeComplete={this.handleChangeComplete}
            />
          </div>
        ) : (
          false
        )}
      </Modal>
    );
  }
}
