import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";
import axiosConfig from "./utils/axiosConfig";

import AuthContext from "./context/AuthContext";

import ReactGA from "react-ga4";
ReactGA.initialize("G-KYJXYNX8X6");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      connected: null,
      administration: null,
      products: null,
      name: null,
      email: null,
      urlPdf: null,
      network: null,
    };
  }

  componentDidMount() {
    this.checkAuth();
    this.intervalToken = setInterval(() => {
      this.checkAuth();
    }, 30 * 60 * 1000);
  }

  checkAuth = () => {
    const pathArray = document.location.href.split("/");
    if (navigator.onLine) {
      var url = window.location.href;
      var arr = url.split("/");
      let token = null;
      if (pathArray.includes("success")) {
        token = pathArray[pathArray.length - 1];
      }
      axiosConfig("/auth/refresh-token", {
        data: {
          token: token,
          url: arr[0] + "//" + arr[2],
        },
      }).then((res) => {
        if (res.data.success === true) {
          if (pathArray.includes("success")) {
            document.location.href = "/";
          } else {
            if (res.data.redirect) {
              document.location.href = res.data.redirect;
            } else {
              const oldState = this.state;
              const newState = {
                isReady: true,
                connected: true,
                administration: res.data.data.administration,
                products: {
                  isPilotageCabinet: res.data.data.isPilotageCabinet,
                  isPixTransfer: res.data.data.isPixTransfer,
                  isValorEasy: res.data.data.isValorEasy,
                  isEvalCar: res.data.data.isEvalCar,
                  isRedaxion: res.data.data.isRedaxion,
                  isXpertVisio: res.data.data.isXpertVisio,
                  isIxPerience: res.data.data.isIxPerience,
                  isGlaSoftware: res.data.data.isGlaSoftware,
                },
                name: res.data.data.name,
                email: res.data.data.email,
                urlPdf: res.data.urlPdf,
                network: res.data.data.network,
              };
              if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
                this.setState(newState);
              }
            }
          }
        } else {
          this.setState({ connected: false }, () => {
            document.location.href = res.data.redirect;
          });
        }
      });
    }
  };

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  render() {
    if (!this.state.isReady) {
      return <></>;
    }

    return (
      <AuthContext.Provider
        value={{
          name: this.state.name,
          email: this.state.email,
          urlPdf: this.state.urlPdf,
          isPilotageCabinet: this.state.products
            ? this.state.products.isPilotageCabinet
            : false,
          isPixTransfer: this.state.products
            ? this.state.products.isPixTransfer
            : false,
          isValorEasy: this.state.products
            ? this.state.products.isValorEasy
            : false,
          isEvalCar: this.state.products
            ? this.state.products.isEvalCar
            : false,
          isRedaxion: this.state.products
            ? this.state.products.isRedaxion
            : false,
          isXpertVisio: this.state.products
            ? this.state.products.isXpertVisio
            : false,
          isGlaSoftware: this.state.products
            ? this.state.products.isGlaSoftware
            : false,
          isIxPerience: this.state.products
            ? this.state.products.isIxPerience
            : false,
          administration: this.state.administration,
          network: this.state.network,
        }}
      >
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => {
                    return (
                      <route.layout
                        {...props}
                        connected={this.state.connected}
                        administration={this.state.administration}
                        products={this.state.products}
                        checkAuth={this.checkAuth}
                      >
                        <route.component
                          {...props}
                          products={this.state.products}
                          administration={this.state.administration}
                          checkAuth={this.checkAuth}
                        />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </Switch>
        </Router>
      </AuthContext.Provider>
    );
  }
}
