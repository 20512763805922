import React, { Component } from 'react'
import {
  Button,
  Card,
  Dropzone,
  FullModal,
  Loader
} from 'ui-kit-ck-consultant'
import PicturePreview from './PicturePreview'
import axiosConfig from '../../../utils/axiosConfig'
import Preview from './Preview'

export class ModalNetworkMedia extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoad: false,
      pictures: null,
      dataBlob: null,
      dataMime: null,
      name: null,
      extension: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.item && prevProps.item !== this.props.item) {
      this.setState({
        isLoad: true
      }, () => {
        axiosConfig("/pilotage-cabinet/media/get-media", {
          data: {
            id: this.props.item.id
          }
        }).then((res) => {
          let pictures = []
          if (res.data.success) {
            res.data.res.map((picture) => {
              pictures.push({
                id: picture["nm_id"],
                img: `data:${picture["nm_type"]};base64,${new Buffer.from(picture["nm_media"]).toString("base64")}`,
                name: picture["nm_name"] + picture["nm_extension"]
              })
              return false
            })
          }
          this.setState({
            pictures,
            isLoad: false
          })
        })
      })
    }
  }

  blobValue = (e) => {
    this.setState({
      dataBlob: e.base64,
      dataMime: e.mime,
      name: e.name.split('.')[0],
      extension: '.' + e.name.split('.')[1]
    });
  }

  handleClick = () => {
    this.setState({
      isLoad: true
    }, () => {
      axiosConfig("/pilotage-cabinet/media/insert-network-media", {
        data: {
          id: this.props.item.id,
          blob: this.state.dataBlob,
          mime: this.state.dataMime,
          name: this.state.name,
          extension: this.state.extension,
        }
      }).then((res) => {
        if (res.data.success === true) {
          let pictures = this.state.pictures
          pictures.push({
            id: pictures[pictures.length - 1].id + 1,
            img: this.state.dataBlob,
            name: this.state.name + this.state.extension
          })
          this.setState({
            dataBlob: null,
            dataMime: null,
            name: null,
            extension: null,
            pictures
          })
        }
        this.setState({ isLoad: false })
      })
    })
  }

  handleRemovePicture = (id) => {
    axiosConfig("/pilotage-cabinet/media/remove-network-media", {
      data: {
        id: id
      }
    }).then((res) => {
      if (res.data.success === true) {
        let pictures = this.state.pictures
        pictures = pictures.filter(pic => pic.id !== id)
        this.setState({ pictures })
      }
    })
  }


  render() {
    return (
      <FullModal show={this.props.show} title={this.props.item && this.props.item.name} onClose={this.props.onClose}>
        <Card title={'Ajouter une image'} className='mt-30' >
          <div className={'container'}>
            <Dropzone
              className={"m-auto my-10"}
              dropHandler={this.blobValue}
              typeName={[
                "jpeg",
                "png",
              ]}
              typeAccept={[
                "image/jpeg",
                "image/png",
              ]}
            />
            {this.state.dataBlob
              ? <>
                <Preview src={this.state.dataBlob} />
                <Button
                  text={'Valider'}
                  onClick={() => this.handleClick()}
                />
              </>
              : null}
          </div>
        </Card>
        <Card title={'Images'} className={'mt-30 '}>
          <PicturePreview
            pictures={this.state.pictures}
            handleRemovePicture={this.handleRemovePicture}
          />
        </Card>
        <Loader show={this.state.isLoad} />
      </FullModal>
    )
  }
}

export default ModalNetworkMedia
