import React from "react";
import { Modal, FormInput, FormCheckbox, Button } from "ui-kit-ck-consultant";
import axiosConfig from "../../utils/axiosConfig";

export default class ModalNetwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      isPilotageCabinet: false,
      isPixTransfer: false,
      isValorEasy: false,
      isEvalCar: false,
      isRedaxion: false,
      isXpertVisio: false,
      isGlaSoftware: false,
      isIxPerience: false,
      mailClaim: "",
      isError: false,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.setState({
        name: this.props.name,
        isPilotageCabinet: this.props.isPilotageCabinet,
        isPixTransfer: this.props.isPixTransfer,
        isValorEasy: this.props.isValorEasy,
        isEvalCar: this.props.isEvalCar,
        isRedaxion: this.props.isRedaxion,
        isXpertVisio: this.props.isXpertVisio,
        isGlaSoftware: this.props.isGlaSoftware,
        isIxPerience: this.props.isIxPerience,
        mailClaim: this.props.mailClaim,
      });
    } else if (prevProps.show !== this.props.show && !this.props.id) {
      this.setState({
        name: "",
        isPilotageCabinet: false,
        isPixTransfer: false,
        isValorEasy: false,
        isEvalCar: false,
        isRedaxion: false,
        isXpertVisio: false,
        isGlaSoftware: false,
        isIxPerience: false,
        mailClaim: "",
        isError: false,
      });
    }
  }

  handleAddNetworkClick = () => {
    if (this.state.name) {
      axiosConfig("/network/insert", {
        data: this.getData(),
      }).then((res) => {
        if (res.data.success === true) {
          alert("Le réseau a bien été ajouté");
          this.props.onClose();
        }
      });
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  handleEditNetworkClick = () => {
    if (this.state.name) {
      let data = this.getData();
      data.id = this.props.id;
      axiosConfig("/network/update", {
        data: data,
      }).then((res) => {
        if (res.data.success === true) {
          alert("Le réseau a bien été modifié");
          this.props.onClose();
        }
      });
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  getData() {
    return {
      name: this.state.name,
      isPilotageCabinet: this.state.isPilotageCabinet,
      isPixTransfer: this.state.isPixTransfer,
      isValorEasy: this.state.isValorEasy,
      isEvalCar: this.state.isEvalCar,
      isRedaxion: this.state.isRedaxion,
      isXpertVisio: this.state.isXpertVisio,
      isGlaSoftware: this.state.isGlaSoftware,
      isIxPerience: this.state.isIxPerience,
      mailClaim: this.state.mailClaim,
    };
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Modal
        title={this.props.isEdit ? "Modifier un réseau" : "Ajouter un réseau"}
        show={true}
        onClose={this.props.onClose}
      >
        <FormInput
          className="mb-20"
          type="text"
          name="name"
          value={this.state.name}
          title="Nom *"
          onChange={this.handleInputChange}
          error={
            this.state.isError === true && !this.state.name
              ? "Veuillez remplir ce champ"
              : ""
          }
        />
        <FormCheckbox
          className="mb-20"
          text="Pilotage Cabinet"
          name="isPilotageCabinet"
          checked={this.state.isPilotageCabinet}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="PixTransfer"
          name="isPixTransfer"
          checked={this.state.isPixTransfer}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="ValorEasy"
          name="isValorEasy"
          checked={this.state.isValorEasy}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="EvalCar"
          name="isEvalCar"
          checked={this.state.isEvalCar}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="Rédaxion"
          name="isRedaxion"
          checked={this.state.isRedaxion}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="XpertVisio"
          name="isXpertVisio"
          checked={this.state.isXpertVisio}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="GLA Software"
          name="isGlaSoftware"
          checked={this.state.isGlaSoftware}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="IxPérience"
          name="isIxPerience"
          checked={this.state.isIxPerience}
          onChange={this.handleCheckboxChange}
        />
        <FormInput
          className="mb-20"
          type="text"
          name="mailClaim"
          value={this.state.mailClaim}
          title="Mail de réclamation *"
          onChange={this.handleInputChange}
        />
        <Button
          className="mb-4"
          text={this.props.isEdit ? "Modifier" : "Ajouter"}
          onClick={() => {
            this.props.isEdit
              ? this.handleEditNetworkClick()
              : this.handleAddNetworkClick();
          }}
        />
      </Modal>
    );
  }
}
