import React from "react";
import {
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomBody,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";

import DocTypes from "../../components/ixperience/DocTypes";
import Notifications from "../../components/ixperience/Notifications";

import AuthContext from "../../context/AuthContext";

export default class Ixperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIdArray: [],
      tabsId: 0
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    if (this.context.administration >= 4) {
      this.setState({ 
        tabsIdArray: [
          "Types de documents",
          "Notifications"
        ]
      });
    } else {
      this.setState({ tabsIdArray: []});
    }
  }

  render() {
    return (
      <>
        <Nav className="mb-20" title="Ixpérience" />
        <TabsCustom>
          <TabsCustomHeader className="mt-0">
            {this.state.tabsIdArray.map((element, key) => (
              <TabsCustomMenu
                key={key}
                title={element}
                active={this.state.tabsId === key}
                onClick={() => this.setState({ tabsId: key })}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem
              active={
                this.state.tabsId === 
                  this.state.tabsIdArray.findIndex(el => el === "Types de documents")
              }
              className="overflow-x-auto"
            >
              <DocTypes />
            </TabsCustomItem>
            <TabsCustomItem
              active={
                this.state.tabsId === 
                  this.state.tabsIdArray.findIndex(el => el === "Notifications")
              }
              className="overflow-x-auto"
            >
              <Notifications />
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
      </>
    );
  }
}
