import React from "react";
import { Redirect } from "react-router-dom";
import { Error404 } from "ui-kit-ck-consultant";

// Layout Types
import DashboardLayout from "./layouts/Dashboard";

// Route Views
import Home from "./views/dashboard/Home";
import Network from "./views/dashboard/Network";
import Group from "./views/dashboard/Group";
import Region from "./views/dashboard/Region";
import Agency from "./views/dashboard/Agency";
import User from "./views/dashboard/User";
import Company from "./views/dashboard/Company";
import Principal from "./views/dashboard/Principal";
import Garage from "./views/dashboard/Garage";
import Client from "./views/dashboard/Client";
import PilotageCabinet from "./views/dashboard/PilotageCabinet";
import ValorEasy from "./views/dashboard/ValorEasy";
import EvalCar from "./views/dashboard/EvalCar";
import Ixperience from "./views/dashboard/Ixperience";
import PixTransfer from "./views/dashboard/PixTransfer";
import Glasoftware from "./views/dashboard/Glasoftware";
import Invoice from "./views/dashboard/Invoice";
import Synchronization from "./views/dashboard/Synchronization";

const routes = [
  {
    path: "/",
    exact: true,
    layout: DashboardLayout,
    component: () => <Redirect to="/home" />,
  },
  {
    path: "/home",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  },
  {
    path: "/network",
    exact: true,
    layout: DashboardLayout,
    component: Network,
  },
  {
    path: "/group",
    exact: true,
    layout: DashboardLayout,
    component: Group,
  },
  {
    path: "/region",
    exact: true,
    layout: DashboardLayout,
    component: Region,
  },
  {
    path: "/agency",
    exact: true,
    layout: DashboardLayout,
    component: Agency,
  },
  {
    path: "/user",
    exact: true,
    layout: DashboardLayout,
    component: User,
  },
  {
    path: "/company",
    exact: true,
    layout: DashboardLayout,
    component: Company,
  },
  {
    path: "/principal",
    exact: true,
    layout: DashboardLayout,
    component: Principal,
  },
  {
    path: "/garage",
    exact: true,
    layout: DashboardLayout,
    component: Garage,
  },
  {
    path: "/client",
    exact: true,
    layout: DashboardLayout,
    component: Client,
  },
  {
    path: "/pilotage-cabinet",
    exact: true,
    layout: DashboardLayout,
    component: PilotageCabinet,
  },
  {
    path: "/valoreasy",
    exact: true,
    layout: DashboardLayout,
    component: ValorEasy,
  },
  {
    path: "/evalcar",
    exact: true,
    layout: DashboardLayout,
    component: EvalCar,
  },
  {
    path: "/ixperience",
    exact: true,
    layout: DashboardLayout,
    component: Ixperience,
  },
  {
    path: "/pixtransfer",
    exact: true,
    layout: DashboardLayout,
    component: PixTransfer,
  },
  {
    path: "/glasoftware",
    exact: true,
    layout: DashboardLayout,
    component: Glasoftware,
  },
  {
    path: "/invoice",
    exact: true,
    layout: DashboardLayout,
    component: Invoice,
  },
  {
    path: "/synchronization",
    exact: true,
    layout: DashboardLayout,
    component: Synchronization,
  },
  {
    layout: Error404,
    component: null,
  },
];

export default routes;
