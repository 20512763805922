import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../utils/axiosConfig";

import ModalGroup from "./ModalGroup";

export default class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      isModalGroupEdit: false,
      modalGroupId: null,
      modalGroupName: null,
      modalGroupType1: null,
      modalGroupType2: null,
      modalGroupValue: null,
      modalGroupNegotiationMin: null,
      modalGroupNegotiationMax: null,
    };
  }

  componentDidMount() {
    this.getGroup();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isModalGroupEdit !== this.state.isModalGroupEdit &&
      this.state.isModalGroupEdit === false
    ) {
      this.getGroup();
    }
  }

  getGroup() {
    axiosConfig("/evalcar/group/get-group").then((res) => {
      if (res.data.success === true) {
        this.setState({ groups: res.data.data });
      } else {
        this.setState({ groups: [] });
      }
    });
  }

  render() {
    return (
      <>
        <Table
          isCSV
          radiusTopLeft
          empty={
            this.state.groups.length > 0 ? "" : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th hide>#</Th>
              <Th radiusTopLeft>Nom</Th>
              <Th>Type de document état descriptif</Th>
              <Th>Type de document synthèse</Th>
              <Th>+/- val.</Th>
              <Th>Marge de négociation</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.groups.map((element, idx) => (
              <Tr key={idx}>
                <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                <Td>{element.name}</Td>
                <Td>{element.type1 ? element.type1 : "NR"}</Td>
                <Td>{element.type2 ? element.type2 : "NR"}</Td>
                <Td>{element.value === 1 ? "Général" : "Annonce"}</Td>
                <Td>
                  {(element.negotiationMin ? element.negotiationMin : "") +
                    " | " +
                    (element.negotiationMax ? element.negotiationMax : "")}
                </Td>
                <Td className="nowrap text-center">
                  <ButtonIcon
                    small
                    className="blue"
                    onClick={() => {
                      this.setState({
                        isModalGroupEdit: true,
                        modalGroupId: element.id,
                        modalGroupName: element.name,
                        modalGroupType1: element.type1,
                        modalGroupType2: element.type2,
                        modalGroupValue: element.value,
                        modalGroupNegotiationMin: element.negotiationMin,
                        modalGroupNegotiationMax: element.negotiationMax,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <ModalGroup
          show={this.state.isModalGroupEdit}
          id={this.state.modalGroupId}
          name={this.state.modalGroupName}
          type1={this.state.modalGroupType1}
          type2={this.state.modalGroupType2}
          value={this.state.modalGroupValue}
          negotiationMin={this.state.modalGroupNegotiationMin}
          negotiationMax={this.state.modalGroupNegotiationMax}
          onClose={() =>
            this.setState({
              isModalGroupEdit: false,
              modalGroupId: null,
              modalGroupName: null,
              modalGroupType1: null,
              modalGroupType2: null,
              modalGroupValue: null,
              modalGroupNegotiationMin: null,
              modalGroupNegotiationMax: null,
            })
          }
        />
      </>
    );
  }
}
