import React, { createRef } from "react";
import {
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomBody,
  TabsCustomItem,
  TabsCustomMenu,
  Button,
  Card,
  FormInput
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col
} from "react-flexbox-grid"

import User from "../../components/pilotage-cabinet/user/User";
import Agency from "../../components/pilotage-cabinet/agency/Agency";
import CompanyMonitoring from "../../components/pilotage-cabinet/company-monitoring/CompanyMonitoring";
import Media from "../../components/pilotage-cabinet/media/Media";
import Formations from "../../components/pilotage-cabinet/formation/Formations";
import ShowOptionNGRA from "../../components/ShowOptionNGRA"

import AuthContext from "../../context/AuthContext"

export default class PilotageCabinet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIdArray: ["Utilisateurs", "Agences", "Suivi compagnie", "Médias", "Formations"],
      tabsId: 0,
      search: '',
      network: null,
      group: null,
      region: null,
      agency: null,
    };
    this.refUser = createRef()
    this.refAgency = createRef()
    this.refCompanyMonitoring = createRef()
    this.refFormations = createRef()
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (this.state.tabsId === 0) {
        this.refUser.current.handleSearch(this.state.search)
      } else if (this.state.tabsId === 1) {
        this.refAgency.current.handleSearch(this.state.search)
      }
    }
  }

  componentDidMount() {
    if (this.context.administration < 4) {
      this.setState({
        tabsIdArray: [
          "Utilisateurs",
          "Agences"
        ]
      })
    }
  }

  render() {
    return (
      <>
        <Nav className="mb-20" title="Pilotage cabinet">
          {this.state.tabsId === 2 || this.state.tabsId === 5 ? <Button
            text="Ajouter"
            onClick={() => {
              if (this.state.tabsId === 2) {
                this.refCompanyMonitoring.current.handleClickAdd()
              } else {
                this.refFormations.current.handleAddFormation()
              }
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button> : null}
        </Nav>
        <Row>
          <Col sm={12} md={4}>
            {
              this.state.tabsId === 0 ? <Card className="mb-20">
                <FormInput
                  className="mb-20"
                  value={this.state.search}
                  name="search"
                  onChange={this.handleInputChange}
                  title="Nom ou adresse mail de l'utilisateur"
                  onKeyDown={this.handleSearchKeyDown}>
                  <FontAwesomeIcon className="icon" icon={faSearch} />
                </FormInput>
              </Card> : null
            }
            {
              this.state.tabsId === 1 ? <Card className="mb-20">
                <FormInput
                  className="mb-20"
                  value={this.state.search}
                  name="search"
                  onChange={this.handleInputChange}
                  title="Nom de l'agence"
                  onKeyDown={this.handleSearchKeyDown}>
                  <FontAwesomeIcon className="icon" icon={faSearch} />
                </FormInput>
              </Card> : null
            }
          </Col>

          <Col sm={12} md={8}>
            {
              [0, 1].includes(this.state.tabsId) ?
                <ShowOptionNGRA
                  handleDataChange={(
                    network,
                    group,
                    region,
                    agency
                  ) => {
                    this.setState({
                      network,
                      group,
                      region,
                      agency
                    }, () => {
                      if (this.state.tabsId === 0) {
                        this.refUser.current.handleSearch(this.state.search)
                      } else if (this.state.tabsId === 1) {
                        this.refAgency.current.handleSearch(this.state.search)
                      }
                    })
                  }}
                /> : null
            }
          </Col>
        </Row>
        <TabsCustom>
          <TabsCustomHeader className="mt-0">
            {
              this.state.tabsIdArray.map((element, key) => (
                <TabsCustomMenu
                  key={key}
                  title={element}
                  active={this.state.tabsId === key}
                  onClick={(e) => { this.setState({ tabsId: key }); }}
                />
              ))
            }
          </TabsCustomHeader>
          <TabsCustomBody>
            <TabsCustomItem
              active={this.state.tabsId === 0}
              className="overflow-x-auto"
            >
              <User
                network={this.state.network}
                group={this.state.group}
                region={this.state.region}
                agency={this.state.agency}
                ref={this.refUser}
              />
            </TabsCustomItem>
            <TabsCustomItem
              active={this.state.tabsId === 1}
              className="overflow-x-auto"
            >
              <Agency
                network={this.state.network}
                group={this.state.group}
                region={this.state.region}
                agency={this.state.agency}
                ref={this.refAgency}
              />
            </TabsCustomItem>
            <TabsCustomItem
              active={this.state.tabsId === 2}
              className="overflow-x-auto"
            >
              <CompanyMonitoring
                ref={this.refCompanyMonitoring}
              />
            </TabsCustomItem>
            <TabsCustomItem
              active={this.state.tabsId === 3}
              className="overflow-x-auto"
            >
              <Media />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsId === 4} className="overflow-x-auto">
              <Formations
                ref={this.refFormations}
              />
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
      </>
    )
  }
}