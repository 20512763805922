import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { ButtonIcon } from 'ui-kit-ck-consultant'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export class PicturePreview extends Component {

  render() {
    return (
      <div>
        <div className='container'>
          {this.props.pictures && this.props.pictures.map((item, idx) => 
            (
              <div key={idx} className='picture'>
                <img src={item.img} alt={idx} />
                <ButtonIcon
                  className='close'
                  onClick={() =>this.props.handleRemovePicture(item.id)}
                  >
                  <FontAwesomeIcon icon={faTimes} color={'red'}/>
                </ButtonIcon>
                <span>{item.name}</span>
              </div>
            )
          )}
        </div>
      </div>
    )
  }
}

export default PicturePreview
