import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../utils/axiosConfig";

import ModalUser from "./ModalUser";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isModalUserEdit: false,
      modalUserId: null,
      modalUserName: null,
      modalUserGlobal: null,
      order: null,
    };

    this.refColumnName = React.createRef();
    this.refColumnAccessGroup = React.createRef();
    this.refColumnFolders = React.createRef();
    this.refColumnFoldersMonth = React.createRef();
  }

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnAccessGroup.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnFolders.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnFoldersMonth.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "access_group", desc: desc };
        break;
      case 2:
        order = { name: "folders", desc: desc };
        break;
      case 3:
        order = { name: "foldersMonth", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.getUser();
    });
  };

  componentDidMount() {
    this.getUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isModalUserEdit !== this.state.isModalUserEdit &&
      this.state.isModalUserEdit === false
    ) {
      this.getUser();
    }
  }

  getUser() {
    axiosConfig("/evalcar/user/get-user", {
      data: {
        network: this.props.network,
        group: this.props.group,
        region: this.props.region,
        agency: this.props.agency,
        search: this.props.search,
        orderName: this.state.order ? this.state.order.name : null,
        orderDesc: this.state.order ? this.state.order.desc : null,
      },
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({ users: res.data.data });
      } else {
        this.setState({ users: [] });
      }
    });
  }

  render() {
    return (
      <>
        <Table
          isCSV
          radiusTopLeft
          empty={
            this.state.users.length > 0 ? "" : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th hide>#</Th>
              <Th
                ref={this.refColumnName}
                sort
                onSort={(desc) => this.handleSortClick(desc, 0)}
                radiusTopLeft
              >
                Nom
              </Th>
              <Th
                ref={this.refColumnAccessGroup}
                sort
                onSort={(desc) => this.handleSortClick(desc, 1)}
              >
                Visualisation groupe
              </Th>
              <Th
                ref={this.refColumnFolders}
                sort
                onSort={(desc) => this.handleSortClick(desc, 2)}
              >
                Dossiers
              </Th>
              <Th
                ref={this.refColumnFoldersMonth}
                sort
                onSort={(desc) => this.handleSortClick(desc, 3)}
              >
                Dossier m
              </Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.users.map((element, idx) => (
              <Tr key={idx}>
                <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                <Td>{element.name}</Td>
                <Td>{element.global ? "Oui" : "Non"}</Td>
                <Td>{element.folders}</Td>
                <Td>{element.foldersMonth}</Td>
                <Td className="nowrap text-center">
                  <ButtonIcon
                    small
                    className="blue"
                    onClick={() => {
                      this.setState({
                        isModalUserEdit: true,
                        modalUserId: element.id,
                        modalUserName: element.name,
                        modalUserGlobal: element.global,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <ModalUser
          show={this.state.isModalUserEdit}
          id={this.state.modalUserId}
          name={this.state.modalUserName}
          global={this.state.modalUserGlobal}
          onClose={() =>
            this.setState({
              isModalUserEdit: false,
              modalUserId: null,
              modalUserName: null,
              modalUserGlobal: null,
            })
          }
        />
      </>
    );
  }
}
