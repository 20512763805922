const colors = {
  green: ["#0c8540", "#bcf5d5", "#01612a"],
  cyan: ["#00c0ba", "#befffd", "#026a67"],
  blue: ["#026fc2", "#bbe1fe", "#023f6d"],
  purple: ["#6a3ab3", "#dcc5fe", "#41236d"],
  pink: ["#b40c8d", "#ffbcf0", "#7a0e61"],
  red: ["#db2828", "#ffb8b8", "#9d0c0c"],
  orange: ["#f2711c", "#fecaa9", "#b94900"],
  yellow: ["#ffcc00", "#fbefc0", "#d5ab00"],
  gray: ["#d8d8d8", "#f3f3f3"],
  white: ["#ffffff"],
  black: ["#1e1f26"],
};

const getColors = () => {
  const filtered = Object.keys(colors).filter(
    (key) => colors[key].length === 3
  );

  let result = [];

  filtered.forEach((key) => {
    result.push(colors[key][0]);
  });
  filtered.forEach((key) => {
    result.push(colors[key][1]);
  });
  filtered.forEach((key) => {
    result.push(colors[key][2]);
  });

  return result;
};

const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export { colors, getColors, convertHexToRGBA };
