import React from "react";
import {
  Nav,
  Button,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  FormInput,
  Pagination,
  PaginationClass,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTimes,
  faSearch,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../utils/axiosConfig";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import { ExportCSV } from "react-export-csv";

import pilotagecabinetLogo from "../../images/pilotagecabinet.svg";
import pixtransferLogo from "../../images/pixtransfer.svg";
import valoreasyLogo from "../../images/valoreasy.svg";
import evalcarLogo from "../../images/evalcar.svg";
import redaxionLogo from "../../images/redaxion.svg";
import xpertvisioLogo from "../../images/xpertvisio.svg";
import ixperienceLogo from "../../images/ixperience.svg";
import glaSoftwareLogo from "../../images/glasoftware.png"

import ModalNetwork from "../../components/network/ModalNetwork";

export default class Network extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      activePage: 1,
      tabLength: 20,
      total: 0,
      networks: [],
      isModalNetworkAdd: false,
      isModalNetworkEdit: false,
      modalNetworkId: null,
      modalNetworkName: null,
      modalNetworkIsPilotageCabinet: null,
      modalNetworkIsPixtransfer: null,
      modalNetworkIsValorEasy: null,
      modalNetworkIsEvalCar: null,
      modalNetworkIsRedaxion: null,
      modalNetworkIsXpertVisio: null,
      modalNetworkIsGlaSoftware: null,
      modalNetworkMailClaim: null,
      search: "",
      order: "",
      isDownloadCSV: false,
      headerCSV: [
        {
          name: "Nom",
          key: "name",
        },
        {
          name: "Agences",
          key: "countAgency",
        },
      ],
    };

    this.refColumnName = React.createRef();
    this.refColumnAgency = React.createRef();
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.initSearch();
    }
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnAgency.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "agency", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({
        networks: result.tabData,
      });
    } else {
      this.setState({ networks: [] });
    }
  }

  getData() {
    return {
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "network/count",
      "network/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.isModalNetworkAdd !== this.state.isModalNetworkAdd &&
        this.state.isModalNetworkAdd === false) ||
      (prevState.isModalNetworkEdit !== this.state.isModalNetworkEdit &&
        this.state.isModalNetworkEdit === false)
    ) {
      this.initSearch();
    }
  }

  handleDeleteNetworkClick(id) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce réseau ?")) {
      axiosConfig("/network/delete", {
        data: { id: id },
      }).then((res) => {
        if (res.data.success === true) {
          alert("Le réseau a bien été supprimé");
          this.initSearch();
        }
      });
    }
  }

  render() {
    return (
      <>
        <Nav className="mb-20" title="Réseaux">
          {this.props.administration === 5 ? (
            <Button
              text="Ajouter un réseau"
              onClick={() => {
                this.setState({
                  isModalNetworkAdd: true,
                });
              }}
            />
          ) : (
            ""
          )}
        </Nav>
        <Row>
          <Col sm={12} md={6}>
            <Card className="mb-20">
              <FormInput
                value={this.state.search}
                name="search"
                onChange={this.handleInputChange}
                placeholder="Nom du réseau"
                onKeyDown={this.handleSearchKeyDown}
              >
                <FontAwesomeIcon className="icon" icon={faSearch} />
              </FormInput>
            </Card>
          </Col>
        </Row>
        <Card
          title="Liste des réseaux"
          classNameChildren="overflow-x-auto"
          action={
            <ButtonIcon
              className="blue p-0"
              onClick={() => this.setState({ isDownloadCSV: true })}
            >
              <FontAwesomeIcon icon={faDownload} />
            </ButtonIcon>
          }
        >
          <Table
            isCSV
            empty={
              this.state.networks.length > 0
                ? ""
                : "Aucune donnée n'est disponible"
            }
            radiusTopLeft
          >
            <Thead>
              <Tr>
                <Th hide>#</Th>
                <Th
                  ref={this.refColumnName}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                  radiusTopLeft
                >
                  Nom
                </Th>
                <Th>Produit(s)</Th>
                <Th
                  ref={this.refColumnAgency}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                >
                  Agences
                </Th>
                <Th>Mail de réclamation</Th>
                {this.props.administration === 5 ? <Th>Actions</Th> : <></>}
              </Tr>
            </Thead>
            <Tbody>
              {this.state.networks.map((element, idx) => (
                <Tr key={idx}>
                  <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                  <Td>{element.name}</Td>
                  <Td className="nowrap">
                    {element.isPilotageCabinet ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={pilotagecabinetLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isPixTransfer ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={pixtransferLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isValorEasy ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={valoreasyLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isEvalCar ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={evalcarLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isRedaxion ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={redaxionLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isXpertVisio ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={xpertvisioLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isGlaSoftware ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={glaSoftwareLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isIxPerience ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={ixperienceLogo}
                      />
                    ) : (
                      ""
                    )}
                  </Td>
                  <Td>{element.countAgency}</Td>
                  <Td>{element.mailClaim}</Td>
                  {this.props.administration === 5 ? (
                    <Td className="nowrap">
                      <div className="d-flex">
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => {
                            this.setState({
                              isModalNetworkEdit: true,
                              modalNetworkId: element.id,
                              modalNetworkName: element.name,
                              modalNetworkIsPilotageCabinet:
                                element.isPilotageCabinet,
                              modalNetworkIsPixtransfer: element.isPixTransfer,
                              modalNetworkIsValorEasy: element.isValorEasy,
                              modalNetworkIsEvalCar: element.isEvalCar,
                              modalNetworkIsRedaxion: element.isRedaxion,
                              modalNetworkIsXpertVisio: element.isXpertVisio,
                              modalNetworkIsGlaSoftware: element.isGlaSoftware,
                              modalNetworkIsIxPerience: element.isIxPerience,
                              modalNetworkMailClaim: element.mailClaim,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() =>
                            this.handleDeleteNetworkClick(element.id)
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ButtonIcon>
                      </div>
                    </Td>
                  ) : (
                    <></>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            className="mt-10"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.handlePageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        {this.props.administration === 5 ? (
          <ModalNetwork
            show={this.state.isModalNetworkAdd || this.state.isModalNetworkEdit}
            isEdit={this.state.isModalNetworkEdit}
            id={this.state.modalNetworkId}
            name={this.state.modalNetworkName}
            isPilotageCabinet={this.state.modalNetworkIsPilotageCabinet}
            isPixTransfer={this.state.modalNetworkIsPixtransfer}
            isValorEasy={this.state.modalNetworkIsValorEasy}
            isEvalCar={this.state.modalNetworkIsEvalCar}
            isRedaxion={this.state.modalNetworkIsRedaxion}
            isXpertVisio={this.state.modalNetworkIsXpertVisio}
            isGlaSoftware={this.state.modalNetworkIsGlaSoftware}
            isIxPerience={this.state.modalNetworkIsIxPerience}
            mailClaim={this.state.modalNetworkMailClaim}
            onClose={() =>
              this.setState({
                isModalNetworkAdd: false,
                isModalNetworkEdit: false,
                modalNetworkId: null,
                modalNetworkName: null,
                modalNetworkIsPilotageCabinet: null,
                modalNetworkIsPixtransfer: null,
                modalNetworkIsValorEasy: null,
                modalNetworkIsEvalCar: null,
                modalNetworkIsRedaxion: null,
                modalNetworkIsXpertVisio: null,
                modalNetworkIsGlaSoftware: null,
                modalNetworkMailClaim: null,
              })
            }
          />
        ) : (
          ""
        )}
        {this.state.isDownloadCSV ? (
          <ExportCSV
            header={this.state.headerCSV}
            data={this.state.networks}
            separator={";"}
            callback={() => this.setState({ isDownloadCSV: false })}
            isDownload={true}
            filename={`Réseaux P${this.state.activePage} ${moment().format(
              "DD-MM-YYYY HH-mm-ss"
            )}.csv`}
          />
        ) : null}
      </>
    );
  }
}
