import React from "react";
import {
  Nav,
  Button,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  FormInput,
  Pagination,
  PaginationClass,
  Loader,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTimes,
  faSearch,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faFileUpload,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../utils/axiosConfig";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import { ExportCSV } from "react-export-csv";

import ModalGroup from "../../components/group/ModalGroup";

import AuthContext from "../../context/AuthContext";

export default class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      activePage: 1,
      tabLength: 20,
      total: 0,
      groups: [],
      isModalGroupAdd: false,
      isModalGroupEdit: false,
      modalGroupId: null,
      modalGroupName: null,
      modalGroupNetwork: null,
      modalGroupIsIxPerience: null,
      modalGroupMailClaim: null,
      modalGroupTypeDocumentContact: null,
      modalGroupColor: null,
      modalGroupIsPrecisia: null,
      search: "",
      order: "",
      idLogo: null,
      isDownloadCSV: false,
      headerCSV: [
        {
          name: "Nom",
          key: "name",
        },
        {
          name: "Réseau",
          key: "networkName",
        },
        {
          name: "Agences",
          key: "countAgency",
        },
      ],
    };
    this.refColumnName = React.createRef();
    this.refColumnNetwork = React.createRef();
    this.refColumnAgency = React.createRef();

    this.refInputFile = React.createRef();
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.initSearch();
    }
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnNetwork.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnAgency.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "network", desc: desc };
        break;
      case 2:
        order = { name: "agency", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ isLoad: false, groups: result.tabData });
    } else {
      this.setState({ isLoad: false, groups: [] });
    }
  }

  getData() {
    return {
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "group/count",
      "group/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.isModalGroupAdd !== this.state.isModalGroupAdd &&
        this.state.isModalGroupAdd === false) ||
      (prevState.isModalGroupEdit !== this.state.isModalGroupEdit &&
        this.state.isModalGroupEdit === false)
    ) {
      this.initSearch();
    }
  }

  handleDeleteGroupClick(id) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce groupe ?")) {
      axiosConfig("/group/delete", {
        data: { id: id },
      }).then((res) => {
        if (res.data.success === true) {
          alert("Le groupe a bien été supprimé");
          this.initSearch();
        }
      });
    }
  }

  handleGetLogoClick(id) {
    this.refInputFile.click();
    this.setState({ idLogo: id });
  }

  onFileChange = (e) => {
    if (e.target.files.hasOwnProperty(0)) {
      this.setState({ isLoad: true });
      const type = e.target.files[0].type;
      const extension = null;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        if (e.target.files[0].size / 1000 < 64) {
          axiosConfig("group/update-logo", {
            data: {
              id: this.state.idLogo,
              base64: reader.result,
              type: type,
              extension: extension,
            },
          }).then((res) => {
            if (res.data.success === true) {
              this.handlePageChange(this.state.activePage);
            }
            this.setState({ isLoad: false });
          });
        } else {
          alert("Taille maximale : 64Kb");
        }
      };
    }
  };

  render() {
    return (
      <>
        <Nav className="mb-20" title="Groupes">
          {this.props.administration === 5 ? (
            <Button
              text="Ajouter un groupe"
              onClick={() => {
                this.setState({
                  isModalGroupAdd: true,
                });
              }}
            />
          ) : (
            ""
          )}
        </Nav>
        <Row>
          <Col sm={12} md={6}>
            <Card className="mb-20">
              <FormInput
                value={this.state.search}
                name="search"
                onChange={this.handleInputChange}
                placeholder="Nom du groupe"
                onKeyDown={this.handleSearchKeyDown}
              >
                <FontAwesomeIcon className="icon" icon={faSearch} />
              </FormInput>
            </Card>
          </Col>
        </Row>
        <Card
          title="Liste des groupes"
          classNameChildren="overflow-x-auto"
          action={
            <ButtonIcon
              className="blue p-0"
              onClick={() => this.setState({ isDownloadCSV: true })}
            >
              <FontAwesomeIcon icon={faDownload} />
            </ButtonIcon>
          }
        >
          <Table
            isCSV
            radiusTopLeft
            empty={
              this.state.groups.length > 0
                ? ""
                : "Aucune donnée n'est disponible"
            }
          >
            <Thead>
              <Tr>
                <Th hide>#</Th>
                <Th
                  ref={this.refColumnName}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                  radiusTopLeft
                >
                  Nom
                </Th>
                <Th
                  ref={this.refColumnNetwork}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                >
                  Réseau
                </Th>
                <Th
                  ref={this.refColumnAgency}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 2)}
                >
                  Agences
                </Th>
                <Th>IxPérience</Th>
                <Th>Logo</Th>
                <Th>Mail de réclamation</Th>
                <Th>Type doc. contact</Th>
                <Th>Couleurs</Th>
                <Th>PrécisIA</Th>
                {this.props.administration === 5 ? <Th>Actions</Th> : <></>}
              </Tr>
            </Thead>
            <Tbody>
              {this.state.groups.map((element, idx) => (
                <Tr key={idx}>
                  <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                  <Td>{element.name}</Td>
                  <Td>{element.networkName}</Td>
                  <Td>{element.countAgency}</Td>
                  <Td>{element.isIxPerience ? "Oui" : "Non"}</Td>
                  <Td>
                    <div className="d-flex">
                      <img
                        className="m-auto p-10"
                        alt="logo"
                        src={element.logo}
                        style={{
                          maxHeight: "60px",
                          maxWidth: "100%",
                        }}
                      />
                    </div>
                  </Td>
                  <Td>{element.mailClaim}</Td>
                  <Td>{element.typeDocumentContact}</Td>
                  <Td>
                    {element.color ? (
                      <div className="d-flex">
                        <div
                          className="m-auto"
                          style={{
                            borderRadius: "8px",
                            height: "16px",
                            width: "16px",
                            backgroundColor: element.color.primary,
                          }}
                        />
                        <div
                          className="m-auto"
                          style={{
                            borderRadius: "8px",
                            height: "16px",
                            width: "16px",
                            backgroundColor: element.color.secondary,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Td>
                  <Td>{element.isPrecisia ? "Oui" : "Non"}</Td>
                  {this.props.administration === 5 ? (
                    <Td className="nowrap">
                      <div className="d-flex">
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => {
                            this.setState({
                              isModalGroupEdit: true,
                              modalGroupId: element.id,
                              modalGroupName: element.name,
                              modalGroupNetwork: element.network,
                              modalGroupIsIxPerience: element.isIxPerience,
                              modalGroupMailClaim: element.mailClaim,
                              modalGroupTypeDocumentContact:
                                element.typeDocumentContact,
                              modalGroupColor: element.color,
                              modalGroupIsPrecisia: element.isPrecisia,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.handleGetLogoClick(element.id)}
                        >
                          <FontAwesomeIcon icon={faFileUpload} />
                        </ButtonIcon>
                        <input
                          ref={(input) => (this.refInputFile = input)}
                          type="file"
                          onChange={this.onFileChange}
                          style={{ display: "none" }}
                        />
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() =>
                            this.handleDeleteGroupClick(element.id)
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ButtonIcon>
                      </div>
                    </Td>
                  ) : (
                    <></>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            className="mt-10"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.handlePageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        {this.props.administration === 5 ? (
          <ModalGroup
            show={this.state.isModalGroupAdd || this.state.isModalGroupEdit}
            isEdit={this.state.isModalGroupEdit}
            id={this.state.modalGroupId}
            name={this.state.modalGroupName}
            network={this.state.modalGroupNetwork}
            isIxPerience={this.state.modalGroupIsIxPerience}
            isPrecisia={this.state.modalGroupIsPrecisia}
            mailClaim={this.state.modalGroupMailClaim}
            typeDocumentContact={this.state.modalGroupTypeDocumentContact}
            primaryColor={
              this.state.modalGroupColor
                ? this.state.modalGroupColor.primary
                : ""
            }
            secondaryColor={
              this.state.modalGroupColor
                ? this.state.modalGroupColor.secondary
                : ""
            }
            onClose={() =>
              this.setState({
                isModalGroupAdd: false,
                isModalGroupEdit: false,
                modalGroupId: null,
                modalGroupName: null,
                modalGroupNetwork: null,
                modalGroupIsIxPerience: null,
                modalGroupMailClaim: null,
                modalGroupTypeDocumentContact: null,
                modalGroupColor: null,
                modalGroupIsPrecisia: null,
              })
            }
          />
        ) : (
          ""
        )}
        <Loader show={this.state.isLoad} />
        {this.state.isDownloadCSV ? (
          <ExportCSV
            header={this.state.headerCSV}
            data={this.state.groups}
            separator={";"}
            callback={() => this.setState({ isDownloadCSV: false })}
            isDownload={true}
            filename={`Groupes P${this.state.activePage} ${moment().format(
              "DD-MM-YYYY HH-mm-ss"
            )}.csv`}
          />
        ) : null}
      </>
    );
  }
}
