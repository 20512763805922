import React from "react";
import { Nav, CardColor, Loader, Button } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";

import { getValue } from "../../utils/general";

import AuthContext from "../../context/AuthContext";

export default class Glasoftware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      errorDocument: [],
      errorDocumentPixTransfer: [],
      errorDocumentIxperience: [],
      countDocuments24: 0,
      countManagings24: 0,
      countMaileva: 0,
      current: 0,
      length: 0,
      isUpdate: false,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getErrorDocument();
    this.getCount24();
  }

  getErrorDocument = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("glasoftware/get-error-document", {
        data: {
          isUpdate: this.state.isUpdate,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              isLoad: false,
              errorDocument: res.data.data,
              errorDocumentPixTransfer: res.data.dataPixTransfer,
              errorDocumentIxperience: res.data.dataIxperience,
            });
          } else {
            this.setState({
              isLoad: false,
              errorDocument: [],
              errorDocumentPixTransfer: [],
              errorDocumentIxperience: [],
            });
          }
        })
        .catch(() => this.setState({ isLoad: false }));
    });
  };

  sendErrorDocument = (type = "") => {
    let {
      current,
      errorDocument,
      errorDocumentIxperience,
      errorDocumentPixTransfer,
    } = this.state;

    let data = errorDocument;

    if (type === "pixtransfer") {
      data = errorDocumentPixTransfer;
    } else if (type === "ixperience") {
      data = errorDocumentIxperience;
    }

    this.setState({ isLoad: true, length: data.length }, () => {
      axiosConfig("glasoftware/send-error-document", {
        data: {
          ...data[current],
          source: type,
        },
      })
        .then(() => {
          current += 1;
          if (data.length === current) {
            this.setState(
              {
                isLoad: false,
                current: 0,
                length: 0,
              },
              this.getErrorDocument
            );
          } else {
            this.setState(
              {
                current,
              },
              () => this.sendErrorDocument(type)
            );
          }
        })
        .catch(() => {
          current += 1;
          if (data.length === current) {
            this.setState(
              {
                isLoad: false,
                current: 0,
                length: 0,
              },
              this.getErrorDocument
            );
          } else {
            this.setState(
              {
                current,
              },
              () => this.sendErrorDocument(type)
            );
          }
        });
    });
  };

  getCount24 = () => {
    axiosConfig("glasoftware/get-count-24", {
      data: {},
    })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            countDocuments24: res.data.countDocuments24,
            countManagings24: res.data.countManagings24,
            countMaileva: res.data.countMaileva,
          });
        } else {
          this.setState({
            countDocuments24: 0,
            countManagings24: 0,
            countMaileva: 0,
          });
        }
      })
      .catch(() => this.setState({ isLoad: false }));
  };

  render() {
    const { isUpdate, current, length } = this.state;

    return (
      <>
        <Nav className="mb-20" title="GLA Software">
          <Button
            text={`Mode mise à jour (${isUpdate ? "Actif" : "Inactif"})`}
            onClick={() =>
              this.setState({ isUpdate: !isUpdate }, this.getErrorDocument())
            }
          />
        </Nav>
        <Row>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.state.errorDocument.length}
              title={`Erreur import WS > ${isUpdate ? "10min" : "6h"}`}
              subtitle={"Cliquez pour relancer l'envoi"}
              className={
                this.state.errorDocument.length ? "bg-orange" : "bg-green"
              }
              onClick={() => this.sendErrorDocument()}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.state.errorDocumentPixTransfer.length}
              title={`Erreur PixTransfer > ${isUpdate ? "10min" : "6h"}`}
              subtitle={"Cliquez pour relancer l'envoi"}
              className={
                this.state.errorDocumentPixTransfer.length
                  ? "bg-orange"
                  : "bg-green"
              }
              onClick={() => this.sendErrorDocument("pixtransfer")}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={this.state.errorDocumentIxperience.length}
              title={`Erreur Ixpérience > ${isUpdate ? "10min" : "6h"}`}
              subtitle={"Cliquez pour relancer l'envoi"}
              className={
                this.state.errorDocumentIxperience.length
                  ? "bg-orange"
                  : "bg-green"
              }
              onClick={() => this.sendErrorDocument("ixperience")}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={getValue(this.state.countDocuments24, 0)}
              title={`Insertion documents 24h`}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={getValue(this.state.countManagings24, 0)}
              title={`Insertion lignes de suivi 24h`}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-20">
            <CardColor
              count={getValue(this.state.countMaileva, 0)}
              title={`Courriers Maileva GLA`}
            />
          </Col>
        </Row>
        <Loader
          show={this.state.isLoad}
          message={length ? `${current}/${length}` : ""}
        />
      </>
    );
  }
}
