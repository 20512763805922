import React from "react";
import {
  Modal,
  FormSelect,
  FormInput,
  Button
} from "ui-kit-ck-consultant";
import axiosConfig from "../../utils/axiosConfig"
import { getNetworkSelect, getGroupSelect } from "../../utils/general"

export default class ModalRegion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      network: "DEFAULT",
      networkOptions: [],
      group: "DEFAULT",
      groupOptions: [],
      regionList: "DEFAULT",
      regionListOptions: [],
      mailClaim: "",
      isError: false
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    }, () => {
      if (name === "network") {
        this.getGroup()
      }
    });
  }

  componentDidMount() {
    this.getNetwork()
    this.getRegionList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.setState({
        network: this.props.network,
        regionList: this.props.regionList,
        mailClaim: this.props.mailClaim,
      }, () => {
        this.getGroup()
      })
    } else if (
      prevProps.show !== this.props.show &&
      !this.props.id
    ) {
      this.setState({
        network: "DEFAULT",
        group: "DEFAULT",
        regionList: "DEFAULT",
        mailClaim: "",
        isError: false,
      })
    }
  }

  getData() {
    return {
      network: this.state.network,
      group: this.state.group,
      regionList: this.state.regionList,
      mailClaim: this.state.mailClaim,
    }
  }

  handleAddRegionClick = () => {
    if (
      this.state.network !== "DEFAULT" &&
      this.state.group !== "DEFAULT" &&
      this.state.regionList !== "DEFAULT"
    ) {
      axiosConfig("/region/insert", {
        data: this.getData()
      }).then((res) => {
        if (res.data.success === true) {
          alert("La région a bien été ajoutée")
          this.props.onClose()
        }
      });
    } else {
      this.setState({
        isError: true
      })
    }
  }

  handleEditRegionClick = () => {
    if (
      this.state.network !== "DEFAULT" &&
      this.state.group !== "DEFAULT" &&
      this.state.regionList !== "DEFAULT"
    ) {
      let data = this.getData()
      data.id = this.props.id
      axiosConfig("/region/update", {
        data: data
      }).then((res) => {
        if (res.data.success === true) {
          alert("La région a bien été modifiée")
          this.props.onClose()
        }
      });
    } else {
      this.setState({
        isError: true
      })
    }
  }

  getNetwork() {
    getNetworkSelect(result => {
      this.setState({ networkOptions: result.data })
    })
  }

  getGroup() {
    getGroupSelect(this.state.network, result => {
      if (result.data.length > 0) {
        if (this.props.network === this.state.network) {
          this.setState({ groupOptions: result.data, group: this.props.group })
        } else {
          this.setState({ groupOptions: result.data, group: "DEFAULT" })
        }
      } else {
        this.setState({ groupOptions: result.data, group: "DEFAULT" })
      }
    })
  }

  getRegionList() {
    axiosConfig("/region/get-list").then((res) => {
      if (res.data.success === true) {
        let options = []
        res.data.data.map(element => {
          options.push({
            value: element.id,
            text: element.name
          })
          return true
        })
        if (this.props.network === this.state.network) {
          this.setState({ regionListOptions: options, regionList: this.props.regionList })
        } else {
          this.setState({ regionListOptions: options, regionList: "DEFAULT" })
        }
      } else {
        this.setState({ regionListOptions: [], regionList: "DEFAULT" })
      }
    });
  }

  render() {
    if (!this.props.show) {
      return null
    }

    return (
      <Modal title={this.props.isEdit ? "Modifier une région" : "Ajouter une région"} show={true} onClose={this.props.onClose}>
        <FormSelect
          title="Réseau"
          ignore={true}
          className="mb-20"
          name="network"
          value={this.state.network}
          options={this.state.networkOptions}
          init="Veuillez sélectionner une valeur"
          onChange={this.handleInputChange}
          error={
            this.state.isError === true && this.state.network === "DEFAULT" ? "Veuillez remplir ce champ" : ""
          }
        />
        <FormSelect
          disabled={this.state.network === "DEFAULT"}
          title="Groupe" ignore={true}
          className="mb-20"
          name="group"
          value={this.state.group}
          options={this.state.groupOptions}
          init="Veuillez sélectionner une valeur"
          onChange={this.handleInputChange}
          error={
            this.state.isError === true && this.state.group === "DEFAULT" ? "Veuillez remplir ce champ" : ""
          }
        />
        <FormSelect
          title="Région"
          ignore={true}
          className="mb-20"
          name="regionList"
          value={this.state.regionList}
          options={this.state.regionListOptions}
          init="Veuillez sélectionner une valeur"
          onChange={this.handleInputChange}
          error={
            this.state.isError === true && this.state.regionList === "DEFAULT" ? "Veuillez remplir ce champ" : ""
          }
        />
        <FormInput
          className="mb-20"
          type="text"
          name="mailClaim"
          value={this.state.mailClaim}
          title="Mail de réclamation *"
          onChange={this.handleInputChange}
        />
        <Button
          className="mb-4"
          text={this.props.isEdit ? "Modifier" : "Ajouter"}
          onClick={() => { this.props.isEdit ? this.handleEditRegionClick() : this.handleAddRegionClick() }}
        />
      </Modal>
    )
  }
}