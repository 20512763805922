import React from "react";
import { Modal, FormInput, Button, FormCheckbox } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import axiosConfig from "../../utils/axiosConfig";

export default class ModalGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type1: "",
      type2: "",
      value: false,
      negotiationMin: "",
      negotiationMax: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.setState({
        type1: this.props.type1 ? this.props.type1 : "",
        type2: this.props.type2 ? this.props.type2 : "",
        value: this.props.value ? true : false,
        negotiationMin: this.props.negotiationMin
          ? this.props.negotiationMin
          : "",
        negotiationMax: this.props.negotiationMax
          ? this.props.negotiationMax
          : "",
      });
    } else if (prevProps.show !== this.props.show && !this.props.id) {
      this.setState({
        type1: "",
        type2: "",
        value: "",
      });
    }
  }

  handleEditGroupClick = () => {
    let data = this.getData();
    axiosConfig("/evalcar/group/update-group", {
      data: data,
    }).then((res) => {
      if (res.data.success === true) {
        alert("Le groupe a bien été mis à jour");
        this.props.onClose();
      }
    });
  };

  getData() {
    return {
      id: this.props.id,
      type1: this.state.type1,
      type2: this.state.type2,
      value: this.state.value,
      negotiationMin: this.state.negotiationMin,
      negotiationMax: this.state.negotiationMax,
    };
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Modal
        title={"Modifier " + this.props.name}
        show={true}
        onClose={this.props.onClose}
      >
        <Row>
          <Col xs={12} className="mb-20 d-flex">
            <FormInput
              type="text"
              name="type1"
              value={this.state.type1}
              title="Type de document état descriptif"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} className="mb-20 d-flex">
            <FormInput
              type="text"
              name="type2"
              value={this.state.type2}
              title="Type de document synthèse"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} className="mb-20 d-flex">
            <FormCheckbox
              className="mb-20"
              text="+/- value général"
              name="value"
              checked={this.state.value}
              onChange={this.handleCheckboxChange}
            />
          </Col>
          <Col xs={12} md={6} className="mb-20 d-flex">
            <FormInput
              type="number"
              name="negotiationMin"
              value={this.state.negotiationMin}
              title="Négociation marché min."
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={6} className="mb-20 d-flex">
            <FormInput
              type="number"
              name="negotiationMax"
              value={this.state.negotiationMax}
              title="Négociation marché max."
              onChange={this.handleInputChange}
            />
          </Col>
          <Button
            text="Modifier"
            onClick={(e) => this.handleEditGroupClick(e)}
          />
        </Row>
      </Modal>
    );
  }
}
