import React, { createRef } from "react";
import { Nav, Card, FormInput } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import User from "../../components/pixtransfer/User";
import ShowOptionNGRA from "../../components/ShowOptionNGRA";

import AuthContext from "../../context/AuthContext";

export default class PixTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      network: null,
      group: null,
      region: null,
      agency: null,
    };
    this.refUser = createRef();
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.refUser.current.getUser();
    }
  };

  componentDidMount() {}

  render() {
    return (
      <>
        <Nav className="mb-20" title="PixTransfer" />
        <Row>
          <Col sm={12} md={4}>
            <Card className="mb-20">
              <FormInput
                className="mb-20"
                value={this.state.search}
                name="search"
                onChange={this.handleInputChange}
                title="Nom ou adresse mail de l'utilisateur"
                onKeyDown={this.handleSearchKeyDown}
              >
                <FontAwesomeIcon className="icon" icon={faSearch} />
              </FormInput>
            </Card>
          </Col>
          <Col sm={12} md={8}>
            <ShowOptionNGRA
              handleDataChange={(network, group, region, agency) => {
                this.setState(
                  {
                    network,
                    group,
                    region,
                    agency,
                  },
                  () => {
                    this.refUser.current.getUser();
                  }
                );
              }}
            />
          </Col>
        </Row>
        <Card className="mb-20" title="Utilisateurs">
          <User
            network={this.state.network}
            group={this.state.group}
            region={this.state.region}
            agency={this.state.agency}
            search={this.state.search}
            ref={this.refUser}
          />
        </Card>
      </>
    );
  }
}
