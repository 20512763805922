import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {
  Pagination,
  PaginationClass,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../../utils/axiosConfig";
import ModalNetworkMedia from "./ModalMedia";
import "./ModalMedia.css";

export class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 20,
      total: 0,
      networks: [],
      isModalNetworkAdd: false,
      isModalNetworkEdit: false,
      modalNetworkId: null,
      modalNetworkName: null,
      modalNetworkIsPilotageCabinet: null,
      modalNetworkIsPixtransfer: null,
      modalNetworkIsValorEasy: null,
      modalNetworkIsEvalCar: null,
      modalNetworkIsRedaxion: null,
      modalNetworkIsXpertVisio: null,
      search: "",
      order: "",
      isLoad: true,
      openModal: false,
    };
    this.refColumnName = React.createRef();
    this.refColumnAgency = React.createRef();
  }

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnAgency.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "agency", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ networks: result.tabData });
    } else {
      this.setState({ networks: [] });
    }
  }

  getData() {
    return {
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "network/count",
      "network/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  handleClick = (element) => {
    this.setState({ openModal: true, modalItem: element });
  };

  render() {
    return (
      <div>
        <Table
          isCSV
          radiusTopLeft
          empty={
            this.state.networks.length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th hide>#</Th>
              <Th
                className={"w-100"}
                ref={this.refColumnName}
                sort
                onSort={(desc) => this.handleSortClick(desc, 0)}
                radiusTopLeft
              >
                Nom
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.networks.map((element, idx) => (
              <Tr
                key={idx}
                onClick={() => this.handleClick(element)}
                className={"cursor-pointer Dashboard-container-body"}
              >
                <Th
                  style={{ cursor: "pointer" }}
                  radiusTopLeft={idx === 0 ? true : false}
                >
                  {idx + 1}
                </Th>
                <Td style={{ cursor: "pointer" }}>{element.name}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          className="mt-10"
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.tabLength}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={4}
          onChange={this.handlePageChange.bind(this)}
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
          nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
        />
        <ModalNetworkMedia
          show={this.state.openModal}
          item={this.state.modalItem}
          onClose={() => this.setState({ openModal: false, modalItem: null })}
        />
      </div>
    );
  }
}

export default Media;
