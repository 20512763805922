import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEye } from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../../utils/axiosConfig";

import AuthContext from "../../../context/AuthContext";

import ModalCompanyMonitoring from "./ModalCompanyMonitoring";

export default class CompanyMonitoring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyMonitoring: [],
      isModalCompanyMonitoringAdd: false,
      isModalCompanyMonitoringEdit: false,
      modalCompanyMonitoringId: null,
      modalCompanyMonitoringName: null,
      modalCompanyMonitoringType: null,
      typeOptions: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getCompanyMonitoring();
    this.getCompanyMonitoringType();
  }

  getCompanyMonitoringType() {
    axiosConfig(
      "/pilotage-cabinet/company-monitoring/get-company-monitoring-type"
    ).then((res) => {
      if (res.data.success === true) {
        this.setState({ typeOptions: res.data.data });
      } else {
        this.setState({ typeOptions: [] });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isModalCompanyMonitoringEdit !==
        this.state.isModalCompanyMonitoringEdit &&
      this.state.isModalCompanyMonitoringEdit === false
    ) {
      this.getCompanyMonitoring();
    }
  }

  getCompanyMonitoring() {
    axiosConfig(
      "/pilotage-cabinet/company-monitoring/get-company-monitoring"
    ).then((res) => {
      if (res.data.success === true) {
        this.setState({ companyMonitoring: res.data.data });
      } else {
        this.setState({ companyMonitoring: [] });
      }
    });
  }

  handleClickAdd() {
    this.setState({ isModalCompanyMonitoringAdd: true });
  }

  render() {
    return (
      <>
        <Table
          isCSV
          radiusTopLeft
          empty={
            this.state.companyMonitoring.length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th hide>#</Th>
              <Th radiusTopLeft>Nom</Th>
              <Th>Type</Th>
              <Th>Date de création</Th>
              <Th>Date de modification</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.companyMonitoring.map((element, idx) => (
              <Tr key={idx}>
                <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                <Td>{element.name}</Td>
                <Td>{element.type}</Td>
                <Td>{element.date}</Td>
                <Td>{element.dateEdit}</Td>
                <Td className="nowrap text-center">
                  <div className="d-flex">
                    <ButtonIcon
                      small
                      className="blue"
                      onClick={() => {
                        this.setState({
                          isModalCompanyMonitoringEdit: true,
                          modalCompanyMonitoringId: element.id,
                          modalCompanyMonitoringName: element.name,
                          modalCompanyMonitoringType: element.typeInt,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </ButtonIcon>
                    <ButtonIcon
                      small
                      className="green"
                      onClick={() =>
                        window.open(
                          this.context.urlPdf + `/?id=${element.id}`,
                          "_blank"
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </ButtonIcon>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <ModalCompanyMonitoring
          show={
            this.state.isModalCompanyMonitoringEdit ||
            this.state.isModalCompanyMonitoringAdd
          }
          isEdit={this.state.isModalCompanyMonitoringEdit}
          id={this.state.modalCompanyMonitoringId}
          name={this.state.modalCompanyMonitoringName}
          type={this.state.modalCompanyMonitoringType}
          typeOptions={this.state.typeOptions}
          onClose={() =>
            this.setState({
              isModalCompanyMonitoringAdd: false,
              isModalCompanyMonitoringEdit: false,
              modalCompanyMonitoringId: null,
              modmodalCompanyMonitoringNamealAgencyName: null,
              modalCompanyMonitoringType: null,
            })
          }
        />
      </>
    );
  }
}
