import React from "react";
import {
  Modal,
  FormSelect,
  FormInput,
  Button,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";
import { getNetworkSelect } from "../../utils/general";

export default class ModalGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      network: "DEFAULT",
      networkOptions: [],
      isIxPerience: false,
      mailClaim: "",
      typeDocumentContact: "",
      primaryColor: "",
      secondaryColor: "",
      isError: false,
      isPrecisia: false,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  componentDidMount() {
    this.getNetwork();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.setState({
        name: this.props.name,
        network: this.props.network,
        isIxPerience: this.props.isIxPerience,
        isPrecisia: this.props.isPrecisia,
        mailClaim: this.props.mailClaim,
        typeDocumentContact: this.props.typeDocumentContact,
        primaryColor: this.props.primaryColor,
        secondaryColor: this.props.secondaryColor,
      });
    } else if (prevProps.show !== this.props.show && !this.props.id) {
      this.setState({
        name: "",
        network: "DEFAULT",
        isError: false,
        isIxPerience: false,
        mailClaim: "",
        typeDocumentContact: "",
        primaryColor: "",
        secondaryColor: "",
        isPrecisia: false,
      });
    }
  }

  getNetwork() {
    getNetworkSelect((result) => {
      this.setState({ networkOptions: result.data });
    });
  }

  getData() {
    return {
      name: this.state.name,
      network: this.state.network,
      isIxPerience: this.state.isIxPerience,
      mailClaim: this.state.mailClaim,
      typeDocumentContact: this.state.typeDocumentContact,
      color: {
        primary: this.state.primaryColor,
        secondary: this.state.secondaryColor,
      },
      isPrecisia: this.state.isPrecisia,
    };
  }

  handleAddGroupClick = () => {
    if (this.state.name && this.state.network !== "DEFAULT") {
      axiosConfig("/group/insert", {
        data: this.getData(),
      }).then((res) => {
        if (res.data.success === true) {
          alert("Le groupe a bien été ajouté");
          this.props.onClose();
        }
      });
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  handleEditGroupClick = () => {
    if (this.state.name && this.state.network !== "DEFAULT") {
      let data = this.getData();
      data.id = this.props.id;
      axiosConfig("/group/update", {
        data: data,
      }).then((res) => {
        if (res.data.success === true) {
          alert("Le groupe a bien été modifié");
          this.props.onClose();
        }
      });
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Modal
        title={this.props.isEdit ? "Modifier un groupe" : "Ajouter un groupe"}
        show={true}
        onClose={this.props.onClose}
      >
        <FormSelect
          title="Réseau *"
          ignore={true}
          className="mb-20"
          name="network"
          value={this.state.network}
          options={this.state.networkOptions}
          init="Veuillez sélectionner une valeur"
          onChange={this.handleInputChange}
          error={
            this.state.isError === true && this.state.network === "DEFAULT"
              ? "Veuillez remplir ce champ"
              : ""
          }
        />
        <FormInput
          className="mb-20"
          type="text"
          name="name"
          value={this.state.name}
          title="Nom *"
          onChange={this.handleInputChange}
          error={
            this.state.isError === true && !this.state.name
              ? "Veuillez remplir ce champ"
              : ""
          }
        />
        <FormCheckbox
          className="mb-20"
          text="IxPérience"
          name="isIxPerience"
          checked={this.state.isIxPerience}
          onChange={this.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-20"
          text="PrécisIA"
          name="isPrecisia"
          checked={this.state.isPrecisia}
          onChange={this.handleCheckboxChange}
        />
        <FormInput
          className="mb-20"
          type="text"
          name="mailClaim"
          value={this.state.mailClaim}
          title="Mail de réclamation *"
          onChange={this.handleInputChange}
        />
        <FormInput
          className="mb-20"
          type="text"
          name="typeDocumentContact"
          value={this.state.typeDocumentContact}
          title="Type doc. contact *"
          onChange={this.handleInputChange}
        />
        <Row>
          <Col xs={12} md={6}>
            <FormInput
              className="mb-20"
              type="color"
              name="primaryColor"
              value={this.state.primaryColor}
              title="Couleur primaire"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput
              className="mb-20"
              type="color"
              name="secondaryColor"
              value={this.state.secondaryColor}
              title="Couleur secondaire"
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        <Button
          className="mb-4"
          text={this.props.isEdit ? "Modifier" : "Ajouter"}
          onClick={() => {
            this.props.isEdit
              ? this.handleEditGroupClick()
              : this.handleAddGroupClick();
          }}
        />
      </Modal>
    );
  }
}
