import React, { Component } from 'react'
import { ButtonIcon, Pagination, PaginationClass, Table, Tbody, Td, Th, Thead, Tr } from 'ui-kit-ck-consultant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../../utils/axiosConfig"
import './Formation.css'
import ModalFormation from './ModalFormation';


export class Formations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 20,
      total: 0,
      formations: [],
      isModalFormationAdd: false,
      isModalFormationEdit: false,
      modalFormationId: null,
      modalFormationName: null,
      search: "",
      order: '',
      isLoad: true,
      modalIsOpen: false,
    };
    this.refColumnName = React.createRef();
    this.refColumnOrganization = React.createRef();
  }


  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnOrganization.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc }
        break;
      case 1:
        order = { name: "organization", desc: desc }
        break;
      default:
        order = { name: "id", desc: desc }
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    })
  }

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result)
            });
          })
        }
      })
    })
  }

  setData(result) {
    if (result.success === true) {
      this.setState({ formations: result.tabData, isLoad: false })
    } else {
      this.setState({ formations: [], isLoad: false })
    }
  }

  getData() {
    return {
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    }
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(
      activePage,
      () =>
        this.setState({ activePage: activePage }, () => {
          this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
            if (result.success === true) {
              this.setData(result)
            }
          });
        })
    );
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "/pilotage-cabinet/formation/count",
      "/pilotage-cabinet/formation/pagination",
      this.state.tabLength
    )
    this.initSearch()
  }

  handleRemoveFormation = (formation) => {
    let formations = this.state.formations
    formations = formations.filter(element => element.id !== formation.id)
    let removeOrg = true
    formations.map(element => {
      if (element.idOrganization === formation.idOrganization) removeOrg = false
      return null
    })
    removeOrg = formation.idOrganization === 4 ? false : removeOrg

    axiosConfig("/pilotage-cabinet/formation/remove-formation", {
      data: {
        formation,
        removeOrg
      }
    }).then(res => {
      if (res.data.success) {
        this.setState({formations})
      }
    })
  }
  handleAddFormation = () => {
    this.setState({
      modalIsOpen: true
    })
  }

  handleEditFormation = (formation) => {
    this.setState({
      modalIsOpen: true,
      isEditFormation: formation,
    })
  }

  updateList = (data) => {
    let formations = this.state.formations
    let index = formations.findIndex(element => element.id === data.id)
    if (index === -1) {
      formations.unshift({
        id: data.id,
        idOrganization: data.idOrg,
        name: data.name,
        nameOrganization: data.nameOrg,
      })
    } else {
      formations[index] = {
        id: data.id,
        idOrganization: data.idOrg,
        name: data.name,
        nameOrganization: data.nameOrg,
      }
    }

    this.setState({formations})

  }

  render() {
    return (
      <div>
        <Table empty={this.state.formations.length > 0 ? "" : "Aucune donnée n'est disponible"}>
          <Thead>
            <Tr>
              <Th hide>#</Th>
              <Th ref={this.refColumnName} sort onSort={(desc) => this.handleSortClick(desc, 0)} radiusTopLeft>Formation</Th>
              <Th ref={this.refColumnOrganization} sort onSort={(desc) => this.handleSortClick(desc, 1)} >Organisation</Th>
              <Th  radiusTopRight></Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              this.state.formations.map((element, idx) => (
                <Tr key={idx} >
                  <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                  <Td>{element.name}</Td>
                  <Td>{element.nameOrganization}</Td>
                  <Td className="nowrap">
                      <ButtonIcon
                        small
                        className="red"
                        onClick={() => this.handleRemoveFormation(element)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() => this.handleEditFormation(element)}>
                        <FontAwesomeIcon icon={faPen} />
                      </ButtonIcon>
                    </Td>
                </Tr>
              ))
            }
          </Tbody>
        </Table>
        <Pagination
          className="mt-10"
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.tabLength}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={4}
          onChange={this.handlePageChange.bind(this)}
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
          nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
        />
        <ModalFormation 
          title={"Ajouter une formation"} 
          show={this.state.modalIsOpen} 
          defaultValue={this.state.isEditFormation}
          onClose={() => this.setState({modalIsOpen: false})}
          updateList={(data) => this.updateList(data)}
        >
        </ModalFormation>
      </div>
    )
  }
}

export default Formations
