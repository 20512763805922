import React, { createRef } from "react";
import {
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomBody,
  TabsCustomItem,
  TabsCustomMenu,
  Card,
  FormInput,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import Group from "../../components/evalcar/Group";
import User from "../../components/evalcar/User";
import ShowOptionNGRA from "../../components/ShowOptionNGRA";

export default class EvalCar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIdArray: ["Groupes", "Utilisateurs"],
      tabsId: 0,
      search: "",
      network: null,
      group: null,
      region: null,
      agency: null,
    };
    this.refUser = createRef();
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.refUser.current.getUser();
    }
  };

  render() {
    return (
      <>
        <Nav className="mb-20" title="EvalCar" />
        {this.state.tabsId === 1 ? (
          <Row>
            <Col sm={12} md={4}>
              <Card className="mb-20">
                <FormInput
                  className="mb-20"
                  value={this.state.search}
                  name="search"
                  onChange={this.handleInputChange}
                  title="Nom ou adresse mail de l'utilisateur"
                  onKeyDown={this.handleSearchKeyDown}
                >
                  <FontAwesomeIcon className="icon" icon={faSearch} />
                </FormInput>
              </Card>
            </Col>
            <Col sm={12} md={8}>
              <ShowOptionNGRA
                handleDataChange={(network, group, region, agency) => {
                  this.setState(
                    {
                      network,
                      group,
                      region,
                      agency,
                    },
                    () => {
                      if (this.state.tabsId === 1) {
                        this.refUser.current.getUser();
                      }
                    }
                  );
                }}
              />
            </Col>
          </Row>
        ) : null}
        <TabsCustom>
          <TabsCustomHeader className="mt-0">
            {this.state.tabsIdArray.map((element, key) => (
              <TabsCustomMenu
                key={key}
                title={element}
                active={this.state.tabsId === key}
                onClick={(e) => {
                  this.setState({ tabsId: key });
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody>
            <TabsCustomItem
              active={this.state.tabsId === 0}
              className="overflow-x-auto"
            >
              <Group />
            </TabsCustomItem>
            <TabsCustomItem
              active={this.state.tabsId === 1}
              className="overflow-x-auto"
            >
              <User
                network={this.state.network}
                group={this.state.group}
                region={this.state.region}
                agency={this.state.agency}
                search={this.state.search}
                ref={this.refUser}
              />
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
      </>
    );
  }
}
