import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  Modal,
  FormInput,
  Button,
  Loader,
  Pagination,
  PaginationClass,
  Nav,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faPen,
  faSearch,
  faTrash,
  faKey,
  faEnvelope,
  faUsers,
  faSync,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

export default class Garage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      activePage: 1,
      tabLength: 20,
      total: 0,
      garages: [],
      selectedId: {},
      search: "",
      isModalGarage: false,
      modalGarageId: null,
      modalGarageName: null,
      modalGarageCode: null,
      modalGarageAddress1: null,
      modalGarageAddress2: null,
      modalGarageCity: null,
      modalGarageZipCode: null,
      modalGaragePhone: null,
      modalGarageEmail: null,
      modalGarageSiret: null,
      modalGarageLatitude: null,
      modalGarageLongitude: null,
      modalGaragePassword: null,
      modalGarageAutoUpdate: null,
      isModalMerge: false,
      garageAlias: [],
      isModalAlias: false,
      modalAliasEmail: "",
      modalAliasId: null,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxModalChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let selectedId = this.state.selectedId;

    let idx = null;
    for (const key in this.state.garages) {
      if (Object.hasOwnProperty.call(this.state.garages, key)) {
        const element = this.state.garages[key];
        if (parseInt(element.id) === parseInt(name)) {
          idx = key;
        }
      }
    }
    if (idx) {
      selectedId[name] = {
        checked,
        name: this.state.garages[idx].name,
        code: this.state.garages[idx].lacourId,
      };
      this.setState({
        selectedId,
      });
    }
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.initSearch();
    }
  };

  static contextType = AuthContext;

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "garage/count",
      "garage/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        } else {
          this.setData({
            isLoad: false,
          });
        }
      });
    });
  };

  getData() {
    return {
      search: this.state.search,
    };
  }

  setData(result) {
    if (result.success === true) {
      this.setState({ garages: result.tabData, isLoad: false });
    } else {
      this.setState({ garages: [], isLoad: false });
    }
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  updateGarage() {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/garage/update-garage", {
        data: {
          id: this.state.modalGarageId,
          name: this.state.modalGarageName,
          code: this.state.modalGarageCode,
          address1: this.state.modalGarageAddress1,
          address2: this.state.modalGarageAddress2,
          city: this.state.modalGarageCity,
          zipCode: this.state.modalGarageZipCode,
          phone: this.state.modalGaragePhone,
          email: this.state.modalGarageEmail,
          siret: this.state.modalGarageSiret,
          latitude: this.state.modalGarageLatitude,
          longitude: this.state.modalGarageLongitude,
          password: this.state.modalGaragePassword,
          autoUpdate: this.state.modalGarageAutoUpdate,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState(
            {
              isLoad: false,
              isModalGarage: false,
              modalGarageId: null,
              modalGarageName: null,
              modalGarageCode: null,
              modalGarageAddress: null,
              modalGarageCity: null,
              modalGarageZipCode: null,
              modalGaragePhone: null,
              modalGarageEmail: null,
              modalGarageSiret: null,
              modalGarageLatitude: null,
              modalGarageLongitude: null,
              modalGaragePassword: null,
              modalGarageAutoUpdate: null,
            },
            () => this.initSearch()
          );
        } else {
          this.setState(
            {
              isLoad: false,
            },
            () => {
              alert("Une erreur est survenue");
            }
          );
        }
      });
    });
  }

  deleteGarage = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce réparateur ?")) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/garage/delete-garage", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState(
              {
                isLoad: false,
              },
              () => this.initSearch()
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => alert("Une erreur est survenue")
            );
          }
        });
      });
    }
  };

  mergeGarage = (id) => {
    let deleteGarage = [];
    let codeGarage = [];

    Object.keys(this.state.selectedId).map((key) => {
      if (this.state.selectedId[key].checked) {
        if (id !== key) {
          deleteGarage.push(key);
        }
        codeGarage.push(this.state.selectedId[key].code);
      }
      return null;
    });

    this.setState({ isLoad: true }, () => {
      axiosConfig("/garage/merge-garage", {
        data: {
          id,
          deleteGarage,
          codeGarage,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState(
            {
              isLoad: false,
              isModalMerge: false,
              selectedId: {},
            },
            () => this.initSearch()
          );
        } else {
          this.setState(
            {
              isLoad: false,
            },
            () => alert("Une erreur est survenue")
          );
        }
      });
    });
  };

  handleAutoMergeClick = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("/garage/auto-merge-garage").then((res) => {
          if (res.data.success === true) {
            this.setState(
              {
                isLoad: false,
              },
              () => this.initSearch()
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => alert("Une erreur est survenue")
            );
          }
        });
      }
    );
  };

  handleSavePasswordClick(id, idx) {
    axiosConfig("/garage/save-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpGarages = this.state.garages;
        tmpGarages[idx].isPassword = !tmpGarages[idx].isPassword;
        this.setState({ garages: tmpGarages });
      }
    });
  }

  handleResetPasswordClick(id, idx) {
    axiosConfig("/garage/reset-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpGarages = this.state.garages;
        tmpGarages[idx].isPassword = !tmpGarages[idx].isPassword;
        this.setState({ garages: tmpGarages });
      }
    });
  }

  handleResetFolders(id) {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("/garage/reset-folders", {
          data: { id: id },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false });
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      }
    );
  }

  handleResetTokenClick(id) {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("/garage/reset-token", {
          data: { id: id },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false });
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      }
    );
  }

  getGarageAlias = (id) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/garage/get-garage-alias", { data: { id } }).then((res) => {
        if (res.data.success === true) {
          this.setState({
            garageAlias: res.data.data,
            isModalAlias: true,
            modalAliasEmail: "",
            modalAliasId: id,
            isLoad: false,
          });
        } else {
          this.setState({ garageAlias: [], isLoad: false });
        }
      });
    });
  };

  insertGarageAlias = () => {
    if (this.state.modalAliasEmail) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/garage/insert-garage-alias", {
          data: {
            id: this.state.modalAliasId,
            email: this.state.modalAliasEmail,
          },
        }).then((res) => {
          if (res.data.success === true) {
            let garageAlias = this.state.garageAlias;

            garageAlias.push({
              id: res.data.id,
              token: res.data.token,
              email: this.state.modalAliasEmail,
            });

            this.setState({ isLoad: false, garageAlias, modalAliasEmail: "" });
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  deleteGarageAlias = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce compte ?")) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/garage/delete-garage-alias", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false }, () =>
              this.getGarageAlias(this.state.modalAliasId)
            );
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    }
  };

  resetPasswordGarageAlias = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir rénitialiser le mot de passe de ce compte ?"
      )
    ) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/garage/reset-password-garage-alias", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false }, () =>
              alert("Mot de passe rénitialisé")
            );
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    }
  };

  setDefaultPasswordGarageAlias = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir rénitialiser le mot de passe de ce compte ?"
      )
    ) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/garage/set-default-password-garage-alias", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false }, () =>
              alert("Mot de passe rénitialisé")
            );
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    }
  };

  resetFolderGarage = (garageId) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir rénitialiser les dossiers de ce réparateur ?"
      )
    ) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          axiosConfig("/garage/reset-folder-garage", {
            data: {
              id: garageId,
            },
          }).then((res) => {
            if (res.data.success === true) {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  alert("Ok");
                }
              );
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  alert("Une erreur est survenue");
                }
              );
            }
          });
        }
      );
    }
  };

  render() {
    let countSelectedId = 0;

    Object.keys(this.state.selectedId).map((key) => {
      if (this.state.selectedId[key].checked) {
        countSelectedId++;
      }
      return null;
    });

    return (
      <>
        <Nav
          className="mb-20"
          title={`Réparateurs (total : ${this.state.total})`}
        >
          {/* <Button
            className="mr-10"
            text={`Fusion automatique`}
            outline
            onClick={() => {
              if (
                window.confirm(
                  "Les mandants vont être fusionés automatiquement"
                )
              ) {
                this.handleAutoMergeClick();
              }
            }}
          /> */}
          <Button
            text={`Fusioner ${
              countSelectedId > 1 ? `${countSelectedId} réparateurs` : ""
            }`}
            outline
            disabled={countSelectedId < 2}
            onClick={() => this.setState({ isModalMerge: true })}
          />
        </Nav>
        <Row>
          <Col sm={12} md={8}>
            <Card className="mb-20">
              <FormInput
                value={this.state.search}
                name="search"
                onChange={this.handleInputChange}
                placeholder="Nom / Code / Ville / Email / Adresse / Téléphone du réparateur"
                onKeyDown={this.handleSearchKeyDown}
              >
                <FontAwesomeIcon className="icon" icon={faSearch} />
              </FormInput>
            </Card>
          </Col>
        </Row>
        <Card title="Liste des réparateurs" classNameChildren="overflow-x-auto">
          <Table isCSV>
            <Thead>
              <Tr>
                <Th hide>#</Th>
                <Th radiusTopLeft>Nom</Th>
                <Th>IxPérience</Th>
                <Th>Code</Th>
                <Th>Adresse</Th>
                <Th>Ville</Th>
                <Th>Code postal</Th>
                <Th>Téléphone</Th>
                <Th>E-mail</Th>
                <Th>SIRET</Th>
                <Th>Latitude</Th>
                <Th>Longitude</Th>
                <Th>Mot de passe</Th>
                <Th>Auto</Th>
                {this.context.administration === 5 ? <Th>Actions</Th> : <></>}
              </Tr>
            </Thead>
            <Tbody>
              {this.state.garages.map((element, idx) => (
                <Tr key={idx}>
                  <Th
                    radiusTopLeft={idx === 0 ? true : false}
                    className="bg-gray-light"
                  >
                    <div className="m-auto">
                      <FormCheckbox
                        text={
                          (this.state.activePage - 1) * this.state.tabLength +
                          (idx + 1)
                        }
                        name={element.id}
                        checked={
                          this.state.selectedId[element.id]
                            ? this.state.selectedId[element.id].checked
                            : false
                        }
                        onChange={this.handleCheckboxChange}
                      />
                    </div>
                  </Th>
                  <Td>{element.name}</Td>
                  <Td>{element.countFolderIxperience}</Td>
                  <Td>{element.lacourId}</Td>
                  <Td>
                    {element.address1}
                    {element.address2 ? ` - ${element.address2}` : ""}
                  </Td>
                  <Td>{element.city}</Td>
                  <Td>{element.zipCode}</Td>
                  <Td>{element.phone}</Td>
                  <Td>{element.email}</Td>
                  <Td>{element.siret}</Td>
                  <Td>{element.latitude}</Td>
                  <Td>{element.longitude}</Td>
                  <Td>
                    <div className="d-flex">
                      <div
                        className={`m-auto ${
                          element.isSetPassword ? "bg-green" : "bg-red"
                        }`}
                        style={{
                          borderRadius: "8px",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    </div>
                  </Td>
                  <Td>
                    <div className="d-flex">
                      <div
                        className={`m-auto ${
                          element.autoUpdate ? "bg-green" : "bg-red"
                        }`}
                        style={{
                          borderRadius: "8px",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    </div>
                  </Td>
                  {this.context.administration === 5 ? (
                    <Td className="nowrap">
                      <div className="d-flex">
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.handleResetFolders(element.id)}
                          info="Rénitialiser dossiers"
                        >
                          <FontAwesomeIcon icon={faSync} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.handleResetTokenClick(element.id)}
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                        </ButtonIcon>
                        {!element.isPassword ? (
                          <ButtonIcon
                            small
                            className="blue"
                            onClick={() =>
                              this.handleSavePasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        ) : (
                          <ButtonIcon
                            small
                            className="red"
                            onClick={() =>
                              this.handleResetPasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        )}
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() =>
                            this.setState({
                              isModalGarage: true,
                              modalGarageId: element.id,
                              modalGarageName: element.name,
                              modalGarageCode: element.lacourId,
                              modalGarageAddress1: element.address1,
                              modalGarageAddress2: element.address2,
                              modalGarageCity: element.city,
                              modalGarageZipCode: element.zipCode,
                              modalGaragePhone: element.phone,
                              modalGarageEmail: element.email,
                              modalGarageSiret: element.siret,
                              modalGarageLatitude: element.latitude,
                              modalGarageLongitude: element.longitude,
                              modalGaragePassword: element.password,
                              modalGarageAutoUpdate: element.autoUpdate,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() => this.deleteGarage(element.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.getGarageAlias(element.id)}
                        >
                          <FontAwesomeIcon icon={faUsers} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.resetFolderGarage(element.id)}
                        >
                          <FontAwesomeIcon icon={faSync} />
                        </ButtonIcon>
                      </div>
                    </Td>
                  ) : (
                    <></>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            className="mt-10"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.handlePageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        <Modal
          title={`Modifier ${this.state.modalGarageName}`}
          show={this.state.isModalGarage}
          large
          onClose={() =>
            this.setState({
              isModalGarage: false,
              modalGarageId: null,
              modalGarageName: null,
              modalGarageCode: null,
              modalGarageAddress1: null,
              modalGarageAddress2: null,
              modalGarageCity: null,
              modalGarageZipCode: null,
              modalGaragePhone: null,
              modalGarageEmail: null,
              modalGarageSiret: null,
              modalGarageLatitude: null,
              modalGarageLongitude: null,
              modalGaragePassword: null,
              modalGarageAutoUpdate: null,
            })
          }
        >
          <Row>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGarageName"
                value={this.state.modalGarageName}
                title="Nom"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGarageCode"
                value={this.state.modalGarageCode}
                title="Code"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGarageAddress1"
                value={this.state.modalGarageAddress1}
                title="Adresse 1"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGarageAddress2"
                value={this.state.modalGarageAddress2}
                title="Adresse 2"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGarageCity"
                value={this.state.modalGarageCity}
                title="City"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGarageZipCode"
                value={this.state.modalGarageZipCode}
                title="Code postal"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGaragePhone"
                value={this.state.modalGaragePhone}
                title="Phone"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="email"
                name="modalGarageEmail"
                value={this.state.modalGarageEmail}
                title="E-mail"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGarageSiret"
                value={this.state.modalGarageSiret}
                title="SIRET"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGarageLatitude"
                value={this.state.modalGarageLatitude}
                title="Latitude"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGarageLongitude"
                value={this.state.modalGarageLongitude}
                title="Longitude"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                type="text"
                name="modalGaragePassword"
                value={this.state.modalGaragePassword}
                title="Mot de passe"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12}>
              <FormCheckbox
                className="mb-20"
                text="Mise à jour automatique"
                name="modalGarageAutoUpdate"
                checked={this.state.modalGarageAutoUpdate}
                onChange={this.handleCheckboxModalChange}
              />
            </Col>
            <Col xs={12}>
              <Button
                className="mb-20"
                text={"Modifier"}
                onClick={() => this.updateGarage()}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          title={`Fusioner les réparateurs`}
          show={this.state.isModalMerge}
          onClose={() =>
            this.setState({
              isModalMerge: false,
              selectedId: {},
            })
          }
        >
          <p>Veuillez sélectionner le réparateur principal</p>
          <Row>
            {Object.keys(this.state.selectedId).map((key) => {
              if (this.state.selectedId[key].checked) {
                return (
                  <Col key={key} xs={12} md={6} xl={4}>
                    <Button
                      className="mb-10"
                      onClick={() => this.mergeGarage(key)}
                      text={`${this.state.selectedId[key].name} (${this.state.selectedId[key].code})`}
                    />
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </Modal>
        <Modal
          large
          title="Sous-comptes"
          show={this.state.isModalAlias}
          onClose={() => this.setState({ isModalAlias: false })}
        >
          <Table isCSV>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>E-mail</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.garageAlias.map((element, idx) => (
                <Tr key={idx}>
                  <Td className="nowrap">{idx + 1}</Td>
                  <Td className="w-100">{element.email}</Td>
                  <Td className="nowrap">
                    <div className="d-flex">
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() =>
                          this.resetPasswordGarageAlias(element.id)
                        }
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </ButtonIcon>
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() =>
                          this.setDefaultPasswordGarageAlias(element.id)
                        }
                      >
                        <FontAwesomeIcon icon={faKey} />
                      </ButtonIcon>
                      {element.token ? (
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() =>
                            window.open(
                              `https://ixperience.fr/new-account/garage-alias/${element.token}`,
                              "_blank"
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faGlobe} />
                        </ButtonIcon>
                      ) : null}
                      <ButtonIcon
                        small
                        className="red"
                        onClick={() => this.deleteGarageAlias(element.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                    </div>
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td>{this.state.garageAlias.length + 1}</Td>
                <Td>
                  <FormInput
                    placeholder="E-mail"
                    type="text"
                    name="modalAliasEmail"
                    value={this.state.modalAliasEmail}
                    onChange={this.handleInputChange}
                  />
                </Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
          <div className="d-flex">
            <Button
              className="w-auto mt-10 ml-auto"
              text="Ajouter"
              onClick={this.insertGarageAlias}
            />
          </div>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
