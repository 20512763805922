import React from "react";
import {
  TabsCustom,
  TabsCustomHeader,
  TabsCustomBody,
  TabsCustomMenu,
  TabsCustomItem,
  Card,
  FormInput,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  PaginationClass,
  Pagination,
  Loader,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";

import axiosConfig from "../../utils/axiosConfig";
import handleSortClick from "../../utils/handleSortClick";

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsArray: [
        { id: "company", name: "Compagnies" },
        { id: "principal", name: "Mandants" },
        { id: "garage", name: "Réparateurs" },
        { id: "client", name: "Assurés" },
      ],
      tabsIdx: 0,
      activePage: 1,
      tabLength: 20,
      total: 0,
      search: "",
      order: { name: null, desc: null },
      tableHead: [],
      data: [],
      isLoading: false,
    };

    this.sortRef = [React.createRef()];
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "ixperience/notifications/count",
      "ixperience/notifications/pagination",
      this.state.tabLength
    );

    this.setState(
      {
        tableHead: [
          { title: "Compagnie", nameSort: "company", sort: true },
          { title: "Notifications", sort: false },
        ],
      },
      this.initSearch
    );
  }

  handleChangeTab = (idx) => {
    this.setState(
      {
        tabsIdx: idx,
        search: "",
        order: { name: null, desc: null },
      },
      () => {
        let tableHead = [];

        switch (this.state.tabsIdx) {
          case 0:
            tableHead = [
              { title: "Compagnie", nameSort: "company", sort: true },
            ];
            break;
          case 1:
            tableHead = [
              { title: "Mandant", nameSort: "principal", sort: true },
            ];
            break;
          case 2:
            tableHead = [
              { title: "Réparateur", nameSort: "garage", sort: true },
            ];
            break;
          case 3:
            tableHead = [{ title: "Assuré", nameSort: "client", sort: true }];
            break;
          default:
            break;
        }

        tableHead = [...tableHead, { title: "Notifications", sort: false }];

        this.setState({ tableHead: tableHead }, this.initSearch);
      }
    );
  };

  initSearch = () => {
    this.setState({ isLoading: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        } else {
          this.setState({ data: [], isLoading: false });
        }
      });
    });
  };

  getData = () => {
    return {
      source: this.state.tabsArray[this.state.tabsIdx].id,
      name: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  };

  setData = (result) => {
    if (result.success) {
      this.setState({ data: result.tabData, isLoading: false });
    } else {
      this.setState({ data: [], isLoading: false });
    }
  };

  handleSortClick = (desc, column) => {
    this.setState(
      { order: handleSortClick(this.sortRef, desc, column) },
      this.initSearch
    );
  };

  handlePageChange = (activePage) => {
    this.setState({ isLoading: true });
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success) {
            this.setData(result);
          }
        });
      })
    );
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleValidateChange = (event) => {
    if (event.type === "keydown" && event.keyCode === 13) {
      this.initSearch();
    }
  };

  updateNotifications = (element) => {
    axiosConfig({
      url: "ixperience/notifications/set-notifications-value",
      data: {
        source: this.state.tabsArray[this.state.tabsIdx].id,
        id: element.id,
        notifications: !element.notifications,
      },
    })
      .then((res) => {
        if (res.data.success) {
          this.initSearch();
        } else {
          alert(
            "Une erreur est survenue lors de la sauvegarde des modifications."
          );
        }
      })
      .catch((err) => {
        alert(
          "Une erreur est survenue lors de la sauvegarde des modifications."
        );
      });
  };

  render() {
    return (
      <TabsCustom>
        <TabsCustomHeader className="mt-0">
          {this.state.tabsArray.map((element, idx) => (
            <TabsCustomMenu
              key={idx}
              title={element.name}
              active={this.state.tabsIdx === idx}
              onClick={() => this.handleChangeTab(idx)}
            />
          ))}
        </TabsCustomHeader>
        <TabsCustomBody noCard>
          <TabsCustomItem active={true} className="overflow-x-auto">
            <Card className="mb-20" classNameChildren="overflow-x-auto">
              <div className="ml-auto" style={{ maxWidth: "250px" }}>
                <FormInput
                  className="mb-20"
                  style={{ width: "300px" }}
                  type="text"
                  name="search"
                  value={this.state.search}
                  placeholder="Rechercher par nom"
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleValidateChange}
                >
                  <FontAwesomeIcon className="icon" icon={faSearch} />
                </FormInput>
              </div>

              <Table
                isCSV
                empty={!this.state.data.length ? "Aucune donnée" : ""}
              >
                <Thead>
                  <Tr>
                    {this.state.tableHead.map((elem, idx) => (
                      <Th
                        key={`header-${idx}`}
                        ref={this.sortRef[idx]}
                        sort={elem.sort ? true : false}
                        nameSort={elem.nameSort}
                        onSort={
                          elem.sort
                            ? (desc) => this.handleSortClick(desc, idx)
                            : null
                        }
                      >
                        {elem.title}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {this.state.data.map((element) => (
                    <Tr key={`row-${element.id}`}>
                      <Td className="nowrap">{element.name}</Td>
                      <Td className="nowrap">
                        <ButtonIcon
                          small
                          className={!!element.notifications ? "green" : "gray"}
                          onClick={() => this.updateNotifications(element)}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </ButtonIcon>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                className="mt-10"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.tabLength}
                totalItemsCount={this.state.total}
                pageRangeDisplayed={4}
                onChange={this.handlePageChange.bind(this)}
                firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
                nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
              />
            </Card>

            <Loader show={this.state.isLoading} />
          </TabsCustomItem>
        </TabsCustomBody>
      </TabsCustom>
    );
  }
}
