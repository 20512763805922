import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  FormInput,
  Loader,
  Pagination,
  PaginationClass,
  Nav,
  Modal,
  Button,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faTrash,
  faKey,
  faEnvelope,
  faUnlock,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

export default class Client extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      activePage: 1,
      tabLength: 20,
      total: 0,
      clients: [],
      selectedId: {},
      search: "",
      isModalMerge: false,
      isModalClient: false,
      modalClientId: null,
      modalClientEmail: null,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.initSearch();
    }
  };

  static contextType = AuthContext;

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "client/count",
      "client/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  getData() {
    return {
      search: this.state.search,
    };
  }

  setData(result) {
    if (result.success === true) {
      this.setState({ clients: result.tabData, isLoad: false });
    } else {
      this.setState({ clients: [], isLoad: false });
    }
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  deleteClient = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet assuré ?")) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/client/delete-client", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState(
              {
                isLoad: false,
              },
              () => this.initSearch()
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => alert("Une erreur est survenue")
            );
          }
        });
      });
    }
  };

  handleSavePasswordClick(id, idx) {
    axiosConfig("/client/save-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpClients = this.state.clients;
        tmpClients[idx].isPassword = !tmpClients[idx].isPassword;
        this.setState({ clients: tmpClients });
      }
    });
  }

  handleResetPasswordClick(id, idx) {
    axiosConfig("/client/reset-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpClients = this.state.clients;
        tmpClients[idx].isPassword = !tmpClients[idx].isPassword;
        this.setState({ clients: tmpClients });
      }
    });
  }

  handleResetTokenClick(id) {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("/client/reset-token", {
          data: { id: id },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false });
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      }
    );
  }

  handleSetDefaultPassword(id) {
    if (window.confirm("Êtes-vous sûr de vouloir modifier le mot de passe ?")) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          axiosConfig("/client/set-default-password", {
            data: { id: id },
          }).then((res) => {
            if (res.data.success === true) {
              this.setState({ isLoad: false }, () => {
                alert("Nouveau mot de passe : azerty");
              });
            } else {
              this.setState({ isLoad: false }, () => {
                alert("Une erreur est survenue");
              });
            }
          });
        }
      );
    }
  }

  updateClient() {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/client/update-client", {
        data: {
          id: this.state.modalClientId,
          email: this.state.modalClientEmail,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState(
            {
              isLoad: false,
              isModalClient: false,
              modalClientEmail: null,
            },
            () => this.initSearch()
          );
        } else {
          this.setState(
            {
              isLoad: false,
            },
            () => {
              alert("Une erreur est survenue");
            }
          );
        }
      });
    });
  }

  render() {
    return (
      <>
        <Nav
          className="mb-20"
          title={`Assurés (total : ${this.state.total})`}
        />
        <Row>
          <Col sm={12} md={6}>
            <Card className="mb-20">
              <FormInput
                value={this.state.search}
                name="search"
                onChange={this.handleInputChange}
                placeholder="Nom / Ville / Email"
                onKeyDown={this.handleSearchKeyDown}
              >
                <FontAwesomeIcon className="icon" icon={faSearch} />
              </FormInput>
            </Card>
          </Col>
        </Row>
        <Card title="Liste des assurés" classNameChildren="overflow-x-auto">
          <Table isCSV>
            <Thead>
              <Tr>
                <Th hide>#</Th>
                <Th radiusTopLeft>Nom</Th>
                <Th>IxPérience</Th>
                <Th>Adresse</Th>
                <Th>Code postal</Th>
                <Th>Ville</Th>
                <Th>Téléphone</Th>
                <Th>Portable</Th>
                <Th>E-mail</Th>
                {this.context.administration === 5 ? <Th>Actions</Th> : <></>}
              </Tr>
            </Thead>
            <Tbody>
              {this.state.clients.map((element, idx) => (
                <Tr key={idx}>
                  <Th
                    radiusTopLeft={idx === 0 ? true : false}
                    className="bg-gray-light"
                  >
                    {(this.state.activePage - 1) * this.state.tabLength +
                      (idx + 1)}
                  </Th>
                  <Td>{element.name}</Td>
                  <Td>{element.countFolderIxperience}</Td>
                  <Td>{element.address}</Td>
                  <Td>{element.postalCode}</Td>
                  <Td>{element.city}</Td>
                  <Td>{element.phone}</Td>
                  <Td>{element.cellPhone}</Td>
                  <Td>{element.email}</Td>
                  {this.context.administration === 5 ? (
                    <Td className="nowrap">
                      <div className="d-flex">
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.handleResetTokenClick(element.id)}
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                        </ButtonIcon>
                        {!element.isPassword ? (
                          <ButtonIcon
                            small
                            className="blue"
                            onClick={() =>
                              this.handleSavePasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        ) : (
                          <ButtonIcon
                            small
                            className="red"
                            onClick={() =>
                              this.handleResetPasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        )}
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() =>
                            this.handleSetDefaultPassword(element.id)
                          }
                        >
                          <FontAwesomeIcon icon={faUnlock} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() =>
                            this.setState({
                              isModalClient: true,
                              modalClientId: element.id,
                              modalClientEmail: element.email,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() => this.deleteClient(element.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      </div>
                    </Td>
                  ) : (
                    <></>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            className="mt-10"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.handlePageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        <Loader show={this.state.isLoad} />
        <Modal
          title={`Modifier ${this.state.modalClientEmail}`}
          show={this.state.isModalClient}
          onClose={() =>
            this.setState({
              isModalClient: false,
              modalClientId: null,
              modalClientEmail: null,
            })
          }
        >
          <FormInput
            className="mb-20"
            type="text"
            name="modalClientEmail"
            value={this.state.modalClientEmail}
            title="E-mail"
            onChange={this.handleInputChange}
          />
          <Button
            className="mb-20"
            text={"Modifier"}
            onClick={() => this.updateClient()}
          />
        </Modal>
      </>
    );
  }
}
