import React, { Component } from 'react'

export class Preview extends Component {
  render() {
    return (
      <div className={'container preview picture'}>
        <img src={this.props.src} alt={'preview'} />
      </div>
    )
  }
}

export default Preview
