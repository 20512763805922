import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  PaginationClass,
  Pagination,
  Button,
  ButtonIcon,
  Modal,
  FormInput,
  FormSelect,
  Loader,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEdit,
  faTrashAlt,
  faChevronLeft,
  faChevronRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";

import axiosConfig from "../../utils/axiosConfig";
import handleSortClick from "../../utils/handleSortClick";

import AuthContext from "../../context/AuthContext";

export default class DocTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 20,
      total: 0,
      data: [],
      showModal: false,
      editedType: null,
      network: "DEFAULT",
      networkOptions: [
        { value: "DEFAULT", text: "Choisir une option", disabled: true },
      ],
      name: "",
      type: "",
      isError: false,
      isLoading: false,
    };

    this.sortRef = [React.createRef(), React.createRef(), React.createRef()];
  }

  static contextType = AuthContext;

  componentDidMount() {
    if (!this.context.network) {
      axiosConfig("ixperience/doc-types/get-networks")
        .then((res) => {
          if (res.data.success) {
            this.setState({
              networkOptions: [
                {
                  value: "DEFAULT",
                  text: "Choisir une option",
                  disabled: true,
                },
                ...res.data.data,
              ],
            });
          } else {
            this.setState({
              networkOptions: [
                {
                  value: "DEFAULT",
                  text: "Choisir une option",
                  disabled: true,
                },
              ],
            });
          }
        })
        .catch((err) => {
          this.setState({
            networkOptions: [
              { value: "DEFAULT", text: "Choisir une option", disabled: true },
            ],
          });
        });
    }

    this.PaginationClass = new PaginationClass(
      "ixperience/doc-types/count",
      "ixperience/doc-types/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.initSearch();
    }
  }

  initSearch = () => {
    this.setState({ isLoading: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        } else {
          this.setState({ data: [], isLoading: false });
        }
      });
    });
  };

  handlePageChange = (activePage) => {
    this.setState({ isLoading: true });
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success) {
            this.setData(result);
          }
        });
      })
    );
  };

  setData = (result) => {
    if (result.success) {
      this.setState({ data: result.tabData, isLoading: false });
    } else {
      this.setState({ data: [], isLoading: false });
    }
  };

  getData = () => {
    return {
      network: this.context.network ? this.context.network : null,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  };

  handleSortClick = (desc, column) => {
    this.setState(
      { order: handleSortClick(this.sortRef, desc, column) },
      this.initSearch
    );
  };

  handleSaveType = () => {
    const network = this.context.network
      ? this.context.network
      : Number(this.state.network);

    const existingTypes = this.state.editedType
      ? this.state.data
          .filter(
            (el) =>
              el.id !== this.state.editedType.id && el.networkId === network
          )
          .map((el) => el.name)
      : this.state.data
          .filter((el) => el.networkId === network)
          .map((el) => el.name);

    if (
      existingTypes.includes(this.state.name) ||
      !this.state.name ||
      !this.state.type ||
      (!this.context.network && this.state.network === "DEFAULT")
    ) {
      this.setState({ isError: true });
    } else {
      this.setState({ isLoading: true });
      axiosConfig({
        url: `ixperience/doc-types/${this.state.editedType ? "update" : "add"}`,
        data: {
          network: this.context.network
            ? this.context.network
            : this.state.network,
          id: this.state.editedType ? this.state.editedType.id : null,
          name: this.state.name,
          type: this.state.type,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState(
              {
                showModal: false,
                editedType: null,
                network: "DEFAULT",
                name: "",
                type: "",
                isLoading: false,
              },
              () => {
                alert("Enregistré avec succès !");
                this.initSearch();
              }
            );
          } else {
            this.setState({ isLoading: false }, () => {
              alert("Erreur lors de la sauvegarde. Veuillez réessayer.");
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false }, () => {
            alert("Erreur lors de la sauvegarde. Veuillez réessayer.");
          });
        });
    }
  };

  handleDeleteType = (type) => {
    const message = `Supprimer le type de document "${type.name}" ?`;
    if (window.confirm(message)) {
      axiosConfig({
        url: "ixperience/doc-types/delete",
        data: {
          id: type.id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert("Supprimé avec succès.");
            this.initSearch();
          } else {
            alert("Erreur lors de la suppression.");
          }
        })
        .catch((err) => {
          alert("Erreur lors de la suppression.");
        });
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, isError: false });
  };

  render() {
    const tableHead = [
      { title: "Réseau", nameSort: "network", sort: true },
      { title: "Nom", nameSort: "name", sort: true },
      { title: "Type", nameSort: "type", sort: true },
      { title: "Action", sort: false },
    ];

    return (
      <Card className="mb-20" classNameChildren="overflow-x-auto">
        <div className="ml-auto" style={{ maxWidth: "250px" }}>
          <Button
            className="mb-20"
            style={{ width: "300px" }}
            text="Ajouter un type de document"
            onClick={() => this.setState({ showModal: true })}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>

        <Table
          isCSV
          empty={!this.state.data.length ? "Aucun type de document" : ""}
        >
          <Thead>
            <Tr>
              {tableHead.map((elem, idx) => (
                <Th
                  key={`header-${idx}`}
                  ref={this.sortRef[idx]}
                  sort={elem.sort ? true : false}
                  nameSort={elem.nameSort}
                  onSort={
                    elem.sort ? (desc) => this.handleSortClick(desc, idx) : null
                  }
                >
                  {elem.title}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {this.state.data.map((element, idx) => (
              <Tr key={`row-${idx}`}>
                <Td className="nowrap">{element.networkName}</Td>
                <Td className="nowrap">{element.name}</Td>
                <Td className="nowrap">{element.type}</Td>
                <Td className="nowrap">
                  <ButtonIcon
                    small
                    className="blue"
                    onClick={() =>
                      this.setState({
                        showModal: true,
                        editedType: element,
                        network: element.networkId,
                        name: element.name,
                        type: element.type,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </ButtonIcon>
                  <ButtonIcon
                    small
                    className="red"
                    disabled={["Réclamation", "Contact"].includes(element.name)}
                    onClick={() => this.handleDeleteType(element)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Pagination
          className="mt-10"
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.tabLength}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={4}
          onChange={this.handlePageChange.bind(this)}
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
          nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
        />

        <Modal
          className="m-auto"
          show={this.state.showModal}
          onClose={() =>
            this.setState({
              showModal: false,
              editedType: null,
              network: "DEFAULT",
              name: "",
              type: "",
            })
          }
          title={
            this.state.editedType
              ? "Edition du type de document"
              : "Nouveau type de document"
          }
        >
          {!this.context.network ? (
            <FormSelect
              className="my-20"
              title="Réseau"
              name="network"
              value={this.state.network}
              ignore={true}
              options={this.state.networkOptions}
              onChange={this.handleInputChange}
              disabled={this.state.editedType}
              error={
                this.state.isError && this.state.network === "DEFAULT"
                  ? "Veuillez choisir un réseau"
                  : ""
              }
            />
          ) : null}
          <FormInput
            className="mb-20"
            type="text"
            title="Nom"
            name="name"
            value={this.state.name}
            disabled={
              this.state.editedType &&
              ["Réclamation", "Contact"].includes(this.state.name)
            }
            onChange={this.handleInputChange}
            error={
              this.state.isError && !this.state.name
                ? "Veuillez compléter ce champ"
                : this.state.isError && this.state.name && this.state.type
                ? "Ce nom est déjà utilisé"
                : ""
            }
          />
          <FormInput
            className="mb-20"
            type="text"
            title="Type"
            name="type"
            value={this.state.type}
            onChange={this.handleInputChange}
            error={
              this.state.isError && !this.state.type
                ? "Veuillez compléter ce champ"
                : ""
            }
          />
          <Button
            className="mt-20"
            text="Enregistrer"
            onClick={this.handleSaveType}
          />
        </Modal>

        <Loader show={this.state.isLoading} />
      </Card>
    );
  }
}
