import React from "react";
import {
  Nav,
  Button,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  FormInput,
  Pagination,
  PaginationClass,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTimes,
  faSearch,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faDownload,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../../utils/axiosConfig";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import { ExportCSV } from "react-export-csv";

import pilotagecabinetLogo from "../../images/pilotagecabinet.svg";
import pixtransferLogo from "../../images/pixtransfer.svg";
import valoreasyLogo from "../../images/valoreasy.svg";
import evalcarLogo from "../../images/evalcar.svg";
import redaxionLogo from "../../images/redaxion.svg";
import xpertvisioLogo from "../../images/xpertvisio.svg";
import ixperienceLogo from "../../images/ixperience.svg";
import glaSoftwareLogo from "../../images/glasoftware.png"


import ModalUser from "../../components/user/ModalUser";
import ShowOptionNGRA from "../../components/ShowOptionNGRA";

import AuthContext from "../../context/AuthContext";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 20,
      total: 0,
      users: [],
      isModalUserAdd: false,
      isModalUserEdit: false,
      modalUserId: null,
      modalUserEmail: null,
      modalUserName: null,
      modalUserCodeUnisite: null,
      modalUserPasswordUnisite: null,
      modalRegionNetwork: null,
      modalRegionGroup: null,
      modalRegionRegion: null,
      modalRegionAgency: null,
      modalRegionIsAdministration: false,
      modalRegionIsPilotageCabinet: false,
      modalRegionIsPixtransfer: false,
      modalRegionIsValorEasy: false,
      modalRegionIsEvalCar: false,
      modalRegionIsRedaxion: false,
      modalRegionIsXpertVisio: false,
      modalRegionIsGlaSoftware: false,
      network: null,
      group: null,
      region: null,
      agency: null,
      search: "",
      order: "",
      isDownloadCSV: false,
      headerCSV: [
        {
          name: "Nom",
          key: "name",
        },
        {
          name: "Adresse mail",
          key: "email",
        },
        {
          name: "Réseau",
          key: "networkName",
        },
        {
          name: "Groupe",
          key: "groupName",
        },
        {
          name: "Région",
          key: "regionName",
        },
        {
          name: "Agence",
          key: "agencyName",
        },
        {
          name: "Administration",
          key: "isAdministration",
        },
      ],
    };
    this.refColumnName = React.createRef();
    this.refColumnEmail = React.createRef();
    this.refColumnNetwork = React.createRef();
    this.refColumnGroup = React.createRef();
    this.refColumnRegion = React.createRef();
    this.refColumnAgency = React.createRef();
    this.refColumnAdministration = React.createRef();
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.initSearch();
    }
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnEmail.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnNetwork.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnGroup.current.setState({ desc: false, sort: false });
    }
    if (column !== 4) {
      this.refColumnRegion.current.setState({ desc: false, sort: false });
    }
    if (column !== 5) {
      this.refColumnAgency.current.setState({ desc: false, sort: false });
    }
    if (column !== 6) {
      this.refColumnAdministration.current.setState({
        desc: false,
        sort: false,
      });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "email", desc: desc };
        break;
      case 2:
        order = { name: "network", desc: desc };
        break;
      case 3:
        order = { name: "group", desc: desc };
        break;
      case 4:
        order = { name: "region", desc: desc };
        break;
      case 5:
        order = { name: "agency", desc: desc };
        break;
      case 6:
        order = { name: "administration", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ users: result.tabData });
    } else {
      this.setState({ users: [] });
    }
  }

  getData() {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange(activePage) {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "user/count",
      "user/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.isModalUserAdd !== this.state.isModalUserAdd &&
        this.state.isModalUserAdd === false) ||
      (prevState.isModalUserEdit !== this.state.isModalUserEdit &&
        this.state.isModalUserEdit === false)
    ) {
      this.initSearch();
    }
  }

  handleSavePasswordClick(id, idx) {
    axiosConfig("/user/save-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpUsers = this.state.users;
        tmpUsers[idx].isPassword = !tmpUsers[idx].isPassword;
        this.setState({ users: tmpUsers });
      }
    });
  }

  handleResetPasswordClick(id, idx) {
    axiosConfig("/user/reset-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpUsers = this.state.users;
        tmpUsers[idx].isPassword = !tmpUsers[idx].isPassword;
        this.setState({ users: tmpUsers });
      }
    });
  }

  handleDeleteUserClick(id) {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")
    ) {
      axiosConfig("/user/delete", {
        data: { id: id },
      }).then((res) => {
        if (res.data.success === true) {
          alert("L'utilisateur a bien été supprimé");
          this.initSearch();
        }
      });
    }
  }

  render() {
    return (
      <>
        <Nav className="mb-20" title="Utilisateurs">
          <Button
            text="Ajouter un utilisateur"
            onClick={() => {
              this.setState({
                isModalUserAdd: true,
              });
            }}
          />
        </Nav>
        <Row>
          <Col sm={12} md={4}>
            <Card className="mb-20">
              <FormInput
                className="mb-20"
                value={this.state.search}
                name="search"
                onChange={this.handleInputChange}
                title="Nom ou adresse mail de l'utilisateur"
                onKeyDown={this.handleSearchKeyDown}
              >
                <FontAwesomeIcon className="icon" icon={faSearch} />
              </FormInput>
            </Card>
          </Col>
          <Col sm={12} md={8}>
            <ShowOptionNGRA
              handleDataChange={(network, group, region, agency) => {
                this.setState(
                  {
                    network,
                    group,
                    region,
                    agency,
                  },
                  () => {
                    this.initSearch();
                  }
                );
              }}
            />
          </Col>
        </Row>
        <Card
          title="Liste des utilisateurs"
          classNameChildren="overflow-x-auto"
          action={
            <ButtonIcon
              className="blue p-0"
              onClick={() => this.setState({ isDownloadCSV: true })}
            >
              <FontAwesomeIcon icon={faDownload} />
            </ButtonIcon>
          }
        >
          <Table
            isCSV
            empty={
              this.state.users.length > 0
                ? ""
                : "Aucune donnée n'est disponible"
            }
            radiusTopLeft
          >
            <Thead>
              <Tr>
                <Th hide>#</Th>
                <Th
                  ref={this.refColumnName}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                  radiusTopLeft
                >
                  Nom
                </Th>
                <Th
                  ref={this.refColumnEmail}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                >
                  Adresse mail
                </Th>
                <Th
                  ref={this.refColumnNetwork}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 2)}
                >
                  Réseau
                </Th>
                <Th
                  ref={this.refColumnGroup}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 3)}
                >
                  Groupe
                </Th>
                <Th
                  ref={this.refColumnRegion}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 4)}
                >
                  Région
                </Th>
                <Th
                  ref={this.refColumnAgency}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 5)}
                >
                  Agence
                </Th>
                <Th
                  ref={this.refColumnAdministration}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 6)}
                >
                  Administration
                </Th>
                <Th>Produit(s)</Th>
                {this.context.email !== "d.lorgnet@ref-conseil.fr" ? (
                  <Th>Actions</Th>
                ) : null}
              </Tr>
            </Thead>
            <Tbody>
              {this.state.users.map((element, idx) => (
                <Tr key={idx}>
                  <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                  <Td>{element.name}</Td>
                  <Td>{element.email}</Td>
                  <Td>{element.networkName ? element.networkName : "N.R"}</Td>
                  <Td>{element.groupName ? element.groupName : "N.R"}</Td>
                  <Td>{element.regionName ? element.regionName : "N.R"}</Td>
                  <Td>{element.agencyName ? element.agencyName : "N.R"}</Td>
                  <Td>{element.isAdministration ? "Oui" : "Non"}</Td>
                  <Td className="nowrap">
                    {element.isPilotageCabinet ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={pilotagecabinetLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isPixTransfer ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={pixtransferLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isValorEasy ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={valoreasyLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isEvalCar ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={evalcarLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isRedaxion ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={redaxionLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isXpertVisio ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={xpertvisioLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isGlaSoftware ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={glaSoftwareLogo}
                      />
                    ) : (
                      ""
                    )}
                    {element.isIxPerience ? (
                      <img
                        alt="icon"
                        className="mx-5"
                        height={30}
                        src={ixperienceLogo}
                      />
                    ) : (
                      ""
                    )}
                  </Td>
                  {this.context.email !== "d.lorgnet@ref-conseil.fr" ? (
                    <Td className="nowrap">
                      <div className="d-flex">
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => {
                            this.setState({
                              isModalUserEdit: true,
                              modalUserId: element.id,
                              modalUserEmail: element.email,
                              modalUserName: element.name,
                              modalUserCodeUnisite: element.codeUnisite,
                              modalUserPasswordUnisite: element.passwordUnisite,
                              modalRegionNetwork: element.network,
                              modalRegionGroup: element.group,
                              modalRegionRegion: element.region,
                              modalRegionAgency: element.agency,
                              modalRegionIsAdministration:
                                element.isAdministration,
                              modalRegionIsPilotageCabinet:
                                element.isPilotageCabinet,
                              modalRegionIsPixtransfer: element.isPixTransfer,
                              modalRegionIsValorEasy: element.isValorEasy,
                              modalRegionIsEvalCar: element.isEvalCar,
                              modalRegionIsRedaxion: element.isRedaxion,
                              modalRegionIsXpertVisio: element.isXpertVisio,
                              modalRegionIsGlaSoftware: element.isGlaSoftware,
                              modalRegionIsIxPerience: element.isIxPerience,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        {!element.isPassword ? (
                          <ButtonIcon
                            small
                            className="blue"
                            onClick={() =>
                              this.handleSavePasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        ) : (
                          <ButtonIcon
                            small
                            className="red"
                            onClick={() =>
                              this.handleResetPasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        )}
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() => this.handleDeleteUserClick(element.id)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ButtonIcon>
                      </div>
                    </Td>
                  ) : null}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            className="mt-10"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.handlePageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        <ModalUser
          show={this.state.isModalUserAdd || this.state.isModalUserEdit}
          isEdit={this.state.isModalUserEdit}
          id={this.state.modalUserId}
          email={this.state.modalUserEmail}
          name={this.state.modalUserName}
          codeUnisite={this.state.modalUserCodeUnisite}
          passwordUnisite={this.state.modalUserPasswordUnisite}
          network={this.state.modalRegionNetwork}
          group={this.state.modalRegionGroup}
          region={this.state.modalRegionRegion}
          agency={this.state.modalRegionAgency}
          isAdministration={this.state.modalRegionIsAdministration}
          isPilotageCabinet={this.state.modalRegionIsPilotageCabinet}
          isPixTransfer={this.state.modalRegionIsPixtransfer}
          isValorEasy={this.state.modalRegionIsValorEasy}
          isEvalCar={this.state.modalRegionIsEvalCar}
          isRedaxion={this.state.modalRegionIsRedaxion}
          isXpertVisio={this.state.modalRegionIsXpertVisio}
          isGlaSoftware={this.state.modalRegionIsGlaSoftware}
          isIxPerience={this.state.modalRegionIsIxPerience}
          onClose={() =>
            this.setState({
              isModalUserAdd: false,
              isModalUserEdit: false,
              modalUserId: null,
              modalUserEmail: null,
              modalUserName: null,
              modalUserCodeUnisite: null,
              modalUserPasswordUnisite: null,
              modalRegionNetwork: null,
              modalRegionGroup: null,
              modalRegionRegion: null,
              modalRegionAgency: null,
              modalRegionIsAdministration: null,
              modalRegionIsPilotageCabinet: null,
              modalRegionIsPixtransfer: null,
              modalRegionIsValorEasy: null,
              modalRegionIsEvalCar: null,
              modalRegionIsRedaxion: null,
              modalRegionIsXpertVisio: null,
              modalRegionIsGlaSoftware: null,
            })
          }
        />
        {this.state.isDownloadCSV ? (
          <ExportCSV
            header={this.state.headerCSV}
            data={this.state.users}
            separator={";"}
            callback={() => this.setState({ isDownloadCSV: false })}
            isDownload={true}
            filename={`Utilisateurs P${this.state.activePage} ${moment().format(
              "DD-MM-YYYY HH-mm-ss"
            )}.csv`}
          />
        ) : null}
      </>
    );
  }
}
