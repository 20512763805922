import React from "react";
import {
  FullModal,
  FormInput,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import axiosConfig from "../../../utils/axiosConfig";

export default class ModalGoalUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ageR: "",
      ageNR: "",
      discount: "",
      averageHourly: "",
      costR: "",
      costNR: "",
      depositTimeR: "",
      EADMissionDeadline: "",
      depositTimeNR: "",
      SD29Deadline: "",
      SD29VisitDeadline: "",
      visitMissionDeadline: "",
      honorary: "",
      anomalyNumber: "",
      auditResult: "",
      precision: "",
      satisfaction: "",
    };
    this.baseState = this.state
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      axiosConfig("/pilotage-cabinet/user/get-user-goal", {
        data: {
          id: this.props.id,
        }
      }).then((res) => {
        if (res.data.success === true && res.data.data.goal !== null) {
          const goal = res.data.data.goal
          this.setState({
            ageR: goal.age_r,
            ageNR: goal.age_nr,
            discount: goal.remise,
            averageHourly: goal.horaire,
            costR: goal.cout_reparable,
            costNR: goal.cout_vrade,
            depositTimeR: goal.delai_depot_r,
            EADMissionDeadline: goal.delai_mission_ead ? goal.delai_mission_ead : 2,
            depositTimeNR: goal.delai_depot_nr,
            SD29Deadline: goal.delai_sd29,
            SD29VisitDeadline: goal.delai_visite_sd29,
            visitMissionDeadline: goal.delai_mission_visite,
            honorary: goal.honoraire,
            anomalyNumber: goal.anomalie,
            auditResult: goal.audit,
            precision: goal.precision,
            satisfaction: goal.satisfaction,
          }, () => {
            this.defaultState = this.state
          })
        } else {
          this.setState(this.baseState, () => {
            this.defaultState = this.state
          })
        }
      });
    } else if (
      prevProps.show !== this.props.show &&
      !this.props.id
    ) {
      this.setState(this.baseState, () => {
        this.defaultState = this.state
      })
    }
  }

  handleValidClick = () => {
    axiosConfig("/pilotage-cabinet/user/update-user-goal", {
      data: this.getData()
    }).then((res) => {
      if (res.data.success === true) {
        alert("L'objectif de l'utilisateur bien été modifié")
        this.props.onClose()
      }
    })
  }

  handleCloseClick = () => {
    if (JSON.stringify(this.defaultState) !== JSON.stringify(this.state) && window.confirm("Êtes-vous sûr de vouloir quitter sans sauvegarder ?")) {
      this.props.onClose()
    } else if (JSON.stringify(this.defaultState) === JSON.stringify(this.state)) {
      this.props.onClose()
    }
  }

  getData() {
    return {
      id: this.props.id,
      ageR: this.state.ageR,
      ageNR: this.state.ageNR,
      discount: this.state.discount,
      averageHourly: this.state.averageHourly,
      costR: this.state.costR,
      costNR: this.state.costNR,
      depositTimeR: this.state.depositTimeR,
      EADMissionDeadline: this.state.EADMissionDeadline,
      depositTimeNR: this.state.depositTimeNR,
      SD29Deadline: this.state.SD29Deadline,
      SD29VisitDeadline: this.state.SD29VisitDeadline,
      visitMissionDeadline: this.state.visitMissionDeadline,
      honorary: this.state.honorary,
      anomalyNumber: this.state.anomalyNumber,
      auditResult: this.state.auditResult,
      precision: this.state.precision,
      satisfaction: this.state.satisfaction,
    }
  }

  render() {
    if (!this.props.show) {
      return null
    }

    return (
      <FullModal title={"Modifier l'objectif " + this.props.name} show={true} onClose={this.handleCloseClick} onValid={this.handleValidClick}>
        <Row>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Corrélations</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="ageR" value={this.state.ageR} placeholder="80" title="Âge véhicule R (mois)" onChange={this.handleInputChange} />
          </Col>
          {this.props.type === 2 || this.props.type === 5 ? < Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="ageNR" value={this.state.ageNR} placeholder="80" title="Âge véhicule NR (mois)" onChange={this.handleInputChange} />
          </Col> : ''}
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="discount" value={this.state.discount} placeholder="2" title="Remise (%)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="averageHourly" value={this.state.averageHourly} placeholder="50" title="Horaire moyen (€ HT)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Objectifs coût</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="costR" value={this.state.costR} placeholder="1200" title="Coût réparable (€ HT)" onChange={this.handleInputChange} />
          </Col>
          {this.props.type === 2 || this.props.type === 5 ? <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="costNR" value={this.state.costNR} placeholder="3000" title="Coût VRADE (€ HT)" onChange={this.handleInputChange} />
          </Col> : ''}
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Objectifs délai</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="depositTimeR" value={this.state.depositTimeR} placeholder="30" title="Délai dépôt R (jours)" onChange={this.handleInputChange} />
          </Col>
          {this.props.type === 3 ? <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="EADMissionDeadline" value={this.state.EADMissionDeadline} placeholder="2" title="Délai mission-EAD (jours)" onChange={this.handleInputChange} />
          </Col> : ''}
          {this.props.type === 2 || this.props.type === 5 ? <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="depositTimeNR" value={this.state.depositTimeNR} placeholder="20" title="Délai dépôt NR (jours)" onChange={this.handleInputChange} />
          </Col> : ''}
          {this.props.type === 2 || this.props.type === 5 ? <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="SD29Deadline" value={this.state.SD29Deadline} placeholder="7" title="Délai SD29 (jours)" onChange={this.handleInputChange} />
          </Col> : ''}
          {this.props.type === 2 ? <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="SD29VisitDeadline" value={this.state.SD29VisitDeadline} placeholder="2" title="Délai visite-SD29 (jours)" onChange={this.handleInputChange} />
          </Col> : ''}
          {this.props.type === 5 ? <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="visitMissionDeadline" value={this.state.visitMissionDeadline} placeholder="2" title="Délai mission-visite (jours)" onChange={this.handleInputChange} />
          </Col> : ''}
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Objectifs production</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="honorary" value={this.state.honorary} placeholder="13000" title="Honoraire (€ HT/mois)" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} className="mb-20">
            <h4 className="m-0">Objectifs qualité</h4>
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="anomalyNumber" value={this.state.anomalyNumber} placeholder="0" title="Nombre d'anomalies" onChange={this.handleInputChange} />
          </Col>
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="auditResult" value={this.state.auditResult} placeholder="100" title="Résultat d'audit (%)" onChange={this.handleInputChange} />
          </Col>
          {this.props.type === 2 || this.props.type === 3 ? <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="precision" value={this.state.precision} placeholder="90" title="Précision (%)" onChange={this.handleInputChange} />
          </Col> : ''}
          <Col xs={12} md={3} className="mb-20">
            <FormInput type="number" name="satisfaction" value={this.state.satisfaction} placeholder="18" title="Satisfaction / 20" onChange={this.handleInputChange} />
          </Col>
        </Row>
      </FullModal>
    )
  }
}
