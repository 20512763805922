import React from "react";
import {
  Nav,
  Card,
  FormSelect,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Loader,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { ExportCSV } from "react-export-csv";

import axiosConfig from "../../utils/axiosConfig";

export default class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadCSV: false,
      isDownloadCSV: false,
      yearOptions: this.getYears(),
      monthOptions: this.getMonths(),
      year: new Date().getFullYear(),
      month: this.getMonth(),
      data: [],
      dataCSV: [],
      headerCSV: [
        {
          name: "Utilisateur",
          key: "userName",
        },
        {
          name: "E-mail",
          key: "userEmail",
        },
        {
          name: "Réseau",
          key: "networkName",
        },
        {
          name: "Groupe",
          key: "groupName",
        },
        {
          name: "Region",
          key: "regionName",
        },
        {
          name: "Agence",
          key: "agencyName",
        },
        {
          name: "Pilotate Cabinet",
          key: "pilotage_cabinet",
        },
        {
          name: "PixTransfer",
          key: "pixtransfer",
        },
        {
          name: "ValorEasy",
          key: "valoreasy",
        },
        {
          name: "Rédaxion",
          key: "redaxion",
        },
        {
          name: "XpertVisio",
          key: "xpertvisio",
        },
        {
          name: "IxPérience",
          key: "ixperience",
        },
      ],
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.getData();
      }
    );
  };

  componentDidMount() {
    this.getData();
  }

  getMonth = () => {
    let month = new Date().getMonth();
    if (month > 10) {
      return month;
    } else {
      return "0" + (month + 1);
    }
  };

  getYears = () => {
    let years = [];
    const start = 2018;
    const current = new Date().getFullYear();

    for (let index = current; index >= start; index--) {
      years.push({ text: index, value: index });
    }

    return years;
  };

  getMonths = () => {
    return [
      { text: "JANVIER", value: "01" },
      { text: "FÉVRIER", value: "02" },
      { text: "MARS", value: "03" },
      { text: "AVRIL", value: "04" },
      { text: "MAI", value: "05" },
      { text: "JUIN", value: "06" },
      { text: "JUILLET", value: "07" },
      { text: "AOÛT", value: "08" },
      { text: "SEPTEMBRE", value: "09" },
      { text: "OCTOBRE", value: "10" },
      { text: "NOVEMBRE", value: "11" },
      { text: "DÉCEMBRE", value: "12" },
    ];
  };

  getData = () => {
    axiosConfig("invoice/get-data", {
      data: {
        year: this.state.year,
        month: this.state.month,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({ data: res.data.data });
      } else {
        this.setState({ data: [] });
      }
    });
  };

  getDataCSV = () => {
    this.setState({ isLoadCSV: true }, () => {
      axiosConfig("invoice/get-data-csv", {
        data: {
          year: this.state.year,
          month: this.state.month,
        },
      }).then((res) => {
        this.setState({ isLoadCSV: false }, () => {
          if (res.data.success) {
            this.setState({
              isDownloadCSV: true,
              dataCSV: res.data.data,
            });
          }
        });
      });
    });
  };

  render() {
    const networks = this.state.data.networks || {};
    const groups = this.state.data.groups || {};
    const regions = this.state.data.regions || {};
    const agencies = this.state.data.agencies || {};

    return (
      <>
        <Nav className="mb-20" title="Facturation" />
        <Card className="mb-20" title="Filtres">
          <Row>
            <Col xs={12} md={4} lg={3}>
              <FormSelect
                title="Année"
                ignore={true}
                className="mb-20"
                name="year"
                value={this.state.year}
                options={this.state.yearOptions}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <FormSelect
                title="Mois"
                ignore={true}
                className="mb-20"
                name="month"
                value={this.state.month}
                options={this.state.monthOptions}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
        </Card>
        <Card
          className="mb-20"
          title="Liste"
          action={<FontAwesomeIcon icon={faDownload} />}
          onClick={() => {
            alert("Download CSV");
            this.getDataCSV();
          }}
        >
          <Table isCSV>
            <Thead>
              <Tr>
                <Th colSpan={4}>Nom</Th>
                <Th>Pilotage Cabinet</Th>
                <Th>PixTransfer</Th>
                <Th>ValorEasy</Th>
                <Th>Rédaxion</Th>
                <Th>XpertVisio</Th>
                <Th>IxPérience</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(networks).map((keyNetwork) => (
                <React.Fragment key={keyNetwork}>
                  <Tr>
                    <Td className="bg-red-light" colSpan={4}>
                      {networks[keyNetwork].name}
                    </Td>
                    <Td className="bg-red-light">
                      {networks[keyNetwork].pilotage_cabinet}
                    </Td>
                    <Td className="bg-red-light">
                      {networks[keyNetwork].pixtransfer}
                    </Td>
                    <Td className="bg-red-light">
                      {networks[keyNetwork].valoreasy}
                    </Td>
                    <Td className="bg-red-light">
                      {networks[keyNetwork].redaxion}
                    </Td>
                    <Td className="bg-red-light">
                      {networks[keyNetwork].xpertvisio}
                    </Td>
                    <Td className="bg-red-light">
                      {networks[keyNetwork].ixperience}
                    </Td>
                    <Td className="bg-red-light">
                      {networks[keyNetwork].count}
                    </Td>
                  </Tr>
                  {networks[keyNetwork].groups.map((keyGroup) => (
                    <React.Fragment key={keyGroup}>
                      <Tr>
                        <Td />
                        <Td className="bg-green-light" colSpan={3}>
                          {groups[keyGroup].name}
                        </Td>
                        <Td className="bg-green-light">
                          {groups[keyGroup].pilotage_cabinet}
                        </Td>
                        <Td className="bg-green-light">
                          {groups[keyGroup].pixtransfer}
                        </Td>
                        <Td className="bg-green-light">
                          {groups[keyGroup].valoreasy}
                        </Td>
                        <Td className="bg-green-light">
                          {groups[keyGroup].redaxion}
                        </Td>
                        <Td className="bg-green-light">
                          {groups[keyGroup].xpertvisio}
                        </Td>
                        <Td className="bg-green-light">
                          {groups[keyGroup].ixperience}
                        </Td>
                        <Td className="bg-green-light">
                          {groups[keyGroup].count}
                        </Td>
                      </Tr>
                      {groups[keyGroup].regions.map((keyRegion) => (
                        <React.Fragment key={keyRegion}>
                          <Tr>
                            <Td />
                            <Td />
                            <Td className="bg-blue-light" colSpan={2}>
                              {regions[keyRegion].name}
                            </Td>
                            <Td className="bg-blue-light">
                              {regions[keyRegion].pilotage_cabinet}
                            </Td>
                            <Td className="bg-blue-light">
                              {regions[keyRegion].pixtransfer}
                            </Td>
                            <Td className="bg-blue-light">
                              {regions[keyRegion].valoreasy}
                            </Td>
                            <Td className="bg-blue-light">
                              {regions[keyRegion].redaxion}
                            </Td>
                            <Td className="bg-blue-light">
                              {regions[keyRegion].xpertvisio}
                            </Td>
                            <Td className="bg-blue-light">
                              {regions[keyRegion].ixperience}
                            </Td>
                            <Td className="bg-blue-light">
                              {regions[keyRegion].count}
                            </Td>
                          </Tr>
                          {regions[keyRegion].agencies.map((keyAgency) => (
                            <React.Fragment key={keyAgency}>
                              <Tr>
                                <Td />
                                <Td />
                                <Td />
                                <Td className="bg-orange-light">
                                  {agencies[keyAgency].name}
                                </Td>
                                <Td className="bg-orange-light">
                                  {agencies[keyAgency].pilotage_cabinet}
                                </Td>
                                <Td className="bg-orange-light">
                                  {agencies[keyAgency].pixtransfer}
                                </Td>
                                <Td className="bg-orange-light">
                                  {agencies[keyAgency].valoreasy}
                                </Td>
                                <Td className="bg-orange-light">
                                  {agencies[keyAgency].redaxion}
                                </Td>
                                <Td className="bg-orange-light">
                                  {agencies[keyAgency].xpertvisio}
                                </Td>
                                <Td className="bg-orange-light">
                                  {agencies[keyAgency].ixperience}
                                </Td>
                                <Td className="bg-orange-light">
                                  {agencies[keyAgency].count}
                                </Td>
                              </Tr>
                              {}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </Card>
        <Loader show={this.state.isLoadCSV} />
        {this.state.isDownloadCSV ? (
          <ExportCSV
            header={this.state.headerCSV}
            data={this.state.dataCSV}
            separator={";"}
            callback={() => this.setState({ isDownloadCSV: false })}
            isDownload={true}
            filename={`Utilisateurs ${this.state.month}-${this.state.year}.csv`}
          />
        ) : null}
      </>
    );
  }
}
