import React from "react";
import {
  Card,
  FormSelect
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid"
import axiosConfig from "../utils/axiosConfig"

export default class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      network: "DEFAULT",
      group: "DEFAULT",
      region: "DEFAULT",
      agency: "DEFAULT",
      networkOptions: [{ value: "DEFAULT", text: "Chargement en cours.." }],
      groupOptions: [{ value: "DEFAULT", text: "Chargement en cours.." }],
      regionOptions: [{ value: "DEFAULT", text: "Chargement en cours.." }],
      agencyOptions: [{ value: "DEFAULT", text: "Chargement en cours.." }],
      networkResult: [],
      groupResult: [],
      regionResult: [],
      agencyResult: []
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    }, () => {
      if (name === "network") {
        this.getGroup(() => {
          if (this.props.handleDataChange) {
            this.props.handleDataChange(
              this.state.network === "DEFAULT" ? null : parseInt(this.state.network),
              this.state.group === "DEFAULT" ? null : parseInt(this.state.group),
              this.state.region === "DEFAULT" ? null : parseInt(this.state.region),
              this.state.agency === "DEFAULT" ? null : parseInt(this.state.agency)
            )
          }
        })
      } else if (name === "group") {
        this.getRegion(() => {
          if (this.props.handleDataChange) {
            this.props.handleDataChange(
              this.state.network === "DEFAULT" ? null : parseInt(this.state.network),
              this.state.group === "DEFAULT" ? null : parseInt(this.state.group),
              this.state.region === "DEFAULT" ? null : parseInt(this.state.region),
              this.state.agency === "DEFAULT" ? null : parseInt(this.state.agency)
            )
          }
        })
      } else if (name === "region") {
        this.getAgency(() => {
          if (this.props.handleDataChange) {
            this.props.handleDataChange(
              this.state.network === "DEFAULT" ? null : parseInt(this.state.network),
              this.state.group === "DEFAULT" ? null : parseInt(this.state.group),
              this.state.region === "DEFAULT" ? null : parseInt(this.state.region),
              this.state.agency === "DEFAULT" ? null : parseInt(this.state.agency)
            )
          }
        })
      } else if (name === "agency") {
        if (this.props.handleDataChange) {
          this.props.handleDataChange(
            this.state.network === "DEFAULT" ? null : parseInt(this.state.network),
            this.state.group === "DEFAULT" ? null : parseInt(this.state.group),
            this.state.region === "DEFAULT" ? null : parseInt(this.state.region),
            this.state.agency === "DEFAULT" ? null : parseInt(this.state.agency)
          )
        }
      }
    });
  }

  componentDidMount() {
    this.getSelectOptions()
  }

  getSelectOptions = () => {
    axiosConfig("general/get-select-options", {
      data: {
        id: this.props.id,
      }
    }).then((res) => {
      if (res.data.success) {
        this.setState({
          networkResult: res.data.networkResult,
          groupResult: res.data.groupResult,
          regionResult: res.data.regionResult,
          agencyResult: res.data.agencyResult
        }, () => {
          this.getNetwork()
        })
      } else {
        this.setState({
          networkResult: [],
          groupResult: [],
          regionResult: [],
          agencyResult: []
        })
      }
    })
  }

  getNetwork = (callback = () => { }) => {
    let networks = []

    networks.push({ value: "DEFAULT", text: "Tous les réseaux" })
    this.state.networkResult.map((element) => {
      networks.push({ value: element.id, text: element.name })
      return true
    })

    this.setState({
      networkOptions: networks,
      network: "DEFAULT",
    }, () => {
      this.getGroup(callback)
    })
  }

  getGroup = (callback = () => { }) => {
    let groups = []

    groups.push({ value: "DEFAULT", text: "Tous les groupes" })
    this.state.groupResult.map((element) => {
      if (
        this.state.network === "DEFAULT"
        || parseInt(this.state.network) === element.network_id
      ) {
        groups.push({ value: element.id, text: element.name })
      }
      return true
    })

    this.setState({
      groupOptions: groups,
      group: "DEFAULT",
    }, () => {
      this.getRegion(callback)
    })
  }

  getRegion = (callback = () => { }) => {
    let regions = []

    regions.push({ value: "DEFAULT", text: "Toutes les régions" })
    this.state.regionResult.map((element) => {
      if (
        (
          this.state.network === "DEFAULT"
          || parseInt(this.state.network) === element.network_id
        ) && (
          this.state.group === "DEFAULT"
          || parseInt(this.state.group) === element.group_id
        )
      ) {
        regions.push({ value: element.id, text: element.name })
      }
      return true
    })

    this.setState({
      regionOptions: regions,
      region: "DEFAULT",
    }, () => {
      this.getAgency(callback)
    })
  }

  getAgency = (callback = () => { }) => {
    let agencies = []

    agencies.push({ value: "DEFAULT", text: "Toutes les agences" })
    this.state.agencyResult.map((element) => {
      if (
        (
          this.state.network === "DEFAULT"
          || parseInt(this.state.network) === element.network_id
        ) && (
          this.state.group === "DEFAULT"
          || parseInt(this.state.group) === element.group_id
        ) && (
          this.state.region === "DEFAULT"
          || parseInt(this.state.region) === element.region_id
        )
      ) {
        agencies.push({ value: element.id, text: element.name })
      }
      return true
    })

    this.setState({
      agencyOptions: agencies,
      agency: "DEFAULT"
    }, () => {
      callback()
    })
  }

  render() {
    return (
      <Card>
        <Row>
          <Col xs={12} md={3}>
            <FormSelect
              title="Réseau"
              className="mb-20"
              name="network"
              value={this.state.network}
              options={this.state.networkOptions}
              onChange={this.handleInputChange}
              ignore={true}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormSelect
              title="Groupe"
              className="mb-20"
              name="group"
              value={this.state.group}
              options={this.state.groupOptions}
              onChange={this.handleInputChange}
              ignore={true}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormSelect
              title="Région"
              className="mb-20"
              name="region"
              value={this.state.region}
              options={this.state.regionOptions}
              onChange={this.handleInputChange}
              ignore={true}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormSelect
              title="Agence"
              className="mb-20"
              name="agency"
              value={this.state.agency}
              options={this.state.agencyOptions}
              onChange={this.handleInputChange}
              ignore={true}
            />
          </Col>
        </Row>
      </Card>
    )
  }
}