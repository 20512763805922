import React from "react";
import {
  Modal,
  Button,
  FormInput,
  FormSelect
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid"
import axiosConfig from "../../../utils/axiosConfig"

export default class ModalCompanyMonitoring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      type: "DEFAULT"
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.setState({
        name: this.props.name,
        type: this.props.type,
      })
    } else if (prevProps.show !== this.props.show &&
      !this.props.id
    ) {
      this.setState({
        name: "",
        type: "",
        isError: false,
      })
    }
  }

  getData() {
    return {
      name: this.state.name,
      type: this.state.type
    }
  }

  handleEditCompanyMonitoringClick = () => {
    let data = this.getData()
    data.id = this.props.id
    axiosConfig("/pilotage-cabinet/company-monitoring/update-company-monitoring", {
      data: data
    }).then((res) => {
      if (res.data.success === true) {
        alert("Le modèle a bien été mis à jour")
        this.props.onClose()
      }
    });
  }

  handleAddCompanyMonitoringClick() {
    let data = this.getData()
    axiosConfig("/pilotage-cabinet/company-monitoring/add-company-monitoring", {
      data: data
    }).then((res) => {
      if (res.data.success === true) {
        alert("Le modèle a bien été ajouté")
        this.props.onClose()
      }
    });
  }

  render() {
    if (!this.props.show) {
      return null
    }

    return (
      <Modal
        title={(this.props.isEdit ? "Modifier " : "Ajouter ") + this.props.name}
        show={true}
        onClose={this.props.onClose}
      >
        <Row>
          <Col xs={12} className="mb-20">
            <FormInput
              type="text"
              name="name"
              value={this.state.name}
              title="Nom"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} className="mb-20">
            <FormSelect
              type="text"
              name="type"
              init={"Veuillez sélectionner un type"}
              ignore={true}
              options={this.props.typeOptions}
              value={this.state.type}
              title="Type"
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        <Button text="Modifier" onClick={(e) => {
          if(this.props.isEdit){
            this.handleEditCompanyMonitoringClick()
          } else {
            this.handleAddCompanyMonitoringClick()
          }
        }} />
      </Modal>
    )
  }
}
