import React from "react";
import moment from "moment";
import { Table, Thead, Tbody, Tr, Th, Td } from "ui-kit-ck-consultant";
import axiosConfig from "../../utils/axiosConfig";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isModalUserEdit: false,
      order: null,
    };

    this.refColumnName = React.createRef();
    this.refColumnLastPicture = React.createRef();
    this.refColumnFolders = React.createRef();
    this.refColumnFoldersMonth = React.createRef();
  }

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnFolders.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnFoldersMonth.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "folders", desc: desc };
        break;
      case 2:
        order = { name: "foldersMonth", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.getUser();
    });
  };

  componentDidMount() {
    this.getUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isModalUserEdit !== this.state.isModalUserEdit &&
      this.state.isModalUserEdit === false
    ) {
      this.getUser();
    }
  }

  getUser() {
    axiosConfig("/pixtransfer/user/get-user", {
      data: {
        network: this.props.network,
        group: this.props.group,
        region: this.props.region,
        agency: this.props.agency,
        search: this.props.search,
        orderName: this.state.order ? this.state.order.name : null,
        orderDesc: this.state.order ? this.state.order.desc : null,
      },
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({ users: res.data.data });
      } else {
        this.setState({ users: [] });
      }
    });
  }

  render() {
    return (
      <>
        <Table
          isCSV
          radiusTopLeft
          empty={
            this.state.users.length > 0 ? "" : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th hide>#</Th>
              <Th
                ref={this.refColumnName}
                sort
                onSort={(desc) => this.handleSortClick(desc, 0)}
                radiusTopLeft
              >
                Nom
              </Th>
              <Th>Dernière photo</Th>
              <Th
                ref={this.refColumnFolders}
                sort
                onSort={(desc) => this.handleSortClick(desc, 1)}
              >
                Dossiers
              </Th>
              <Th
                ref={this.refColumnFoldersMonth}
                sort
                onSort={(desc) => this.handleSortClick(desc, 2)}
              >
                Dossier m
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.users.map((element, idx) => (
              <Tr key={idx}>
                <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                <Td>{element.name}</Td>
                <Td>
                  {moment(element.lastPicture).format("DD/MM/YYYY HH:mm:ss")}
                </Td>
                <Td>{element.folders}</Td>
                <Td>{element.foldersMonth}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </>
    );
  }
}
