import axiosConfig from "./axiosConfig";

function getNetworkSelect(callback, special = false) {
  axiosConfig("/network/get", {
    data: {
      special,
    },
  }).then((res) => {
    if (res.data.success === true) {
      let options = [];
      res.data.data.map((element) => {
        options.push({
          value: element.id,
          text: element.name,
        });
        return true;
      });
      callback({ data: options });
    } else {
      callback({ data: [] });
    }
  });
}

function getGroupSelect(network, callback, special = false) {
  axiosConfig("/group/get", {
    data: {
      network,
      special,
    },
  }).then((res) => {
    if (res.data.success === true) {
      let options = [];
      res.data.data.map((element) => {
        options.push({
          value: element.id,
          text: element.name,
        });
        return true;
      });
      callback({ data: options });
    } else {
      callback({ data: [] });
    }
  });
}

function getRegionSelect(network, group, callback, special = false) {
  axiosConfig("/region/get", {
    data: {
      network,
      group,
      special,
    },
  }).then((res) => {
    if (res.data.success === true) {
      let options = [];
      res.data.data.map((element) => {
        options.push({
          value: element.id,
          text: element.regionListName,
        });
        return true;
      });
      callback({ data: options });
    } else {
      callback({ data: [] });
    }
  });
}

function getAgencySelect(network, group, region, callback, special = false) {
  axiosConfig("/agency/get", {
    data: {
      network,
      group,
      region,
      special,
    },
  }).then((res) => {
    if (res.data.success === true) {
      let options = [];
      res.data.data.map((element) => {
        options.push({
          value: element.id,
          text: element.name,
        });
        return true;
      });
      callback({ data: options });
    } else {
      callback({ data: [] });
    }
  });
}

function getValue(value, digits = 2) {
  const options = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", options).format(value);
}

const getPercentageValue = (value, digits = 2) => {
  if (value == null) {
    return "";
  }

  const optionsPercentage = {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", optionsPercentage).format(value);
};

export {
  getNetworkSelect,
  getGroupSelect,
  getRegionSelect,
  getAgencySelect,
  getValue,
  getPercentageValue,
};
