import React from "react";
import {
  Modal,
  Button,
  FormCheckbox
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid"
import { PhotoshopPicker } from 'react-color';
import axiosConfig from "../../../utils/axiosConfig"

export default class ModalAgency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overall: false,
      satisfaction: false,
      honorary: false,
      color: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });
  }

  handleChangeComplete = (e) => {
    this.setState({ colorTmp: e.hex })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.setState({
        overall: this.props.overall,
        satisfaction: this.props.satisfaction,
        honorary: this.props.honorary,
        color: this.props.color ? this.props.color : "",
      })
    } else if (prevProps.show !== this.props.show &&
      !this.props.id
    ) {
      this.setState({
        overall: false,
        satisfaction: false,
        color: "",
        isError: false,
      })
    }
  }

  handleEditAgencyClick = () => {
    let data = this.getData()
    data.id = this.props.id
    axiosConfig("/pilotage-cabinet/agency/update-agency", {
      data: data
    }).then((res) => {
      if (res.data.success === true) {
        alert("L'agence a bien été mis à jour")
        this.props.onClose()
      }
    });
  }

  getData() {
    return {
      color: this.state.color,
      overall: this.state.overall,
      satisfaction: this.state.satisfaction,
      honorary: this.state.honorary,
    }
  }

  render() {
    if (!this.props.show) {
      return null
    }

    return (
      <Modal title={"Modifier " + this.props.name} show={true} onClose={this.props.onClose}>
        <Row>
          <Col xs={12} md={5} className="mb-20 d-flex">
            <Button className="mt-auto" onClick={() => this.setState({ isColor: true, colorTmp: this.state.color })} text="Modifier la couleur">
              <div style={{ height: '30px', width: '30px', borderRadius: '15px', backgroundColor: this.state.color }}></div>
            </Button>
          </Col>
        </Row>
        <FormCheckbox className="mb-20" text="Performance globale" name="overall" checked={this.state.overall} onChange={this.handleCheckboxChange} />
        <FormCheckbox className="mb-20" text="Satisfaction" name="satisfaction" checked={this.state.satisfaction} onChange={this.handleCheckboxChange} />
        <FormCheckbox className="mb-20" text="Afficher les honoraires" name="honorary" checked={this.state.honorary} onChange={this.handleCheckboxChange} />
        <Button text="Modifier" onClick={(e) => this.handleEditAgencyClick()} />
        {this.state.isColor === true ?
          <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <PhotoshopPicker
              color={this.state.colorTmp}
              onAccept={(e) => this.setState({ isColor: false, color: this.state.colorTmp })}
              onCancel={(e) => this.setState({ isColor: false })}
              onChangeComplete={this.handleChangeComplete}
            />
          </div> : false}
      </Modal>
    )
  }
}
