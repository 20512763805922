import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  Modal,
  FormInput,
  Button,
  Loader,
  Nav,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileUpload,
  faPen,
  faKey,
  faUsers,
  faTrash,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

export default class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      isLoad: false,
      idLogo: null,
      isModalCompany: false,
      modalCompanyId: null,
      modalCompanyName: null,
      modalCompanyCode: null,
      modalCompanyPassword: null,
      companyAlias: [],
      isModalAlias: false,
      modalAliasEmail: "",
      modalAliasId: null,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  static contextType = AuthContext;

  componentDidMount() {
    this.getCompany();
  }

  getCompany() {
    axiosConfig("/company/get-company").then((res) => {
      if (res.data.success === true) {
        this.setState({ companies: res.data.data });
      } else {
        this.setState({ companies: [] });
      }
    });
  }

  updateCompany() {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company/update-company", {
        data: {
          id: this.state.modalCompanyId,
          name: this.state.modalCompanyName,
          code: this.state.modalCompanyCode,
          password: this.state.modalCompanyPassword,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState(
            {
              isLoad: false,
              isModalCompany: false,
              modalCompanyId: null,
              modalCompanyName: null,
              modalCompanyCode: null,
              modalCompanyPassword: null,
            },
            () => this.getCompany()
          );
        } else {
          this.setState(
            {
              isLoad: false,
            },
            () => {
              alert("Une erreur est survenue");
            }
          );
        }
      });
    });
  }

  getCompanyAlias = (id) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company/get-company-alias", { data: { id } }).then(
        (res) => {
          if (res.data.success === true) {
            this.setState({
              companyAlias: res.data.data,
              isModalAlias: true,
              modalAliasEmail: "",
              modalAliasId: id,
              isLoad: false,
            });
          } else {
            this.setState({ companyAlias: [], isLoad: false });
          }
        }
      );
    });
  };

  insertCompanyAlias = () => {
    if (this.state.modalAliasEmail) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/company/insert-company-alias", {
          data: {
            id: this.state.modalAliasId,
            email: this.state.modalAliasEmail,
          },
        }).then((res) => {
          if (res.data.success === true) {
            let companyAlias = this.state.companyAlias;

            companyAlias.push({
              id: res.data.id,
              token: res.data.token,
              email: this.state.modalAliasEmail,
            });

            this.setState({ isLoad: false, companyAlias, modalAliasEmail: "" });
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  deleteCompanyAlias = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce compte ?")) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/company/delete-company-alias", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false }, () =>
              this.getCompanyAlias(this.state.modalAliasId)
            );
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    }
  };

  resetPasswordCompanyAlias = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir rénitialiser le mot de passe de ce compte ?"
      )
    ) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("/company/reset-password-company-alias", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false }, () =>
              alert("Mot de passe rénitialisé")
            );
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      });
    }
  };

  onFileChange = (e) => {
    if (e.target.files.hasOwnProperty(0)) {
      this.setState({ isLoad: true }, () => {
        const type = e.target.files[0].type;
        const extension = null;
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          if (e.target.files[0].size / 1000 < 64) {
            axiosConfig("company/update-logo-company", {
              data: {
                id: this.state.idLogo,
                base64: reader.result,
                type: type,
                extension: extension,
              },
            }).then((res) => {
              if (res.data.success === true) {
                this.getCompany();
              }
              this.setState({ isLoad: false });
            });
          } else {
            alert("Taille maximale : 64Kb");
          }
        };
      });
    }
  };

  handleGetLogoClick(id) {
    this.refInputFile.click();
    this.setState({ idLogo: id });
  }

  handleSavePasswordClick(id, idx) {
    axiosConfig("/company/save-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpCompanies = this.state.companies;
        tmpCompanies[idx].isPassword = !tmpCompanies[idx].isPassword;
        this.setState({ users: tmpCompanies });
      }
    });
  }

  handleResetPasswordClick(id, idx) {
    axiosConfig("/company/reset-password", {
      data: { id: id },
    }).then((res) => {
      if (res.data.success === true) {
        let tmpCompanies = this.state.companies;
        tmpCompanies[idx].isPassword = !tmpCompanies[idx].isPassword;
        this.setState({ users: tmpCompanies });
      }
    });
  }

  render() {
    return (
      <>
        <Nav className="mb-20" title="Compagnies" />
        <Card title="Liste des compagnies" classNameChildren="overflow-x-auto">
          <Table isCSV>
            <Thead>
              <Tr>
                <Th hide>#</Th>
                <Th radiusTopLeft>Nom</Th>
                <Th>Codes</Th>
                <Th>Logo</Th>
                {this.context.administration === 5 ? <Th>Actions</Th> : <></>}
              </Tr>
            </Thead>
            <Tbody>
              {this.state.companies.map((element, idx) => (
                <Tr key={idx}>
                  <Th radiusTopLeft={idx === 0 ? true : false}>{idx + 1}</Th>
                  <Td>{element.name}</Td>
                  <Td>
                    {element.code ? element.code.split(",").join(", ") : ""}
                  </Td>
                  <Td>
                    <div className="d-flex">
                      <img
                        className="m-auto p-10"
                        alt="logo"
                        src={element.logo}
                        style={{
                          maxHeight: "60px",
                          maxWidth: "100%",
                        }}
                      />
                    </div>
                  </Td>
                  {this.context.administration === 5 ? (
                    <Td className="nowrap">
                      <div className="d-flex">
                        {!element.isPassword ? (
                          <ButtonIcon
                            small
                            className="blue"
                            onClick={() =>
                              this.handleSavePasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        ) : (
                          <ButtonIcon
                            small
                            className="red"
                            onClick={() =>
                              this.handleResetPasswordClick(element.id, idx)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                        )}
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.handleGetLogoClick(element.id)}
                        >
                          <FontAwesomeIcon icon={faFileUpload} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() =>
                            this.setState({
                              isModalCompany: true,
                              modalCompanyId: element.id,
                              modalCompanyName: element.name,
                              modalCompanyCode: element.code,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.getCompanyAlias(element.id)}
                        >
                          <FontAwesomeIcon icon={faUsers} />
                        </ButtonIcon>
                        <input
                          ref={(input) => (this.refInputFile = input)}
                          type="file"
                          onChange={this.onFileChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    </Td>
                  ) : null}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
        <Modal
          title={`Modifier ${this.state.modalCompanyName}`}
          show={this.state.isModalCompany}
          onClose={() =>
            this.setState({
              isModalCompany: false,
              modalCompanyId: null,
              modalCompanyName: null,
              modalCompanyCode: null,
              modalCompanyPassword: null,
            })
          }
        >
          <FormInput
            className="mb-20"
            type="text"
            name="modalCompanyName"
            value={this.state.modalCompanyName}
            title="Nom"
            onChange={this.handleInputChange}
          />
          <FormInput
            className="mb-20"
            type="text"
            name="modalCompanyCode"
            value={this.state.modalCompanyCode}
            title="Code"
            onChange={this.handleInputChange}
          />
          <FormInput
            className="mb-20"
            type="text"
            name="modalCompanyPassword"
            value={this.state.modalCompanyPassword}
            title="Mot de passe"
            onChange={this.handleInputChange}
          />
          <Button
            className="mb-20"
            text={"Modifier"}
            onClick={() => this.updateCompany()}
          />
        </Modal>
        <Modal
          large
          title="Sous-comptes"
          show={this.state.isModalAlias}
          onClose={() => this.setState({ isModalAlias: false })}
        >
          <Table isCSV>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>E-mail</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.companyAlias.map((element, idx) => (
                <Tr key={idx}>
                  <Td className="nowrap">{idx + 1}</Td>
                  <Td className="w-100">{element.email}</Td>
                  <Td className="nowrap">
                    <div className="d-flex">
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() =>
                          this.resetPasswordCompanyAlias(element.id)
                        }
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </ButtonIcon>
                      {element.token ? (
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() =>
                            window.open(
                              `https://ixperience.fr/new-account/company-alias/${element.token}`,
                              "_blank"
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faGlobe} />
                        </ButtonIcon>
                      ) : null}
                      <ButtonIcon
                        small
                        className="red"
                        onClick={() => this.deleteCompanyAlias(element.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                    </div>
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td>{this.state.companyAlias.length + 1}</Td>
                <Td>
                  <FormInput
                    placeholder="E-mail"
                    type="text"
                    name="modalAliasEmail"
                    value={this.state.modalAliasEmail}
                    onChange={this.handleInputChange}
                  />
                </Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
          <div className="d-flex">
            <Button
              className="w-auto mt-10 ml-auto"
              text="Ajouter"
              onClick={this.insertCompanyAlias}
            />
          </div>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
